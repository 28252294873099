import randomString from "randomstring";
import Vue from "vue";
import axios from "axios";

import store from "@/store";

import gastro from "./gastro";
import retail from "./retail";
import {ENDPOINTS} from "../../../config";

export default {
    namespaced: true,

    modules: {
        gastro,
        retail
    },

    state: {
        activeShift: {
            uuid: ""
        },
        verifiedAge: 0,
        progressToken: null
    },

    mutations: {
        updateActiveShift(state, data) {
            state.activeShift.uuid = data.uuid;
        },
        stornoItem(state, data) {
            let selectedItem;

            if (data.posType === "gastro") {
                if (state.gastro.selectedOrderedItem != null) {
                    selectedItem = state.gastro.selectedOrderedItem;
                }
            }

            if (data.posType === "retail") {
                if (state.retail.selectedItem != null) {
                    selectedItem = state.retail.selectedItem;
                }
            }

            if (selectedItem) {
                if (data.amount !== selectedItem.amount) {
                    //GENERATE NEW ITEM
                    let newItem = Object.assign({}, selectedItem);
                    newItem.randomPositionID = randomString.generate(15);
                    newItem.originalItemRandomPositionID = selectedItem && selectedItem.randomPositionID || randomString.generate(15);
                    newItem.amount = data.amount;
                    newItem.isVoid = true;
                    newItem.voidReason = data.voidReason;
                    newItem.needsVoidPrint = true;
                    newItem.voidReasonID = data.id;

                    //PUSH NEW ITEM
                    if (data.posType === "gastro") {
                        state.gastro.orderedItems[state.gastro.party.name].push(newItem);
                    }

                    if (data.posType === "retail") {
                        state.retail.orderedItems.push(newItem);
                    }

                    //CHANGE AMOUNT OF OLD ITEM
                    selectedItem.amount = selectedItem.amount - data.amount;
                } else {
                    //FULL STACK OF ITEM IS TO VOID
                    selectedItem.voidReasonID = data.id;
                    selectedItem.isVoid = true;
                    selectedItem.voidReason = data.voidReason;
                    selectedItem.needsVoidPrint = true;
                }
            }
        },
        deleteItem(state, data) {
            if (data.posType === "gastro") {
                if (state.gastro.selectedOpenItem != null) {
                    const item = state.gastro.selectedOpenItem;

                    if (item) {
                        let itemIndex = state.gastro.openItems[state.gastro.party.name].indexOf(state.gastro.selectedOpenItem);

                        Vue.delete(state.gastro.openItems[state.gastro.party.name], itemIndex);

                        //SELECT NEXT ITEM
                        this.commit("pos/gastro/selectInvoiceOpenItem", state.gastro.openItems[state.gastro.party.name][itemIndex])
                    }
                } else if (state.gastro.selectedOpenItem === null) {
                    //ALL ITEMS
                    state.gastro.openItems[state.gastro.party.name] = [];
                }
            }
        },
        priceDiscount(state, data) {
            let item;

            if (data.posType === "gastro") {
                if (state.gastro.selectedOpenItem != null) {
                    item = state.gastro.selectedOpenItem;
                }

                if (state.gastro.selectedOrderedItem != null) {
                    item = state.gastro.selectedOrderedItem;
                }
            }

            if (data.posType === "retail") {
                if (state.retail.selectedItem != null) {
                    item = state.retail.selectedItem;
                }
            }

            if (item) {
                if (data.amount !== item.amount) {
                    //GENERATE NEW ITEM
                    let newItem = Object.assign({}, item);
                    newItem.randomPositionID = randomString.generate(15);
                    newItem.amount = data.amount;
                    newItem.sellPrice = data.discountPrice;

                    //PUSH NEW ITEM
                    if (data.posType === "retail") {
                        state.retail.orderedItems.push(newItem);
                    }

                    if (data.posType === "gastro") {
                        if (state.gastro.selectedOpenItem != null) {
                            state.gastro.openItems[state.gastro.party.name].push(newItem);
                        }

                        if (state.gastro.selectedOrderedItem != null) {
                            state.gastro.orderedItems[state.gastro.party.name].push(newItem);
                        }
                    }

                    //CHANGE AMOUNT OF OLD ITEM
                    item.amount = item.amount - data.amount;
                } else {
                    item.sellPrice = data.discountPrice;
                }
            }
        },
        discount(state, data) {
            let item;

            if (data.posType === "gastro") {
                if (state.gastro.selectedOpenItem != null) {
                    item = state.gastro.selectedOpenItem;
                }

                if (state.gastro.selectedOrderedItem != null) {
                    item = state.gastro.selectedOrderedItem;
                }
            }

            if (data.posType === "retail") {
                if (state.retail.selectedItem != null) {
                    item = state.retail.selectedItem;
                }
            }

            if (item) {
                if (data.amount !== item.amount) {
                    //GENERATE NEW ITEM
                    let newItem = Object.assign({}, item);
                    newItem.randomPositionID = randomString.generate(15);
                    newItem.amount = data.amount;
                    newItem.discount = data.discount;

                    //PUSH NEW ITEM
                    if (data.posType === "retail") {
                        state.retail.orderedItems.push(newItem);
                    }

                    if (data.posType === "gastro") {
                        if (state.gastro.selectedOpenItem != null) {
                            state.gastro.openItems[state.gastro.party.name].push(newItem);
                        }

                        if (state.gastro.selectedOrderedItem != null) {
                            state.gastro.orderedItems[state.gastro.party.name].push(newItem);
                        }
                    }

                    //CHANGE AMOUNT OF OLD ITEM
                    item.amount = item.amount - data.amount;
                } else {
                    item.discount = data.discount;
                }
            }
        },
        discountInvoice(state, data) {
            if (data.posType === "gastro") {
                //OPEN ITEMS
                if (state.gastro.openItems.hasOwnProperty(state.gastro.party.name)) {
                    state.gastro.openItems[state.gastro.party.name].forEach((item) => {
                        if (!item.ean.includes("V$") && !item.ean.includes("GF$") && !item.ean.includes("ZMYLE$") && !item.ean.includes("MYWORLD$") && !item.randomPositionID?.includes("$deliveryCost_")) {
                            item.discount = data.discount;
                        }
                    });
                }

                //BOOKED ITEMS
                if (state.gastro.orderedItems.hasOwnProperty(state.gastro.party.name)) {
                    state.gastro.orderedItems[state.gastro.party.name].forEach((item) => {
                        if (!item.isVoid && !item.ean.includes("V$") && !item.ean.includes("GF$") && !item.ean.includes("ZMYLE$") && !item.ean.includes("MYWORLD$") && !item.randomPositionID?.includes("$deliveryCost_")) {
                            item.discount = data.discount;
                        }
                    });
                }
            }

            if (data.posType === "retail") {
                state.retail.orderedItems.forEach((item) => {

                    if (!item.isVoid && !item.ean.includes("V$") && !item.ean.includes("GF$") && !item.ean.includes("ZMYLE$") && !item.ean.includes("MYWORLD$")) {
                        item.discount = data.discount;
                    }
                });
            }
        },
        async increaseInvoice(state, data) {
            if (data.posType === "gastro") {
                //OPEN ITEMS
                if (state.gastro.openItems.hasOwnProperty(state.gastro.party.name)) {
                    for (let item in state.gastro.openItems[state.gastro.party.name]) {
                        if (!state.gastro.openItems[state.gastro.party.name].hasOwnProperty(item))
                            continue;

                        item = state.gastro.openItems[state.gastro.party.name][item];

                        if (!item.ean.includes("V$") && !item.ean.includes("GF$") && !item.ean.includes("ZMYLE$") && !item.ean.includes("MYWORLD$")) {
                            let fromPrice = item.originalSellPrice;

                            // CHECK IF ITEM HAS MEALSIZE
                            if (parseInt(item.mealSizeID) > 0) {
                                // FIND ORG. ITEM
                                let baseItem = await store.dispatch("items/getItemByID", item.id);

                                if (baseItem) {
                                    // FIND MEALSIZE
                                    let baseItemSize = baseItem.mealSizes.find((size) => size.id === item.mealSizeID);

                                    if (baseItemSize) {
                                        fromPrice = baseItemSize.price;
                                    }
                                }
                            }

                            item.sellPrice = parseFloat((((100 + data.value) / 100) * fromPrice).toFixed(2));
                            item.isPriceChanged = true;
                        }
                    }
                }

                //BOOKED ITEMS
                if (state.gastro.orderedItems.hasOwnProperty(state.gastro.party.name)) {
                    for (let item in state.gastro.orderedItems[state.gastro.party.name]) {
                        if (!state.gastro.orderedItems[state.gastro.party.name].hasOwnProperty(item))
                            continue;

                        item = state.gastro.orderedItems[state.gastro.party.name][item];

                        if (!item.isVoid && !item.ean.includes("V$") && !item.ean.includes("GF$") && !item.ean.includes("ZMYLE$") && !item.ean.includes("MYWORLD$")) {
                            let fromPrice = item.originalSellPrice;

                            // CHECK IF ITEM HAS MEALSIZE
                            if (parseInt(item.mealSizeID) > 0) {
                                // FIND ORG. ITEM
                                let baseItem = await store.dispatch("items/getItemByID", item.id);

                                if (baseItem) {
                                    // FIND MEALSIZE
                                    let baseItemSize = baseItem.mealSizes.find((size) => size.id === item.mealSizeID);

                                    if (baseItemSize) {
                                        fromPrice = baseItemSize.price;
                                    }
                                }
                            }

                            item.sellPrice = parseFloat((((100 + data.value) / 100) * fromPrice).toFixed(2));
                            item.isPriceChanged = true;
                        }
                    }
                }
            }

            if (data.posType === "retail") {
                state.retail.orderedItems.forEach((item) => {

                    if (!item.isVoid && !item.ean.includes("V$") && !item.ean.includes("GF$") && !item.ean.includes("ZMYLE$") && !item.ean.includes("MYWORLD$")) {
                        item.sellPrice = parseFloat((((100 + data.value) / 100) * item.originalSellPrice).toFixed(2));
                        item.isPriceChanged = true;
                    }
                });
            }
        },
        setCourse(state, data) {
            let item;
            if (data.posType === "gastro") {
                if (state.gastro.selectedOpenItem != null) {
                    item = state.gastro.selectedOpenItem;
                }

                if (state.gastro.selectedOrderedItem != null) {
                    item = state.gastro.selectedOrderedItem;
                }
            }

            if (data.posType === "retail") {
                if (state.retail.selectedItem != null) {
                    item = state.retail.selectedItem;
                }
            }

            if (item) {
                state.gastro.selectedCourse = data.course;
                item.course = data.course;
            } else {
                state.gastro.selectedCourse = data.course;
            }
        },
        setFreetext(state, data) {
            if (data.posType === "gastro") {
                if (data.selectedItem !== null) {
                    if (state.gastro.selectedOpenItem != null) {
                        const item = state.gastro.selectedOpenItem;

                        if (item) {
                            item.freeText = data.freeText;
                        }
                    }

                    if (state.gastro.selectedOrderedItem != null) {
                        const item = state.gastro.selectedOrderedItem;

                        if (item) {
                            item.freeText = data.freeText;
                        }
                    }
                } else {
                    //SET GLOBAL FREETEXT
                    store.commit('pos/gastro/setFreetext', data.freeText, {
                        root: true
                    });
                }
            }

            if (data.posType === "retail") {
                if (state.retail.selectedItem != null) {
                    const item = state.retail.selectedItem;

                    if (item) {
                        item.freeText = data.freeText;
                    }
                } else {
                    //SET GLOBAL FREETEXT
                    store.commit('pos/retail/setFreetext', data.freeText, {
                        root: true
                    });
                }
            }
        },
        setCustomer(state, data) {
            state.gastro.customer = data.customer;
        },
        setCustomerProject(state, data) {
            if (data.posType === "gastro") {
                state.gastro.customerProject = data.project;
            }

            if (data.posType === "retail") {
                state.retail.customerProject = data.project;
            }
        },
        setVerifiedAge(state, data) {
            state.verifiedAge = data;
        },
        setProgressToken(state, token) {
            state.progressToken = token;
        },
        resetPos(state, data) {
            if (data.posType === "gastro") {
                //state.pos.gastro.openItems = {};
                //state.pos.gastro.bookedItems = {};
                state.gastro.selectedOrderedItem = null;
                state.gastro.selectedOpenItem = null;
                state.gastro.customer = null;
                state.gastro.setCustomerProject = null;
                state.gastro.party = null;
                state.gastro.table = {
                    name: 0,
                    parties: []
                };
            }
        }
    },

    actions: {
        updateActiveShift({commit}, data) {
            commit('updateActiveShift', data);
        },
        stornoItem({commit}, data) {
            commit('stornoItem', data);
        },
        deleteItem({commit}, data) {
            commit('deleteItem', data);
        },
        priceDiscount({commit}, data) {
            commit('priceDiscount', data);
        },
        discount({commit}, data) {
            commit('discount', data);
        },
        discountInvoice({commit}, data) {
            commit('discountInvoice', data);
        },
        increaseInvoice({commit}, data) {
            commit('increaseInvoice', data)
        },
        setCourse({commit}, data) {
            commit('setCourse', data);
        },
        setFreetext({commit}, data) {
            commit("setFreetext", data);
        },
        setCustomer({commit}, data) {
            commit("setCustomer", data);
        },
        setCustomerProject({commit}, data) {
            commit("setCustomerProject", data);
        },
        setVerifiedAge({commit}, data) {
            commit("setVerifiedAge", data);
        },
        resetPos({commit}, data) {
            commit('resetPos', data);
        },
    }
};
