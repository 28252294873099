<template>
  <div>
    <v-app-bar
        app
        dark
        style="background-image: linear-gradient(60deg,#29323c,#485563)!important;"
        class="app-header"
        clipped-left
        v-if="!(this.$route.path === '/pos/gastro' && this.$vuetify.breakpoint.smAndDown)"
    >

      <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp">
        <router-link link to='/dashboards/analytics'>
          <v-img src="../../assets/posilinio-logo-filed.svg" contain
                 height="46"></v-img>
        </router-link>
      </v-toolbar-title>

      <v-app-bar-nav-icon class="ml-2" @click.stop="drawer = !drawer" style="color:#3f6ad8;font-size:35px;"/>

      <TablebeePrintOrderBonComponent
          v-if="$store.getters['permissions/checkModule'](54) && Number($store.getters['settings/getSettingValue']('tb_enableAutoPrinting'))"
          ref="tablebeeOrderbonPrint"/>

      <template v-if="!$store.getters['app/fiscalState']">
        <v-tooltip bottom color="error">
          <template v-slot:activator="{ on, attrs }">

            <div class="white--text my-0 mx-2 pa-1 py-4 overflow-hidden error rounded"
                 v-bind="attrs"
                 v-on="on"
                 style="max-height: 56px;height:50px;text-overflow: ellipsis;white-space: nowrap;">
              <v-icon dark>
                mdi-alert-circle
              </v-icon>

              {{ $t('generic.lang_tseError') }}
            </div>
          </template>

          {{ $t('generic.lang_fiscalNotSet') }}
        </v-tooltip>
      </template>

      <v-spacer/>

      <v-progress-circular
          color="primary"
          indeterminate
          size="35"
          style="margin-right: 20px;"
          v-show="backgroundSync.currentSyncing"
          width="2"
      />
      <TablebeeNotificationsComponent
          v-if="$store.getters['permissions/checkModule'](54)&&Number($store.getters['settings/getSettingValue']('showTablebeeNotifications'))"/>
      <HeaderPrinters></HeaderPrinters>
      <HeaderTouchKeyboard/>
      <HeaderLanguage/>
      <UserArea/>
    </v-app-bar>

    <v-navigation-drawer
        app
        key="pos-sidebar"
        class="mx-auto"
        clipped
        style="overflow-y: hidden;background-image: linear-gradient(60deg,#29323c,#485563)!important;"
        dark
        v-bind:width="325"
        v-model="drawer"
        v-if="!(this.$route.path === '/pos/gastro' && this.$vuetify.breakpoint.smAndDown)"
    >
      <vue-custom-scrollbar class="scroll-area">

        <v-list dense>
          <div :key="index" v-for="(entry, index) in menuEntries">
            <v-list-item
                :to="entryPathLookUp(entry)"
                @click="selectNavEntry(entry)" color="primary" link
                v-if="entry.groups.length === 0 && entry.childs.length === 0 && !entry.meta.hideInNavigation && !excluded(entry) &&  !(entry.name && entry.name==='pos.externalAppGroup')">
              <v-list-item-icon>
                <v-icon class="listMenuIcon">{{ entry.meta.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title class="font-weight-bold">{{ $t(entry.meta.menuTitle) }}</v-list-item-title>
            </v-list-item>
            <v-list-group dark
                v-if="$store.getters['permissions/checkModule'](65) && (entry.name && entry.name==='pos.externalAppGroup')"
                prepend-icon="pe-7s-exapnd2"
                color="primary">
              <template v-slot:activator>
                <v-list-item-title>{{ $t('settings.lang_externalApps') }}</v-list-item-title>
              </template>
              <div :key="k+child.uuid" v-for="(child, k) in externalAPPS">

                <v-list-item dark :to="'/pos/externalApp?target='+child.target"
                             color="primary" link>
                  <v-list-item-icon>

                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-bold">{{ child.name }}</v-list-item-title>
                </v-list-item>
              </div>
            </v-list-group>
            <v-list-group dark :prepend-icon="entry.meta.icon" :value="isActive(entry)"
                          color="primary"
                          v-if="entry.groups.length > 0 || entry.childs.length > 0 ">
              <template v-slot:activator>
                <v-list-item-title class="font-weight-bold">{{ $t(entry.meta.menuTitle) }}</v-list-item-title>
              </template>


              <div :key="k+'child'" v-for="(child, k) in entry.childs">

                <v-list-item dark :to="child.path"
                             color="primary" link
                             v-if="entry.childs.length > 0 && !entry.meta.hideInNavigation">
                  <v-list-item-icon>
                    <v-icon class="listMenuIcon">{{ child.meta.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title class="font-weight-bold">{{ $t(child.meta.menuTitle) }}</v-list-item-title>
                </v-list-item>
              </div>
              <!--                                    :value="isActive(group.childs)"-->
              <v-list-group
                  :key="i"
                  :value="isActive(group.childs)"
                  no-action
                  sub-group
                  dark
                  v-for="(group, i) in entry.groups"
              >
                <template v-slot:activator>
                                    <span>
                                            <v-icon class="listMenuIcon" v-text="group.meta.icon"></v-icon>
                                        <v-list-item-title
                                            class="ml-8">{{ $t(group.meta.menuTitle) }}</v-list-item-title>
                                    </span>
                </template>

                <v-list-item :key="i"
                             :to="child.path"
                             link
                             dark
                             no-action
                             v-for="(child, i) in group.childs"
                >
                  <v-list-item-title class="font-weight-bold">{{ $t(child.meta.menuTitle) }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </div>
        </v-list>
        <v-footer class="px-0">
          <!--theme switcher-->
          <v-list-item
              key="theme-switcher-btn"
              color="primary" @click="switchTheme">
            <v-list-item-icon>
              <v-icon class="listMenuIcon">{{ this.$vuetify.theme.dark ? 'pe-7s-sun' : 'pe-7s-moon' }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
                this.$t(this.$vuetify.theme.dark ? 'generic.lang_switch_to_light' : 'generic.lang_switch_to_dark')
              }}
            </v-list-item-title>
          </v-list-item>

        </v-footer>
      </vue-custom-scrollbar>


    </v-navigation-drawer>
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'
import {ROUTER} from "@/config/routes";
import UserArea from './Header/HeaderUserArea';
import HeaderLanguage from "./Header/HeaderLanguage";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEllipsisV,} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import HeaderTouchKeyboard from "./Header/HeaderTouchKeyboard";
import HeaderPrinters from "./Header/HeaderPrinters";
import {mapGetters, mapState} from "vuex";
import TablebeePrintOrderBonComponent from "../../components/tableBee/TablebeePrintOrderBonComponent";
import TablebeeNotificationsComponent from "./Header/TablebeeNotificationsComponent";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

/**
 * mixins
 */
import cashoutTSEMixin from "@/mixins/pos/cashout/cashoutTSEMixin";
import printInterimBill from "@/mixins/pos/buttons/printInterimBill";

library.add(
    faEllipsisV,
);
export default {
  components: {
    TablebeeNotificationsComponent,
    HeaderPrinters,
    HeaderTouchKeyboard,
    UserArea,
    TablebeePrintOrderBonComponent,
    HeaderLanguage,
    'font-awesome-icon': FontAwesomeIcon,
    vueCustomScrollbar,
  },
  mixins: [cashoutTSEMixin, printInterimBill],
  data() {
    return {
      ROUTER,
      drawer: this.drawerSetter,
      currentCustomer: null,
      externalAPPS: [],
    }
  },

  props: {
    sidebarbg: String,
    layoutType: String,
    drawerSetter: Boolean
  },

  mounted() {

    this.$vuetify.theme.dark = this.$store.getters['app/isDarkTheme'];

    //CHECK IF WE NEED TO CLOSE SIDEBAR
    this.$nextTick(() => {
      if (this.currentRouteName === "pos.retail" || this.currentRouteName === "pos.gastro" || this.currentRouteName === "pos.gastro.tables" || this.currentRouteName === "canteen.MealCheckout") {
        this.drawer = false;
      }
    });
  },

  sockets: {
    "tablebee.orderCreated": function (payload) {
      if (this.$store.getters['permissions/checkModule'](54) && this.$store.getters['settings/getSettingValue']('tb_enableAutoPrinting')) {
        let cashiers = this.$store.getters['settings/getSettingValue']('tb_autoPrintingCashierIDs');
        let users = this.$store.getters['settings/getSettingValue']('tb_autoPrintingUserIDs');

        if (cashiers && cashiers.length > 0 && Array.isArray(JSON.parse(cashiers))) {
          cashiers = JSON.parse(cashiers);
        } else {
          cashiers = null;
        }

        if (users && users.length > 0 && Array.isArray(JSON.parse(users))) {
          users = JSON.parse(users);
        } else {
          users = null;
        }

        if ((cashiers && cashiers.length > 0 && cashiers.includes(this.api.auth.cashierID))) {
          if (users && users.length > 0) {
            if (users.includes(this.currentUser.userID))
              this.autoPrintTablebee(payload);
          } else {
            this.autoPrintTablebee(payload);
          }
        }
      }
    },
  },

  computed: {
    currentgroup: function () {
      return this.$route.fullPath.split('/')[1]
    },
    ...mapState([
      'backgroundSync',
      'api',
      'app'
    ]),
    ...mapGetters({
      checkRoutePermission: 'permissions/checkRoutePermission',
      currentUser: 'user/user',
      IsOfflineForCloudServer: 'hybrid/IsOfflineForCloudServer',
    }),
    currentRouteName() {
      return this.$route.name;
    },
    menuEntries() {
      let entries = [];
      let clonedEntries = JSON.parse(JSON.stringify(ROUTER.ENTRIES))

      /* BUILD MENU ENTRIED FROm config/routes.js with main entries, childs and groups */
      clonedEntries.forEach((entry) => {
        if (entry.meta.hideInNavigation)
          return;

        //hide elements that required hybrid server to be online
        if (entry.meta.hideWhenHybridOffline && this.IsOfflineForCloudServer)
          return;

        //CHECK MODULE + PERMISSION
        if (!this.checkRoutePermission(entry))
          return;

        let groups = [];
        let childs = [];

        //MAIN CHILDS
        if (entry.childs.length > 0) {
          entry.childs.forEach((mainChild) => {
            if (mainChild.meta.hideInNavigation)
              return;

            //hide elements that required hybrid server to be online
            if (mainChild.meta.hideWhenHybridOffline && this.IsOfflineForCloudServer)
              return;

            //CHECK MODULE + PERMISSION
            if (!this.checkRoutePermission(mainChild))
              return;

            childs.push(mainChild);
          });


          entry.childs = childs;
        }

        //GROUPS
        if (entry.groups.length > 0) {
          entry.groups.forEach((group) => {
            if (group.meta.hideInNavigation)
              return;


            //hide elements that required hybrid server to be online
            if (group.meta.hideWhenHybridOffline && this.IsOfflineForCloudServer)
              return;

            //CHECK MODULE + PERMISSION
            if (!this.checkRoutePermission(group))
              return;

            let groupChilds = [];

            group.childs.forEach((groupChild) => {
              if (groupChild.meta.hideInNavigation)
                return;

              //hide elements that required hybrid server to be online
              if (groupChild.meta.hideWhenHybridOffline && this.IsOfflineForCloudServer)
                return;

              //CHECK MODULE + PERMISSION
              if (!this.checkRoutePermission(groupChild))
                return;

              groupChilds.push(groupChild);
            });

            group.childs = groupChilds;

            groups.push(group);
          });

          entry.groups = groups;
        }

        entries.push(entry);
      });

      return entries;
    },
  },

  watch: {
    currentRouteName(val) {
      if (val === "pos.retail" || val === "pos.gastro" || this.currentRouteName === "pos.gastro.tables" || val === "reservations" || val === "canteen.MealCheckout") {
        this.drawer = false;
      }
    },
    IsOfflineForCloudServer(val) {
      console.log('hybrid connection status: ', val)
      this.$forceUpdate();
    }
  },

  methods: {
    autoPrintTablebee(payload) {
      if (Number(this.$store.getters['settings/getSettingValue']('tb_autoPrintOnlyInHybrid'))) {
        if (this.$store.getters["permissions/checkHybridEnabled"]("Hybrid")) {
          payload.order.saleObj = payload.items;
          this.$refs.tablebeeOrderbonPrint.printOrderBons([payload.order])
        }
      } else {
        payload.order.saleObj = payload.items;
        this.$refs.tablebeeOrderbonPrint.printOrderBons([payload.order])
      }
    },
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.commit('app/switchTheme');
    },
    printInterimBill(order) {
      let data = {
        saleID: 0,
        ts: order.timestamp,
        moneyGiven: 0,
        paymentType: order.paymentType,
        isDelivery: order.isDelivery === 1,
        serviceTime: order.serviceTime,
        pickUpNo: order.pickUpNo,
        isAsap: order.isAsap,
      }
      this.setCustomer(order.customerID).then(() => {
        data.customer = this.currentCustomer;
        this.startPrintInterimBill(order, 'delivery', data)
        this.currentCustomer = null;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err
        });
      })

    },
    async setCustomer(id) {

      // GET CUSTOMER
      await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: id,
      }).then(async (res) => {
        if (res.data.success) {
          this.currentCustomer = res.data.customer;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
      });
    },
    entryPathLookUp(entry) {
      if (entry.hasOwnProperty("path")) {
        return entry.path.includes(':') ? entry.path.split(':')[0] : entry.path;
      } else {
        return "/dashboards/analytics";
      }
    },
    selectNavEntry(entry) {
      /*this.$router.push({
          name: entry.name
      });*/
    },
    isActive(entry) {
      let active = false;

      if (entry.childs == undefined && entry.groups == undefined && entry != undefined && entry.length > 0)
        entry.forEach(entr => {
          if (entr.path === this.$route.fullPath) active = true;
        })
      if (entry.childs !== undefined)
        if (entry.childs.length > 0) {
          entry.childs.forEach(child => {
            if (child.path === this.$route.fullPath) active = true;
          })
        }

      if (entry.groups !== undefined)
        if (!active && entry.groups.length > 0) {
          entry.groups.forEach(group => {
            if (group.childs.length > 0) {
              group.childs.forEach(childGroup => {
                if (childGroup.path === this.$route.fullPath) active = true;
              })
            }
          })
        }


      return active;
    },
    // EXTEND THIS METHOD TO EXCLUDE ROUTES IN MOBILE CASE
    excluded(entry) {
      if (entry.name === 'pos.gastro.tables') {
        return this.$vuetify.breakpoint.smAndDown
      }

      return false;
    }
  }
}
</script>

<style scoped>
.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.scroll-area {

  position: relative;
  margin: auto;
  width: 300px;
  height: calc(100% - 20px);
  font-size: 14px;
  font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
}

.v-navigation-drawer--fixed {
  z-index: 1000;
}

.listMenuText {

  line-height: 2.4rem;
  color: #495057;
  text-decoration: none;
}

.listMenuIcon {
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  font-size: 1.5rem;
  opacity: .3;
}

.v-list-item--link:before {
  content: "";
  height: 100%;
  opacity: 1;
  width: 3px;
  background: #e0f3ff;
  position: absolute;
  left: 10px;
  top: 0;
  border-radius: 15px;
}
</style>



