<template>
  <div>
    <v-snackbar top
        v-model="loading" v-if="!noLoader" color="primary lighten-1"
    >
      {{ $t("tablebee.lang_printingTablebeeOrders") }}...
      <v-progress-linear indeterminate size="10" rounded color="white"/>
    </v-snackbar>
    
    <v-dialog persistent max-width="500" v-model="showDialog">
      <v-card>
        <v-toolbar dark flat>
          <v-toolbar-title>{{ $t("generic.lang_orderbonPrinting") }}</v-toolbar-title>
          <v-card-subtitle class="white--text">{{ $t("generic.lang_errorOccurred") }}</v-card-subtitle>
        </v-toolbar>

        <v-card-text style="padding: 0;">
          <v-list style="padding-top: 0;">
            <v-list-item v-for="(rejectedPrint, index) in rejectedPrintResults" :key="index">
              <v-list-item-content>{{ $t("settings.langOrderbonPrinter") }} {{ rejectedPrint.reason.payload.targetPrinter.address }}
                (ID {{ rejectedPrint.reason.payload.targetPrinter.id }}) {{ $t("generic.lang_isNotAvailable") }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" @click="cancelReprint" :disabled="reprintLoading" text>{{$t("generic.lang_cancel")}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="reprintRejectedPromises" :disabled="reprintLoading"
                 :loading="reprintLoading">{{ $t("generic.lang_print_again") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {Events} from "@/plugins/events";
import {createOrderBonPrintingData} from "@/plugins/printing/printOrderBon";
import {createVoidOrderBonPrintingData} from "@/plugins/printing/printVoidOrderBon";
import moment from "moment";
import {printDataFromPrinter} from "../../plugins/printing/printerController";

export default {
  name: "TablebeePrintOrderBonComponent",
  props:{
    noLoader:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      loading:false,
      printResults: [],
      reprintLoading: false
    }
  },
  computed:{
    ...mapState([
      'api',
      'pos',
      'settings',
      'posLayoutTemplates',
      'user'
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    ...mapGetters({
      activeUser: 'multiUser/activeUser',
      itemsTotalPrice: 'pos/gastro/totalPrice',
    }),
    rejectedPrintResults() {
      return this.printResults.filter((printResult) => printResult.status === "rejected");
    },
    showDialog() {
      return this.rejectedPrintResults.length > 0;
    },
    realUser() {
      return this.user;
    }
  },
  methods: {
    cancelReprint() {
      this.$emit("cancelReprint");

      this.printResults = [];
    },
    printOrderBons(orders) {
      this.loading=true;
      return new Promise((resolve, reject) => {
        const partyPromises = [];

        orders.forEach(order=>{
          //PUSH PROMISE TO QUEUE
          partyPromises.push(this.printItems({name:order.tableName}, {name: order.partyName},order));
        })
        //WAIT FOR ALL PROMISES FINISHING
        Promise.allSettled(partyPromises).then((results) => {
          //CHECK IF PROMISE HAS ERROR
          let promiseError = results.find((result) => result.status === "rejected");

          if (promiseError)
            reject(promiseError);
          else
            resolve();
        }).catch((err) => {
          reject(err);
        }).finally(()=>{
          this.loading=false;
        })
      })
    },
    printItems(table, party,order) {
      let orderedItems = order.saleObj;
      //GET OPEN ITEMS
      let openItems = orderedItems.filter((orderedItem) => !orderedItem.isVoid);

      return new Promise( (resolve, reject) => {
        (async () => {


          //GET ALL ORDERBON PRINTERS
          let targetPrinters = {};
          let orderData = {
            timestamp: order.timestamp,
            table: (table !== null ? table.name : "Tresenverkauf"),
            party: order.partyName,
            userID: 0,
            orderFreetext: order.orderFreetext,
          };
          if (openItems)
              // !!!
              // USE FOR-LOOP
              // because forEach is not promise-aware. It cannot support async and await. You cannot use await in forEach.
              // !!!
            for (let openItem in openItems) {
              if (!openItems.hasOwnProperty(openItem))
                continue;

              openItem = openItems[openItem];

              let targetPrinter = await this.getOrderBonPrinter(openItem);
              let dupTargetPrinter = await this.getDupOrderBonPrinter(openItem);

              if (targetPrinter !== null) {
                //CHECK IF PRINTER ALREADY ADDED TO LIST
                if (!targetPrinters.hasOwnProperty(targetPrinter.id)) {
                  //ADD EMPTY ARRAY
                  targetPrinter.items = [];

                  targetPrinters[targetPrinter.id] = targetPrinter;
                }

                targetPrinters[targetPrinter.id].items.push(openItem);
              }
              //CHECK FOR DUP ITEMGROUP ORDER BON PRINTER

              if (dupTargetPrinter !== null) {
                //CHECK IF PRINTER ALREADY ADDED TO LIST
                if (!targetPrinters.hasOwnProperty(dupTargetPrinter.id)) {
                  //ADD EMPTY ARRAY
                  dupTargetPrinter.items = [];

                  targetPrinters[dupTargetPrinter.id] = dupTargetPrinter;
                }

                targetPrinters[dupTargetPrinter.id].items.push(openItem);
              }
            }

          //CHECK IF NEED TO PRINT VOIDED ITEMS
          //THEN GET ALL TARGET PRINTERS FOR VOIDED ITEMS
          let voidedItems = orderedItems.filter((orderedItem) => orderedItem.isVoid);

          let voidedItemsToPrint = voidedItems
          let targetPrintersVoid = {};

          if (voidedItemsToPrint.length > 0) {
            for (let openVoidItem in voidedItemsToPrint) {

              openVoidItem = voidedItemsToPrint[openVoidItem];

              let targetPrinter = await this.getOrderBonPrinter(openVoidItem);

              if (targetPrinter !== null) {
                //CHECK IF PRINTER ALREADY ADDED TO LIST
                if (!targetPrintersVoid.hasOwnProperty(targetPrinter.id)) {
                  //ADD EMPTY ARRAY
                  targetPrinter.items = [];
                  targetPrintersVoid[targetPrinter.id] = targetPrinter;
                }

                targetPrintersVoid[targetPrinter.id].items.push(openVoidItem);
              }
            }
          }

          //SKIP PRINTING WHEN NO TARGET PRINTERS ARE AVAILABLE
          /*
          if (Object.keys(targetPrinters).length === 0)
              return resolve();
           */

          //CREATE ARRAY WITH PROMISES
          const printPromises = [];

          for (let targetPrinter in targetPrinters) {
            if (!targetPrinters.hasOwnProperty(targetPrinter))
              continue;

            //PUSH PROMISE TO QUEUE
            printPromises.push(this.orderbonPrintPromise(orderData, party, table, targetPrinters[targetPrinter]));
          }

          //LOOP TARGET PRINTERS VOID
          for (let targetPrinterVoid in targetPrintersVoid) {
            if (!targetPrintersVoid.hasOwnProperty(targetPrinterVoid))
              continue;

            //PUSH PROMISE TO QUEUE
            printPromises.push(this.orderbonPrintPromise(orderData, party, table, targetPrintersVoid[targetPrinterVoid], true));
          }

          //WAIT FOR ALL PROMISES FINISHING
          Promise.allSettled(printPromises).then((results) => {
            //APPEND PRINT RESULTS
            this.printResults = this.printResults.concat(results);

            //CHECK IF PROMISE HAS ERROR
            let promiseError = results.find((result) => result.status === "rejected");

            if (promiseError)
              reject(promiseError);
            else
              resolve();
          }).catch((err) => {
            reject();
          }).finally(() => {

          });
        })()
      });
    },
    async getOrderBonPrinter(item) {
      // FIRST GET ITEM
      let originalItem = await this.$store.dispatch("items/getItemByID", item.id);

      if (!originalItem)
        return null;

      let itemOrderbonprinterID = originalItem.orderbonPrinterID;

      // GET ITEMGROUP
      let itemgroupOrderbonprinterID = null;

      let itemgroup = await this.$store.dispatch("itemgroups/getItemgroupByID", originalItem.itemgroupID);

      if (itemgroup)
        itemgroupOrderbonprinterID = itemgroup.orderbonPrinterID;

      // --------------------------------------------------
      //CHECK ORDERBON PRINTER ON ITEM
      if (itemOrderbonprinterID > 0) {
        let orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(itemOrderbonprinterID));
        if (orderbonPrinter) {
          //CHECK IF VIRTUAL PRINTER
          if (orderbonPrinter.type === 4) {
            if (orderbonPrinter.virtualOrderbonPrinterAssignment) {
              if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('rooms')) {
                let roomPrinter = null;
                // CHECK IF DIRECT SALE OR TABLE
                if (this.table && this.table.name !== 0) {

                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === this.pos.gastro.lastRoom.id)
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  } else {
                    //going back to default if room doesn't have a assigned printer
                    roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                    if (roomPrinter) {
                      orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id))
                    }
                  }
                } else {
                  // SEARCHING FOR THE DEFAULT PRINTER FOR DIRECT SALE
                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);

              } else if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('cashiers')) {
                let cashierPrinter = null;

                cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === this.api.auth.cashierID)
                if (cashierPrinter) {
                  orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id));
                } else {
                  cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === 'default')
                  if (cashierPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id))
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);
              }
            }
          } else {
            return Object.assign({}, orderbonPrinter);
          }
        }

      }

      //CHECK ORDERBON PRINTER ON ITEMGROUP
      if (!itemgroup)
        return null;
      if (itemgroupOrderbonprinterID > 0) {
        let orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(itemgroupOrderbonprinterID));

        if (orderbonPrinter) {
          //CHECK IF VIRTUAL PRINTER
          if (orderbonPrinter.type === 4) {
            if (orderbonPrinter.virtualOrderbonPrinterAssignment) {
              if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('rooms')) {
                let roomPrinter = null;
                // CHECK IF DIRECT SALE OR TABLE
                if (this.table && this.table.name !== 0) {
                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === this.pos.gastro.lastRoom.id)
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  } else {
                    //going back to default if room doesn't have a assigned printer
                    roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                    if (roomPrinter) {
                      orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id))
                    }
                  }
                } else {
                  // SEARCHING FOR THE DEFAULT PRINTER FOR DIRECT SALE
                  roomPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.rooms.find(room => room.id === 'default')
                  if (roomPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(roomPrinter.printer_id));
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);

              } else if (orderbonPrinter.virtualOrderbonPrinterAssignment.hasOwnProperty('cashiers')) {
                let cashierPrinter = null;

                cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === this.api.auth.cashierID)
                if (cashierPrinter) {
                  orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id));
                } else {
                  cashierPrinter = orderbonPrinter.virtualOrderbonPrinterAssignment.cashiers.find(cashier => cashier.id === 'default')
                  if (cashierPrinter) {
                    orderbonPrinter = this.printers.find((printer) => Number(printer.id) === Number(cashierPrinter.printer_id))
                  }
                }

                if (orderbonPrinter && orderbonPrinter.type !== 4)
                  return Object.assign({}, orderbonPrinter);
              }
            }
          } else {
            return Object.assign({}, orderbonPrinter);
          }
        }
        //NO PRINTER FOUND
        return null;
      } else if (itemgroupOrderbonprinterID === 0) {
        // FIND MAIN PRINTER OF THIS CASHIER ID
        let orderbonPrinter = this.printers.find((printer) => printer.type === 1 && printer.cashierID.includes(this.api.auth.cashierID));

        if (orderbonPrinter)
            //CLONE OBJECT
          return Object.assign({}, orderbonPrinter);
      }

      return null;
    },

    async getDupOrderBonPrinter(item) {
      // FIRST GET ITEM
      let originalItem = await this.$store.dispatch("items/getItemByID", item.id);

      if (!originalItem)
        return null;

      // GET ITEMGROUP
      let itemgroupDupOrderbonprinterID = null;

      let itemgroup = await this.$store.dispatch("itemgroups/getItemgroupByID", originalItem.itemgroupID);

      if (itemgroup)
        itemgroupDupOrderbonprinterID = itemgroup.duplicate_orderbonPrinterID;

      //CHECK DUP ORDERBON PRINTER ON ITEMGROUP
      if (!itemgroup)
        return null;

      if (itemgroupDupOrderbonprinterID > 0) {
        let orderbonPrinter = this.printers.find((printer) => printer.id === itemgroupDupOrderbonprinterID);

        if (orderbonPrinter)
            //CLONE OBJECT
          return Object.assign({}, orderbonPrinter);

        //NO PRINTER FOUND
        return null;
      }

      return null;
    },
    orderbonPrintPromise(orderData, party, table, targetPrinter, isVoidPrint = false) {
      //RETURN PROMISE
      return new Promise((resolve, reject) => {
        //GENERATE INVOICE DATA
        let data = {
          userID: orderData.userID,
          timestamp: orderData.timestamp,
          table: (table !== null ? table.name : "Tresenverkauf"),
          party: party.name,
        };
        //GET XML PRINTING DATA
        let printData;
        try {
          if (!isVoidPrint)
            printData = createOrderBonPrintingData(targetPrinter.items, data, targetPrinter.options, orderData.orderFreetext, this.realUser);
          else
            printData = createVoidOrderBonPrintingData(targetPrinter.items, data, targetPrinter.options, orderData.orderFreetext, this.realUser);
        } catch (err) {
          reject({
            error: err,
            payload: {
              orderData: orderData,
              party: party,
              table: table,
              targetPrinter: targetPrinter
            }
          });
        }

        //CHECK DEVICE ID
        let deviceID;

        if (typeof targetPrinter.deviceID === 'undefined' || targetPrinter.deviceID.length < 2) {
          deviceID = "local_printer"
        } else {
          deviceID = targetPrinter.deviceID;
        }

        //PRINT XML
        printDataFromPrinter(targetPrinter,printData,deviceID).then(()=>{
          resolve();
        }).catch((err)=>{
          reject({
            error: err,
            payload: {
              orderData: orderData,
              party: party,
              table: table,
              targetPrinter: targetPrinter
            }
          });
        }).finally(()=>{

        })


      });
    },
    reprintRejectedPromises() {
      this.reprintLoading = true;

      //CREATE ARRAY WITH PROMISES
      const printPromises = [];

      this.rejectedPrintResults.forEach((rejectedPrintResult) => {
        printPromises.push(this.orderbonPrintPromise(rejectedPrintResult.reason.payload.orderData, rejectedPrintResult.reason.payload.party, rejectedPrintResult.reason.payload.table, rejectedPrintResult.reason.payload.targetPrinter,))
      });

      //WAIT FOR ALL PROMISES FINISHING
      Promise.allSettled(printPromises).then((results) => {
        this.printResults = results;
        this.reprintLoading = false;

        //CHECK IF PROMISE HAS ERROR
        let promiseError = results.find((result) => result.status === "rejected");

        if (!promiseError) {
          this.$emit("reprintFinished");
        }
      }).catch(() => {
        this.$emit("reprintFinished");
      });
    }
  }
}
</script>

<style scoped>
.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>