export default {
    namespaced: true,

    state: {
        apiWebToken: "",
        apiWebTokenExpiration: 0,
        accessString: "",
        posID: 0,
        cashierID: 1
    },

    mutations: {
        setResetPasswordCreds(state, data) {
            state.userId = data.userId;
            state.resetToken = data.resetToken;
        },
        deleteResetPasswordCreds(state, data) {
            if (state.hasOwnProperty('userId') && state.hasOwnProperty('resetToken')) {
                delete state.userId;
                delete state.resetToken;
            }
        },
        update(state, data) {
            state = data;
        },
        init(state, data) {
            state.accessString = data.accessString;
            state.posID = data.posID;
            state.cashierID = parseInt(data.cashierID);
        },
        updateToken(state, token) {
            state.apiWebToken = token;
        },
        clear(state) {
            state.apiWebToken = "";
            state.apiWebTokenExpiration = 0;
            state.accessString = "";
            state.posID = 0;
            state.cashierID = 1;
        },
        setCashierID(state, cashierID) {
            state.cashierID = parseInt(cashierID);
        },
        updateTokenExpiration(state, data) {
            state.apiWebTokenExpiration = data;
        },
        restoreAfterVuexReset(state, data) {
            state.apiWebToken = data.apiWebToken;
            state.apiWebTokenExpiration = data.apiWebTokenExpiration;
            state.accessString = data.accessString;
            state.posID = data.posID;
            state.cashierID = data.cashierID;
        }
    },

    actions: {
        update({commit}, data) {
            commit('update', data);
        },
        init({commit}, data) {
            commit('init', data);
        },
        updateToken({commit}, token) {
            commit('updateToken', token);
        },
        clear({commit}) {
            commit('clear');
        },
        setCashierID({commit}, data) {
            commit('setCashierID', data);
        },
        updateTokenExpiration({commit}, data) {
            commit('updateTokenExpiration', data);
        }
    },

    getters: {
        cashierAliasName: (state, getters, rootState) => {
            let cashiers = rootState.cashierIDs.cashiers;
            let cashierID = state.cashierID;

            let cashier = cashiers.find((cashier) => cashier.id === cashierID);

            if (!cashier)
                return "";

            return cashier.aliasName;
        },
    }
};
