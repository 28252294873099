export default {
    namespaced: true,

    state: {
        loggedIn: false,
        userID: 0,
        name: "",
        last_name: "",
        avatarURI: "",
        fullscreen: false,
    },

    mutations: {
        setFullscreen(state, payload) {
            state.fullscreen = payload;
        },
        clearData(state) {
            state.loggedIn = false;
            state.userID = 0;
            state.name = "";
            state.last_name = "";
            state.avatarURI = "";
        },
        login(state, data) {
            state.loggedIn = true;
            state.userID = data.userID;
            state.name = data.name;
            state.last_name = data.last_name;
            state.avatarURI = data.avatarURI;
        }
    },

    getters: {
        user: (state) => {
            return {
                userID: state.userID,
                fullname: state.name + " " + state.last_name,
                name: state.name,
                last_name: state.last_name,
                avatarURI: state.avatarURI
            }
        },
        getFullscreen(state) {
            return state.fullscreen;
        },
        __loggedIn: state => state.loggedIn,
    },

    actions: {
        clearData({commit}) {
            commit('clearData');
        },
        login({commit, dispatch, rootState}, data) {
            commit('login', data);

            //CONNECT TO SOCKET SERVER
            data.vm.$socket.io.opts.query = {
                bsid: rootState.api.auth.accessString,
                bs: parseInt(rootState.api.auth.posID),
                apiToken: rootState.api.auth.apiWebToken,
                cashierID: parseInt(rootState.api.auth.cashierID),
                userID: parseInt(rootState.user.userID),
                clientType: 1
            };

            data.vm.$socket.open();

            if (!rootState.app.initDataLoaded) {
                dispatch('backgroundSync/fullSync', {}, {
                    root: true
                });
            } else {
                //START BACKGROUND SYNC
                dispatch('backgroundSync/start', {
                    lastUpdate: null,
                    lastRow: null
                }, {
                    root: true
                });
            }
        },
        logout({state, commit, rootState, rootGetters}, data) {
            commit("clearData");

            //STOP BACKGROUND SYNC
            commit('backgroundSync/stop', null, {
                root: true
            });

            // CLOSE WEBSOCKET CONNECTION
            data.vm.$socket.disconnect();

            // CHECK TSE
            try {
                let fiscalClient = rootGetters["tse/fiscalClient"];

                if (fiscalClient !== null) {
                    let tseDevice = data.vm.$eposClass.getTSEPrinter(fiscalClient.id);

                    if (tseDevice !== null) {
                        tseDevice.disconnect();

                        data.vm.$eposClass.removeTSEPrinter(fiscalClient.id)
                    }
                }
            } catch (error) {
                console.log("vuex: user", error);
            }

            rootState.api.auth.apiWebToken = "";
            rootState.api.auth.apiWebTokenExpiration = 0;
        },
    }
};
