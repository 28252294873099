export let erpRoutes = {
  meta: {
    menuTitle: 'erp.lang_wawi',
    icon: 'pe-7s-graph2',
  },
  groups: [
    {
      meta: {
        menuTitle: 'erp.lang_baseData',
        icon: 'pe-7s-plugin',
      },
      childs: [
        {
          path: '/erp/baseData/garnish/add',
          name: 'erp.baseData.garnish.add',
          childs: [],
          meta: {
            siteTitle: 'erp.lang_garnish',
            menuTitle: 'erp.lang_garnish',
            layout: 'default',
            icon: '',
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: 'ware_create',
              hybridDisplay: 'Server',
            },
          },
          component: () => import('../views/erp/baseData/AddUpdateGarnish.vue'),
        },
        {
          path: '/erp/baseData/garnish/edit/:id',
          name: 'erp.baseData.garnish.edit',
          props: true,
          childs: [],
          meta: {
            siteTitle: 'erp.lang_garnish',
            menuTitle: 'erp.lang_garnish',
            layout: 'default',
            icon: '',
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: 'ware_edit',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/AddUpdateGarnish.vue'),
        },
        {
          path: '/erp/baseData/items/edit/:id',
          name: 'erp.baseData.items.edit',
          props: true,
          meta: {
            siteTitle: 'erp.lang_items',
            menuTitle: 'erp.lang_items',
            layout: 'default',
            hideInNavigation: true,
            icon: '',
            permission: {
              requiredModule: 0,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/ItemEdit.vue'),
        },
        {
          path: '/erp/baseData/items',
          name: 'erp.baseData.items',
          meta: {
            siteTitle: 'erp.lang_items',
            menuTitle: 'erp.lang_items',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 0,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/Items.vue'),
        },
        {
          path: '/erp/baseData/item/create',
          name: 'erp.baseData.item.create',
          meta: {
            siteTitle: 'erp.lang_items',
            menuTitle: 'erp.lang_items',
            hideInNavigation: true,
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 0,
              requiredPermission: '',
              hybridDisplay: 'Server',
            },
          },
          component: () => import('../views/erp/baseData/ItemCreate.vue'),
        },
        {
          path: '/erp/baseData/garnish',
          name: 'erp.baseData.garnish',
          meta: {
            siteTitle: 'erp.lang_extra',
            menuTitle: 'erp.lang_extra',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 19,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/Garnish.vue'),
        },
        {
          path: '/erp/baseData/garnishgroup',
          name: 'erp.baseData.garnishgroup',
          meta: {
            siteTitle: 'erp.lang_garnishGroups',
            menuTitle: 'erp.lang_garnishGroups',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 19,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () =>
            import('../views/erp/baseData/garnishgroup/GarnishGroups'),
        },
        {
          path: '/erp/baseData/garnishgroup/create',
          name: 'erp.baseData.garnishgroup.create',
          meta: {
            siteTitle: 'erp.lang_garnishGroups',
            menuTitle: 'erp.lang_garnishGroups',
            layout: 'default',
            icon: '',
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () =>
            import('../views/erp/baseData/garnishgroup/CreateGarnishGroup'),
        },
        {
          path: '/erp/baseData/garnishgroup/edit/:uuid',
          name: 'erp.baseData.garnishgroup.edit',
          meta: {
            siteTitle: 'erp.lang_garnishGroups',
            menuTitle: 'erp.lang_garnishGroups',
            layout: 'default',
            icon: '',
            hideInNavigation: true,
            permission: {
              requiredModule: 19,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () =>
            import('../views/erp/baseData/garnishgroup/EditGarnishGroup'),
        },
        {
          path: '/erp/baseData/itemgroups',
          name: 'erp.baseData.itemgroups',
          meta: {
            siteTitle: 'erp.lang_nav_waregroups',
            menuTitle: 'erp.lang_nav_waregroups',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 0,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/Itemgroups.vue'),
        },
        {
          path: '/erp/baseData/itemgroup/edit/:id',
          props: true,
          name: 'erp.baseData.itemgroup.edit',
          meta: {
            siteTitle: 'erp.lang_nav_waregroups',
            menuTitle: 'erp.lang_nav_waregroups',
            layout: 'default',
            icon: '',
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/ItemgroupsEdit.vue'),
        },
        {
          path: '/erp/baseData/itemgroups/create',
          name: 'erp.baseData.itemgroups.create',
          meta: {
            siteTitle: 'erp.lang_nav_waregroups',
            menuTitle: 'erp.lang_nav_waregroups',
            layout: 'default',
            icon: '',
            hideInNavigation: true,
            permission: {
              requiredModule: 0,
              requiredPermission: '',
              hybridDisplay: 'Server',
            },
          },
          component: () => import('../views/erp/baseData/ItemgroupsCreate.vue'),
        },
        {
          path: '/erp/baseData/voucher',
          name: 'erp.baseData.voucher',
          meta: {
            siteTitle: 'erp.lang_nav_vouchers',
            menuTitle: 'erp.lang_nav_vouchers',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 31,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/Vouchers.vue'),
        },
        {
          path: '/erp/baseData/giftCard',
          name: 'erp.baseData.giftCard',
          meta: {
            siteTitle: 'erp.lang_nav_voucherCards',
            menuTitle: 'erp.lang_nav_voucherCards',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 31,
              requiredPermission: '',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/baseData/GiftCards.vue'),
        },
      ],
    },
    {
      meta: {
        menuTitle: 'settings.lang_settings',
        icon: 'pe-7s-tools',
        permission: {},
      },
      childs: [
        {
          path: '/erp/settings/tax',
          name: 'erp.settings.tax',
          meta: {
            siteTitle: 'erp.lang_nav_wawi_taxset',
            menuTitle: 'erp.lang_nav_wawi_taxset',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 0,
              requiredPermission: 'accounting_displayTaxRates',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/settings/TaxSetup.vue'),
        },
        {
          path: '/erp/settings/garnishTabs',
          name: 'erp.settings.garnishTabs',
          meta: {
            siteTitle: 'erp.lang_extraTabsZwaenge',
            menuTitle: 'erp.lang_extraTabsZwaenge',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 19,
              requiredPermission: 'garnishTabs',
              hybridDisplay: 'Server',
            },
          },
          component: () => import('../views/erp/settings/ExtraTab.vue'),
        },
        {
          path: '/erp/settings/freeTextTags',
          name: 'erp.settings.freeTextTags',
          meta: {
            siteTitle: 'erp.lang_freeTextTabs',
            menuTitle: 'erp.lang_freeTextTabs',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 2,
              requiredPermission: 'freeTextTags',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/settings/FreeTextTags.vue'),
        },
        {
          path: '/erp/settings/mealSizes',
          name: 'erp.settings.mealSizes',
          meta: {
            siteTitle: 'erp.lang_SizeVariation',
            menuTitle: 'erp.lang_SizeVariation',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 24,
              requiredPermission: 'mealsizes',
              hybridDisplay: 'Server',
            },
          },
          component: () => import('../views/erp/settings/SizeVariations.vue'),
        },
        {
          path: '/erp/settings/deposit',
          name: 'erp.settings.deposit',
          meta: {
            siteTitle: 'erp.lang_deposit',
            menuTitle: 'erp.lang_deposit',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 7,
              requiredPermission: 'deposit',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/settings/Deposit.vue'),
        },
        {
          path: '/erp/settings/wareImport',
          name: 'erp.settings.wareImport',
          meta: {
            siteTitle: 'erp.lang_wareImport',
            menuTitle: 'erp.lang_wareImport',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 32,
              requiredPermission: 'itemImport',
              hybridDisplay: 'Server',
            },
          },
          component: () => import('../views/erp/settings/ItemImportView.vue'),
        },
        {
          path: '/erp/settings/voidReasons',
          name: 'erp.settings.voidReasons',
          meta: {
            siteTitle: 'accounting.lang_nav_retour_loss_reasons',
            menuTitle: 'accounting.lang_nav_retour_loss_reasons',
            layout: 'default',
            icon: '',
            permission: {
              requiredModule: 0,
              requiredPermission: 'voidReasons',
              hybridDisplay: '',
            },
          },
          component: () => import('../views/erp/settings/VoidReasons.vue'),
        },
      ],
    },
  ],
  childs: [],
};
