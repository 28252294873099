import store from "../../store/index";
import lodash from "lodash";
import {geol} from "../../main";
import {format} from 'mathjs';
import {formatCurrency} from '../currencyFormater';

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
// we re gonna use this work arround for now until we find a better way
let geoLocale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
if (geoLocale) {
    geol.locale = geoLocale;
}

function convertPaymentType(paymentType) {
    paymentType = parseInt(paymentType);
    // CHECKING ADDITIONAL PAYMENTS
    if (paymentType >= 500 && store.state.payments.payments && store.state.payments.payments.length > 0) {
        let payment = store.state.payments.payments.find(search => search.paymentID === paymentType)
        if (payment)
            return payment.paymentName;
        else
            return paymentType;
    }

    if (paymentType === 1) return geol.t('generic.lang_cash');
    if (paymentType === 2) return geol.t('erp.lang_voucher');
    if (paymentType === 3) return "EC";
    if (paymentType === 4) return geol.t('accounting.lang_bill');
    if (paymentType === 7) return geol.t('generic.lang_split_table');
    if (paymentType === 9) return geol.t('generic.lang_creditCard');
    if (paymentType === 101 || paymentType === 102 || paymentType === 103) return "AUFS ZIMMER";
    if (paymentType === 11) return "Paypal";

    return paymentType;
}

//GETTING THE PAYMENT NOTE FROM SYSTEM SETTINGS

function getPaymentTypeNote(paymentType) {
    paymentType = parseInt(paymentType);

    if (paymentType === 1) {
        if (store.state.settings.settings.cashPaymentText != "0") {
            return store.state.settings.settings.cashPaymentText;
        } else {
            return ''
        }
    } else if (paymentType === 3) {
        if (store.state.settings.settings.ecPaymentText != "0") {
            return store.state.settings.settings.ecPaymentText;
        } else {
            return ''
        }
    } else if (paymentType === 4) {
        if (store.state.settings.settings.invoicePaymentRecieptFreeText != "0") {
            return store.state.settings.settings.invoicePaymentRecieptFreeText;
        } else {
            return ''
        }
    } else if (paymentType === 9) {
        if (store.state.settings.settings.ecPaymentText != "0") {
            return store.state.settings.settings.ecPaymentText;
        } else {
            return ''
        }
    }

    return '';
}

export function createInvoicePrintingData(items, data, printerOpts, activeUser, interimsPrint = false, deliveryNotePrint = false, goodsValue = 0, vouchersValue = 0,) {
    let printObj = [];
    let taxCalcObj = {};
    let totalprice = 0.00;

    if(activeUser && activeUser.userID===101){
        printObj.push({feed: 1});
        printObj.push({align: 'center'});
        printObj.push({fontSize: 2});
        printObj.push({reverse: 'on'});
        printObj.push({text: geol.t('generic.lang_training').toUpperCase()});
        printObj.push({reverse: 'off'});
        printObj.push({fontSize: 1});
        printObj.push({feed: 1});
    }

    var pickUpNo = "";
    if (data.hasOwnProperty('isDelivery') && data.hasOwnProperty('pickUpNo') && data.pickUpNo) {
        pickUpNo = data.pickUpNo;
    } else if (typeof store.state.pos.gastro.pickup === 'undefined') {
        pickUpNo = "";
    } else {
        pickUpNo = store.state.pos.gastro.pickup;
    }

    let customerData = "";
    let customerAddress = "";
    let customerPhone = "";
    let customerFloor = "";

    if (data.hasOwnProperty('isDelivery') && data.hasOwnProperty('customer') && data.customer) {
        if (data.customer.company !== null) {
            customerData += data.customer.company + " \n";
        }
        if (data.customer.departmentName !== null) {
            customerData += data.departmentName + " \n";
        }
        if (data.customer.salutationName !== null) {
            customerData += data.customer.salutationName + " ";
        }
        if (data.customer.last_name !== null) {
            customerData += data.customer.first_name + " " + data.customer.last_name + " \n";
        }
        if (data.isDelivery) {
            if (data.customer.street !== null) {
                customerData += data.customer.street + " " + data.customer.street_no + "\n " + data.customer.zip + " " + data.customer.city + " \n";
                customerAddress += data.customer.street ? data.customer.street + " " + data.customer.street_no + " " + data.customer.zip + " " + data.customer.city + "" : '';
            }
            if (data.customer.mobil_private) {
                customerPhone += data.customer.mobil_private;
            }
            if (data.customer.BuildingInfo && data.customer.BuildingInfo.hasOwnProperty('floor') && data.customer.BuildingInfo.floor) {
                customerFloor = data.customer.BuildingInfo.floor;
            }
        }
    } else if (typeof store.state.pos.gastro.customer !== 'undefined' && store.state.pos.gastro.customer !== null) {
        if (store.state.pos.gastro.customer.company !== null) {
            customerData += store.state.pos.gastro.customer.company + " \n";
        }
        if (store.state.pos.gastro.customer.departmentName !== null) {
            customerData += store.state.pos.gastro.customer.departmentName + " \n";
        }
        if (store.state.pos.gastro.customer.salutationName !== null) {
            customerData += store.state.pos.gastro.customer.salutationName + " ";
        }
        if (store.state.pos.gastro.customer.last_name !== null) {
            customerData += store.state.pos.gastro.customer.first_name + " " + store.state.pos.gastro.customer.last_name + " \n";
        }
        if ((data.hasOwnProperty('isDelivery') && data.isDelivery) || !data.hasOwnProperty('isDelivery')) {
            if (store.state.pos.gastro.customer.street !== null) {
                customerData += store.state.pos.gastro.customer.street + " " + store.state.pos.gastro.customer.street_no + "\n " + store.state.pos.gastro.customer.zip + " " + store.state.pos.gastro.customer.city + " \n";
                customerAddress += store.state.pos.gastro.customer.street ? store.state.pos.gastro.customer.street + " " + store.state.pos.gastro.customer.street_no + " " + store.state.pos.gastro.customer.zip + " " + store.state.pos.gastro.customer.city + "" : '';
            }
            if (store.state.pos.gastro.customer.mobil_private) {
                customerPhone += store.state.pos.gastro.customer.mobil_private;
            }
            if (store.state.pos.gastro.customer.BuildingInfo && store.state.pos.gastro.customer.BuildingInfo.hasOwnProperty('floor') && store.state.pos.gastro.customer.BuildingInfo.floor) {
                customerFloor = store.state.pos.gastro.customer.BuildingInfo.floor;
            }
        }
    } else if (typeof store.state.pos.retail.customer !== 'undefined' && store.state.pos.retail.customer !== null) {
        if (store.state.pos.retail.customer.company !== null) {
            customerData += store.state.pos.retail.customer.company + " \n";
        }
        if (store.state.pos.retail.customer.departmentName !== null) {
            customerData += store.state.pos.retail.customer.departmentName + " \n";
        }
        if (store.state.pos.retail.customer.salutationName !== null) {
            customerData += store.state.pos.retail.customer.salutationName + " ";
        }
        if (store.state.pos.retail.customer.last_name !== null) {
            customerData += store.state.pos.retail.customer.first_name + " " + store.state.pos.retail.customer.last_name + " \n";
        }
        if (store.state.pos.retail.customer.street !== null) {
            customerData += store.state.pos.retail.customer.street + " " + store.state.pos.retail.customer.street_no + "\n " + store.state.pos.retail.customer.zip + " " + store.state.pos.retail.customer.city + " \n";
        }
    }

    //CALC TAX AND TOTAL
    //TAX DECIMAL => array(NET,TOTAL,TAXTOTAL)
    //NUMBER OF ITEMS FOR DELIVERY SERVICE
    let itemsNumber = 0;
    Array.from(items).forEach((item) => {
        if (item.isVoid)
            return;

        let itemSellPrice = item.sellPrice;

        // INCREMENTING ITEMSNUMBER
        itemsNumber += Math.abs(item.amount);

        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
            if (item.depositgroupID > 0) {
                let deposit = store.getters['deposit/depositgroups'].find((depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                });

                if (deposit)
                    itemSellPrice += deposit.priceTotal;
            }
        }

        //CALC TOTAL PRICE
        totalprice += parseFloat((itemSellPrice * Math.abs(item.amount)));

        //CHECK IF VOUCHER
        if ((item.ean.includes("GF$") || item.ean.includes("V$") || item.ean.includes("ZMYLE$")) && item.sellPrice < 0)
            return;

        if (item.isMenuItem === 1) {
            // MENU ITEM
            // GET TAX VALUES FROM ITEM
            for (let taxValue in item.menuTaxRules) {
                if (!item.menuTaxRules.hasOwnProperty(taxValue))
                    continue;

                let taxPercentage = item.menuTaxRules[taxValue];
                taxPercentage = taxPercentage / 100;

                taxValue = parseFloat(taxValue);

                //CHECK IF OBJECT VALUE EXISTS
                if (typeof taxCalcObj[taxValue] === 'undefined') {
                    taxCalcObj[taxValue] = [];
                    taxCalcObj[taxValue]['net'] = 0.00;
                    taxCalcObj[taxValue]['total'] = 0.00;
                    taxCalcObj[taxValue]['tax'] = 0.00;
                }

                // CALC TAX OF PERC.

                let calcNet = parseFloat((format((itemSellPrice * taxPercentage) / (1 + (taxValue / 100)), {precision: 14}) * Math.abs(item.amount)));
                let calcTax = parseFloat((format((itemSellPrice * taxPercentage), {precision: 14}) * Math.abs(item.amount))) - calcNet;

                taxCalcObj[taxValue]['net'] += calcNet;
                taxCalcObj[taxValue]['tax'] += calcTax;
                taxCalcObj[taxValue]['total'] += parseFloat(((itemSellPrice * taxPercentage) * Math.abs(item.amount)));
            }
        } else {
            //CHECK IF OBJECT VALUE EXISTS
            if (typeof taxCalcObj[item.taxValue] === 'undefined') {
                taxCalcObj[item.taxValue] = [];
                taxCalcObj[item.taxValue]['net'] = 0.00;
                taxCalcObj[item.taxValue]['total'] = 0.00;
                taxCalcObj[item.taxValue]['tax'] = 0.00;
            }

            let calcNet = parseFloat((format(itemSellPrice / (1 + (item.taxValue / 100)), {precision: 14}) * Math.abs(item.amount)));
            let calcTax = parseFloat((itemSellPrice * Math.abs(item.amount))) - calcNet;

            taxCalcObj[item.taxValue]['net'] += calcNet;
            taxCalcObj[item.taxValue]['tax'] += calcTax;
            taxCalcObj[item.taxValue]['total'] += parseFloat((itemSellPrice * Math.abs(item.amount)));
        }
    });

    if (vouchersValue > 0) {
        //PREPARE 0% TAX ARRAY
        taxCalcObj[0] = [];
        taxCalcObj[0]['net'] = 0;
        taxCalcObj[0]['total'] = 0;
        taxCalcObj[0]['tax'] = 0;

        //CHECK IF VOUCHERS VALUE IS GREATER THEN GOODS VALUE
        if (vouchersValue >= goodsValue) {
            let calcNet = parseFloat((goodsValue / (1 + (0 / 100))));
            let calcTax = parseFloat((goodsValue)) - calcNet;

            taxCalcObj[0]['net'] += calcNet * -1;
            taxCalcObj[0]['tax'] += calcTax * -1;
            taxCalcObj[0]['total'] += parseFloat((goodsValue)) * -1;

            totalprice = 0;
        } else {
            let calcNet = parseFloat((vouchersValue / (1 + (0 / 100))).toFixed(2));
            let calcTax = parseFloat((vouchersValue)) - calcNet;

            taxCalcObj[0]['net'] += calcNet * -1;
            taxCalcObj[0]['tax'] += calcTax * -1;
            taxCalcObj[0]['total'] += parseFloat((vouchersValue)) * -1;
        }
    }


    // --- DATE ---
    let today = new Date(data['ts'] * 1000);

    if (data['ts'] < 1000) {
        today = new Date();
    }

    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let hours, minutes;

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }

    hours = today.getHours();
    minutes = today.getMinutes();

    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    //CALC MONEY BACK
    let moneyBack = data.moneyGiven - totalprice;
    if (moneyBack < 0)
        moneyBack = 0;

    //VOUCHER CASE:
    if (totalprice <= 0) {
        moneyBack = 0;
        if (data.moneyGiven > 0.00) {
            moneyBack = data.moneyGiven;
        }
    }

    //PAYMENT TYPE
    let paymentType = data.paymentType.toString();
    let paymentTypeID = parseInt(paymentType);
    let paymentNote = getPaymentTypeNote(paymentType) ? getPaymentTypeNote(paymentType) : '';
    paymentType = convertPaymentType(paymentType);

    //PRINTER TEMPLATE
    let printWidth = printerOpts.paperType;
    let printerTmpl = lodash.cloneDeep(store.getters["printer/templatesRetail"]);
    let printerTmplSettings = lodash.cloneDeep(store.getters["printer/templatesRetailSetting"]);
    let usePercentForDiscount = true;

    if (printerTmplSettings.hasOwnProperty('discountDisplay')) {
        if (printerTmplSettings.discountDisplay) {
            if (printerTmplSettings.discountDisplay === 'percent') {
                usePercentForDiscount = true;
            } else if (printerTmplSettings.discountDisplay === 'number') {
                usePercentForDiscount = false;
            }
        }
    }

    let displayPriceChange = 0;
    if (typeof printerTmplSettings.displayPriceChangesOnBon === 'undefined') {
        displayPriceChange = 0;
    } else if (parseInt(printerTmplSettings.displayPriceChangesOnBon) > 0) {
        displayPriceChange = 1;
    }
    Array.from(printerTmpl).forEach(function (templateElement) {
        let templateElementCopy = Object.assign({}, templateElement);

        if (templateElement["barcode128"]) {
            //CHECK FOR INTERIMS PRINT
            if (interimsPrint || deliveryNotePrint) {
                if (templateElement.barcode128.includes("@@INVOICEID@@")) {
                    return;
                }
            }

            templateElement["barcode128"] = templateElement["barcode128"].replace("@@INVOICEID@@", data.saleID);

            printObj.push(templateElement);


            // ADD PAGER NUMBER
            // PRINT ALSO PAGER NUMBER
            if (store.state.pos.gastro.hasOwnProperty("pagerNo"))
                if (store.state.pos.gastro.pagerNo !== null) {
                    printObj.push({feed: 1});
                    printObj.push({reverse: "on"});
                    printObj.push({align: "center"});
                    printObj.push({fontSize: 2});
                    printObj.push({
                        text: geol.t('generic.lang_Pagernummer') + ': ' + store.state.pos.gastro.pagerNo
                    });
                    printObj.push({fontSize: 1});
                    printObj.push({reverse: "off"});
                }

        } else if (templateElement["text"]) {
            //CHECK FOR INTERIMS PRINT
            if (interimsPrint || deliveryNotePrint) {
                if (templateElement.text.includes("@@INVOICEID@@") || templateElement.text.includes("@@MONEYBACK@@") || templateElement.text.includes("@@MONEYGIVEN@@")) {
                    return;
                }
            }

            templateElement["text"] = templateElement["text"].replace("@@INVOICEDATE@@", dd + "." + mm + "." + yyyy);
            templateElement["text"] = templateElement["text"].replace("@@INVOICETIME@@", hours + ":" + minutes);
            templateElement["text"] = templateElement["text"].replace("@@INVOICEID@@", data.saleID);
            templateElement["text"] = templateElement["text"].replace("@@PARTYNAME@@", store.state.pos.gastro.party?.name);

            if (templateElement.text.includes("@@CASHIERID@@")) {
                // AUSSER HAUS
                if (store.state.pos.gastro.hasOwnProperty("takeAway")) {
                    if (store.state.pos.gastro.takeAway === 1) {
                        printObj.push({
                            text: geol.t('generic.lang_offSiteSale')
                        });
                    }
                }
            }

            templateElement["text"] = templateElement["text"].replace("@@CASHIERID@@", data.cashierID);

            // CHECK TABLE AND SKIPPING IF ITS DELIVERY SERVICE
            if (templateElement.text.includes("@@TABLENO@@")) {
                if ((data.tableNo.length === 0 || data.tableNo.toString() === "0" || (data.hasOwnProperty('isDelivery')))) {
                    return; // SKIP THIS TEXT ELEMENT
                }
            }

            templateElement["text"] = templateElement["text"].replace("@@TABLENO@@", data.tableNo);

            let sellerPrintText = parseInt(data.userID);

            if (printerOpts.hasOwnProperty("showWaiterName")) {
                if (parseInt(printerOpts.showWaiterName) === 1) {
                    sellerPrintText = activeUser.name + " " + activeUser.last_name + " (" + activeUser.userID + ")";
                }
            }

            templateElement["text"] = templateElement["text"].replace("@@SALESCLERKID@@", sellerPrintText);

            templateElement["text"] = templateElement["text"].replace("@@SALESTYPE@@", '');
            templateElement["text"] = templateElement["text"].replace("@@TOTAL@@", formatCurrency(totalprice));
            templateElement["text"] = templateElement["text"].replace("@@MONEYBACK@@", formatCurrency(moneyBack));

            // CHECK IF SPLIT PAYMENT
            if (paymentTypeID !== 7) {
                if (paymentTypeID === 1) {
                    templateElement["text"] = templateElement["text"].replace("@@MONEYGIVEN@@", formatCurrency(data.moneyGiven));
                } else {
                    //templateElement["text"] = templateElement["text"].replace("@@MONEYGIVEN@@", totalprice.toFixed(2).toString().replace(".", ","));
                    templateElement["text"] = templateElement["text"].replace("@@MONEYGIVEN@@", formatCurrency(data.moneyGiven));
                }
                templateElement["text"] = templateElement["text"].replace("@@PAYMENTTYPE@@", paymentType);
            } else {
                if (templateElement.hasOwnProperty("text")) {
                    if (templateElement.text.includes("@@PAYMENTTYPE@@")) {
                        console.log(data);
                        if (data.hasOwnProperty("splitPayments")) {
                            let splitPrintText = geol.t('generic.lang_splitpay') + ":";

                            data.splitPayments.forEach((splitPayment) => {
                                if (splitPayment.amount === 0)
                                    return;

                                splitPrintText += "\n" + splitPayment.label + ": " + formatCurrency(splitPayment.amount)
                            });

                            templateElement["text"] = splitPrintText;
                        }
                    }
                }
            }

            printObj.push(templateElement);

            if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
                if (data.tableNo.toString() === "0" && pickUpNo.toString().length > 0 && store.state.settings.settings.pickup_enable > 0 && !data.hasOwnProperty('isDelivery')) {
                    printObj.push({reverse: "on"});
                    printObj.push({align: "center"});
                    printObj.push({fontSize: 2});

                    if (store.state.pos.gastro.pagerNo === null) {
                        printObj.push({
                            text: geol.t('generic.lang_pickUpNumber') + ': ' + pickUpNo
                        });
                    }

                    printObj.push({reverse: "off"});
                    printObj.push({fontSize: 1});
                    printObj.push({align: "center"});
                }

                if (!data.hasOwnProperty('isDelivery')) {
                    printObj.push({align: 'center'});
                    printObj.push({text: customerData});
                    printObj.push({align: 'left'});
                }
            }

            //CHECK INTERIMS BILL
            if (interimsPrint) {
                if (templateElementCopy.text.includes('@@SALESCLERKID@@')) {
                    //INSERT INTERIMS BILL TEXT
                    printObj.push({feed: 1});
                    printObj.push({align: 'center'});
                    printObj.push({fontSize: 2});
                    printObj.push({reverse: 'on'});
                    printObj.push({text: geol.t('accounting.lang_Zwischenrechnung')});
                    printObj.push({text: geol.t('accounting.langINValid')});
                    printObj.push({reverse: 'off'});
                    printObj.push({feed: 1});
                    if (!data.hasOwnProperty('isDelivery')) {
                        printObj.push({align: 'center'});
                        printObj.push({fontSize: 1});
                        printObj.push({text: customerData});
                        printObj.push({align: 'left'});
                    }
                }
            }

            if (deliveryNotePrint) {
                if (templateElementCopy.text.includes('@@SALESCLERKID@@')) {
                    //INSERT INTERIMS BILL TEXT
                    printObj.push({feed: 1});
                    printObj.push({align: 'center'});
                    printObj.push({fontSize: 2});
                    printObj.push({reverse: 'on'});
                    printObj.push({text: geol.t('generic.lang_deliveryNote')});
                    printObj.push({reverse: 'off'});
                    printObj.push({fontSize: 1});
                    printObj.push({feed: 1});
                    printObj.push({text: customerData});
                    printObj.push({align: 'left'});
                }
            }

            //INSERTING DELIVERY SERVICE DATA
            if (data.hasOwnProperty('isDelivery')) {
                if (templateElementCopy.text.includes('@@SALESCLERKID@@')) {
                    // START PRINT PICK PICKUP NUMBER
                    printObj.push({reverse: "on"});
                    printObj.push({align: "center"});
                    printObj.push({fontSize: 2});
                    printObj.push({
                        text: geol.t('erp.lang_orderNo') + ': ' + pickUpNo,
                    });
                    // START PRINTING THE VOR BASED ON THE isAsap ATTRIBUTE
                    if (!data.isAsap) {
                        printObj.push({feed: 1});
                        printObj.push({
                            text: geol.t('datatables.lang_dtNext').toUpperCase(),
                        });
                    }
                    // END PRINTING
                    printObj.push({reverse: "off"});
                    printObj.push({fontSize: 1});
                    printObj.push({feed: 1});
                    // END PRINT PICK PICKUP NUMBER

                    // START
                    printObj.push({align: 'left'});
                    printObj.push({fontSize: 2});
                    printObj.push({text: data.isDelivery ? geol.t('delivery.lang_delivery') + ' ' + (data.serviceTime ? data.serviceTime : '') : geol.t('delivery.lang_pickup') + ' ' + (data.serviceTime ? data.serviceTime : '')});
                    // END PRINT SERVICE TYPE DELIVERY/PICKUP

                    // START PRINT CUSTOMER DATA
                    if (data.isDelivery) {
                        printObj.push({reverse: "on"});
                        printObj.push({text: customerPhone});
                        printObj.push({reverse: "off"});
                        printObj.push({text: customerData});
                        if (customerFloor && customerFloor.toString().length > 0) {
                            printObj.push({text: geol.t('generic.lang_floorNo') + ' : ' + customerFloor});
                        }
                        //printing the qr code
                        if (customerAddress.toString().length > 0) {
                            printObj.push({align: 'right'});
                            printObj.push({qrCode: 'https://www.google.com/maps?daddr=' + customerAddress});
                        }
                        // adding the payment method only for delivery
                        printObj.push({align: "center"});
                        printObj.push({reverse: "on"});
                        printObj.push({text: paymentType});
                        printObj.push({reverse: "off"});
                        printObj.push({align: "left"});

                    } else {
                        printObj.push({text: customerData});
                    }
                    // END PRINT CUSTOMER DATA

                    printObj.push({feed: 1});
                    printObj.push({align: "center"});
                    printObj.push({fontSize: 2});
                    printObj.push({text: geol.t('generic.lang_numberOfArticles')});
                    printObj.push({reverse: 'on'});
                    printObj.push({text: itemsNumber.toString()});
                    printObj.push({reverse: 'off'});
                    printObj.push({fontSize: 1});

                }
            }


        } else if (templateElement["shoppingCart"]) {
            let voucherLeftToPay = goodsValue;

            Array.from(items).forEach((item) => {
                //CHECK IF ITEM IS VOIDED
                if (item.isVoid)
                    return;

                let singlePrice = formatCurrency(item.sellPrice);
                let totalsinglePrice = formatCurrency(parseFloat(item.sellPrice) * parseFloat(item.amount));
                let oldPrice = formatCurrency(item.originalSellPrice);
                //CHECK IF VOUCHER VALUE IS GREATER THEN TOTAL VALUE
                if ((item.ean.includes("GF$") || item.ean.includes("V$") || item.ean.includes("ZMYLE$")) && item.sellPrice < 0) {
                    let voucherValue = item.sellPrice * -1;

                    if (voucherLeftToPay > 0) {
                        if (voucherValue >= voucherLeftToPay) {
                            voucherValue = voucherLeftToPay;
                            voucherLeftToPay = 0;
                        } else {
                            voucherLeftToPay -= voucherValue;
                        }
                    }

                    voucherValue = voucherValue * -1;

                    singlePrice = formatCurrency(voucherValue);
                    totalsinglePrice = formatCurrency(voucherValue);
                }

                if (printWidth === 50) {
                    printObj.push({"align": "left"});
                    printObj.push({"text": "    " + item.name.substr(0, 45)});
                    printObj.push({"align": "left"});
                    printObj.push({"text": "    " + ((item.isWeighted?"{{"+item.scalePositionId+".weight}}":item.amount.toString()) + "X ") + ' ' + (item.isWeighted?"{{"+item.scalePositionId+".price}}":singlePrice) + ' = ' + (item.isWeighted?"{{"+item.scalePositionId+".weightedPrice}}":totalsinglePrice)});
                    // SERIAL NUMBER PRINTING
                    if (item.hasOwnProperty('serialNo') && item.serialNo) {
                        printObj.push({"text": "     " + item.serialNo});
                    }
                    printObj.push({"feed": 1});
                } else {
                    printObj.push({"text": (item.isWeighted?"{{"+item.scalePositionId+".weight}}":(item.amount.toString() + "X ").substr(0, 5).padEnd(5, ' ')) + item.name.substr(0, 21).padEnd(21, ' ') + " " + (item.isWeighted?"{{"+item.scalePositionId+".price}}":singlePrice.substr(0, 9).padEnd(9, ' ')) + " " + (item.isWeighted?"{{"+item.scalePositionId+".weightedPrice}}":totalsinglePrice.substr(0, 8).padEnd(8, ' '))});

                    if (item.name.length > 21) {
                        printObj.push({"text": "     " + item.name.substr(21, 80)});
                    }
                    // SERIAL NUMBER PRINTING
                    if (item.hasOwnProperty('serialNo') && item.serialNo) {
                        printObj.push({"text": "     " + item.serialNo});
                    }

                    if (displayPriceChange > 0 && item.sellPrice.toFixed(2) != item.originalSellPrice.toFixed(2) && item.discount == 0) {
                        printObj.push({"text": "    " + geol.t('generic.lang_oldPrice') + " " + oldPrice});
                    } else if (displayPriceChange > 0 && item.discount > 0 && usePercentForDiscount) {
                        printObj.push({"text": "     " + item.discount.toFixed(2) + geol.t('generic.lang_discountRedeemed')});
                    } else if (displayPriceChange > 0 && item.discount > 0 && !usePercentForDiscount) {
                        let priceDiff = item.originalSellPrice - item.sellPrice;
                        printObj.push({"text": "     " + priceDiff.toFixed(2) + geol.t('generic.lang_discountRedeemedEuro')});
                    }


                    //GARNISH PRINTING
                    Array.from(item.selectedExtras).forEach((itemExtra) => {
                        // BUG FIXING (IF NAME IS EMPTY)
                        if (itemExtra.name === undefined || itemExtra.name === null)
                            return;

                        if (itemExtra.name.trim().length === 0)
                            return;

                        printObj.push({"text": "  +  " + (itemExtra.amount.toString() + "X ").substr(0, 5).padEnd(5, ' ') + itemExtra.name.substr(0, 21).padEnd(21, ' ')});
                    });

                    //DEPOSIT PRINTING
                    if (item.hasOwnProperty("depositgroupID")) {
                        if (item.depositgroupID > 0) {
                            let deposit = store.getters['deposit/depositgroups'].find((depositGroup) => {
                                return depositGroup.id === item.depositgroupID;
                            });

                            if (deposit) {
                                printObj.push({"text": '     ' + deposit.name.substr(0, 21).padEnd(21, ' ') + " " + formatCurrency(deposit.priceTotal).toString().padEnd(9, ' ') + " " + formatCurrency(deposit.priceTotal * item.amount).toString().padEnd(8, ' ')});
                            }
                        }
                    }
                }
            });
        } else if (templateElement["taxElement"] && !deliveryNotePrint) {
            if (printWidth === 50) {
                printObj.push({"align": "center"});
                printObj.push({"text": geol.t('generic.lang_taxOverview')});
                printObj.push({"left": "left"});

                Object.keys(taxCalcObj).forEach(function (element) {
                    printObj.push({"text": geol.t('accounting.lang_turnover') + " " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%   " + formatCurrency(taxCalcObj[element]['total'])});
                    printObj.push({"text": geol.t('generic.lang_subtotal') + " " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%   " + formatCurrency(taxCalcObj[element]['net'])});
                    printObj.push({"text": geol.t('accounting.lang_taxes') + " " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%   " + formatCurrency(taxCalcObj[element]['tax'])});
                });

                printObj.push({"feed": 1});
            } else {
                printObj.push({"align": "center"});
                printObj.push({"text": geol.t('generic.lang_taxOverview')});
                printObj.push({"left": "left"});
                printObj.push({"text": "ID       " + geol.t('accounting.lang_taxes') + "%       Total       Net       " + geol.t('accounting.lang_taxes')});
                var taxID = 0;
                Object.keys(taxCalcObj).forEach(function (element) {
                    taxID += 1;
                    printObj.push({"text": taxID + "       " + parseFloat(element).toFixed(2).toString().replace(".", ",") + "%       " + formatCurrency(taxCalcObj[element]['total'])+ "   " + formatCurrency(taxCalcObj[element]['net'])+"   " + formatCurrency(taxCalcObj[element]['tax'])})
                });

                printObj.push({"feed": 1});
            }
        } else {
            printObj.push(templateElement);
        }
    });

    if (interimsPrint) {
        if (printerOpts.hasOwnProperty("showRoomNumber")) {
            if (parseInt(printerOpts.showRoomNumber) === 1) {
                printObj.push({
                    fontSize: 2
                });
                printObj.push({
                    feed: 3
                });
                printObj.push({
                    text: geol.t('generic.lang_roomNo') + ": "
                });
                printObj.push({
                    feed: 4
                });
                printObj.push({
                    align: "center"
                });
                printObj.push({
                    text: "_____________________________________"
                });
                printObj.push({
                    text: geol.t('generic.lang_signature')
                });
                printObj.push({
                    align: "left"
                });
                printObj.push({
                    feed: 4
                });
            }
        }
    }

    if (deliveryNotePrint) {
        printObj.push({
            fontSize: 1
        });

        printObj.push({
            feed: 2
        });
        printObj.push({
            align: "center"
        });
        printObj.push({
            text: "_____________________________________"
        });
        printObj.push({
            text: geol.t('generic.lang_signature')
        });
        printObj.push({
            align: "left"
        });
        printObj.push({
            feed: 4
        });
    }

    if (paymentNote) {
        printObj.push({
            align: "center"
        });
        printObj.push({
            fontSize: 1
        });
        printObj.push({
            text: paymentNote
        });
        printObj.push({
            align: "left"
        });
        printObj.push({
            feed: 1
        });
    }

    return printObj;
}

export function createPaperlesInvoicePrintingData(items, data, printerOpts, activeUser, deliveryNotePrint = false) {
    let printObj = [];
    let totalprice = 0.00;

    // --- DATE ---
    let today = new Date(data['ts'] * 1000);

    if (data['ts'] < 1000) {
        today = new Date();
    }

    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let hours, minutes;

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }

    hours = today.getHours();
    minutes = today.getMinutes();

    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    let pickUpNo = "";
    if (data.hasOwnProperty('pickUpNo') && data.pickUpNo) {
        pickUpNo = data.pickUpNo;
    } else if (typeof store.state.pos.gastro.pickup === 'undefined') {
        pickUpNo = "";
    } else {
        pickUpNo = store.state.pos.gastro.pickup;
    }
    Array.from(items).forEach((item) => {
        if (item.isVoid)
            return;

        let itemSellPrice = item.sellPrice;
        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
            if (item.depositgroupID > 0) {
                let deposit = store.getters['deposit/depositgroups'].find((depositGroup) => {
                    return depositGroup.id === item.depositgroupID;
                });

                if (deposit)
                    itemSellPrice += deposit.priceTotal;
            }
        }

        //CALC TOTAL PRICE
        totalprice += parseFloat((itemSellPrice * Math.abs(item.amount)));

        //CHECK IF VOUCHER
        if ((item.ean.includes("GF$") || item.ean.includes("V$") || item.ean.includes("ZMYLE$")) && item.sellPrice < 0)
            return;
    });
    //CALC MONEY BACK
    let moneyBack = data.moneyGiven - totalprice;
    if (moneyBack < 0)
        moneyBack = 0;

    //VOUCHER CASE:
    if (totalprice <= 0) {
        moneyBack = 0;
        if (data.moneyGiven > 0.00) {
            moneyBack = data.moneyGiven;
        }
    }
    //PAYMENT TYPE
    let paymentType = data.paymentType.toString();
    let paymentTypeID = parseInt(paymentType);
    paymentType = convertPaymentType(paymentType);

    let printerTmpl = lodash.cloneDeep(store.getters["printer/templatesRetail"]);

    Array.from(printerTmpl).forEach(function (templateElement) {
            let templateElementCopy = Object.assign({}, templateElement);
            if (templateElement["barcode128"]) {

                templateElement["barcode128"] = templateElement["barcode128"].replace("@@INVOICEID@@", data.saleID);

                printObj.push(templateElement);


                // ADD PAGER NUMBER
                // PRINT ALSO PAGER NUMBER
                if (store.state.pos.gastro.hasOwnProperty("pagerNo"))
                    if (store.state.pos.gastro.pagerNo !== null) {
                        printObj.push({feed: 1});
                        printObj.push({reverse: "on"});
                        printObj.push({align: "center"});
                        printObj.push({fontSize: 2});
                        printObj.push({
                            text: geol.t('generic.lang_Pagernummer') + ': ' + store.state.pos.gastro.pagerNo
                        });
                        printObj.push({fontSize: 1});
                        printObj.push({reverse: "off"});
                    }

            } else if (templateElement["text"]) {
                if(templateElementCopy.text.includes("@@TOTAL@@")
                    ||templateElementCopy.text.includes("@@MONEYBACK@@")
                    ||templateElementCopy.text.includes("@@MONEYGIVEN@@")
                    ||templateElementCopy.text.includes("@@PAYMENTTYPE@@")
                    ||templateElementCopy.text.includes("@@SALESTYPE@@")
                    ||templateElementCopy.text.includes("__________________________________________")
                    ||templateElementCopy.text.includes("------------------------------------------")
                    ||templateElementCopy.text.includes("     Artikel             Einz.-Pr.  Ges.-Pr."))
                    return;
                templateElement["text"] = templateElement["text"].replace("@@INVOICEDATE@@", dd + "." + mm + "." + yyyy);
                templateElement["text"] = templateElement["text"].replace("@@INVOICETIME@@", hours + ":" + minutes);
                templateElement["text"] = templateElement["text"].replace("@@INVOICEID@@", data.saleID);
                templateElement["text"] = templateElement["text"].replace("@@PARTYNAME@@", store.state.pos.gastro.party?.name);

                if (templateElement.text.includes("@@CASHIERID@@")) {
                    // AUSSER HAUS
                    if (store.state.pos.gastro.hasOwnProperty("takeAway")) {
                        if (store.state.pos.gastro.takeAway === 1) {
                            printObj.push({
                                text: geol.t('generic.lang_offSiteSale')
                            });
                        }
                    }
                }

                templateElement["text"] = templateElement["text"].replace("@@CASHIERID@@", data.cashierID);

                // CHECK TABLE AND SKIPPING IF ITS DELIVERY SERVICE
                if (templateElement.text.includes("@@TABLENO@@")) {
                    if ((data.tableNo.length === 0 || data.tableNo.toString() === "0" || (data.hasOwnProperty('isDelivery')))) {
                        return; // SKIP THIS TEXT ELEMENT
                    }
                }

                templateElement["text"] = templateElement["text"].replace("@@TABLENO@@", data.tableNo);

                let sellerPrintText = parseInt(data.userID);

                if (printerOpts.hasOwnProperty("showWaiterName")) {
                    if (parseInt(printerOpts.showWaiterName) === 1) {
                        sellerPrintText = activeUser.name + " " + activeUser.last_name + " (" + activeUser.userID + ")";
                    }
                }

                templateElement["text"] = templateElement["text"].replace("@@SALESCLERKID@@", sellerPrintText);

                printObj.push(templateElement);

                if (templateElementCopy.text.includes("@@SALESCLERKID@@")) {
                    if (data.tableNo.toString() === "0" && pickUpNo.toString().length > 0 && store.state.settings.settings.pickup_enable > 0) {
                        printObj.push({reverse: "on"});
                        printObj.push({align: "center"});
                        printObj.push({fontSize: 2});

                        if (store.state.pos.gastro.pagerNo === null) {
                            printObj.push({
                                text: geol.t('generic.lang_pickUpNumber') + ': ' + pickUpNo
                            });
                        }

                        printObj.push({reverse: "off"});
                        printObj.push({fontSize: 1});
                        printObj.push({align: "center"});
                    }
                }
            } else if (templateElement["feed"]===3) {
                return;
            }else if (templateElement["shoppingCart"]) {
                return;
            }else if(templateElement["taxElement"]){
                return;
            }else {
                printObj.push(templateElement);
            }
        }
    );
    printObj.push({align: 'center'});
    printObj.push({qrCode: 'https://' + store.state.settings.settings.servicesSubDomainName + '.paperlessinvoice.3pos.de/?i=' + data.invoiceUUID});
    printObj.push({align: 'left'});
    printObj.push(...[
        {
            "font": "A"
        },
        {
            "fontSize": 2
        },
        {
            "align": "left"
        },
        {
            "text": geol.t('generic.lang_total') + ": " + formatCurrency(totalprice)
        },
        {
            "fontSize": 1
        },
        {
            "text": geol.t('generic.lang_receipt_gegeben') + "  " + paymentType + " : " + formatCurrency(data.moneyGiven)
        },
        {
            "fontSize": 1
        },
        {
            "text": geol.t('generic.lang_back') + ": " + formatCurrency(moneyBack)
        },
    ])
    printObj.push({cashDrawer: true});
    printObj.push({cut: true});
    return printObj;
}