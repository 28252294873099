import axios from 'axios';
import store from '@/store';

import { db } from '@/store/indexeddb/dexie';
//import dexie from "@/store/indexeddb/dexie";

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    async 'init'(state, data) {
      /*
            data.forEach(async (item) => {
                // state.items.push(item);
            });
            */

      // INSERT INTO DEXIE DB
      //await db.items.clear();
      await db.items.bulkAdd(data);

      // CHECK LENGTH
      store.commit('app/setUseAPIPos', state.items.length >= 4000, {
        root: true,
      });
    },
    async 'resetState'(state, data) {
      state.items = [];
      await db.items.clear();
    },
    'backgroundSync'(state, payload) {
      const transaction = payload.transaction;
      const transactionType = payload.type;

      if (transactionType === 'INSERT') {
        //INSERT ITEM
        let insertObject = {
          id: transaction.id,
          ean: transaction.ean,
          internalWareID: transaction.internalWareID,
          itemOrderingNameTranslation: transaction.itemOrderingNameTranslation,
          itemNameTranslation: transaction.itemNameTranslation,
          itemOrderingNameTranslationCanvas:
            transaction.itemOrderingNameTranslationCanvas,
          itemNameTranslationCanvas: transaction.itemNameTranslationCanvas,
          itemgroupID: transaction.itemgroupID,
          name: transaction.name,
          description: transaction.description,
          dontAskForExtras: parseInt(transaction.dontAskForExtras),
          sellPrice: transaction.sellPrice,
          openPriceInput: transaction.openPriceInput,
          openWeightInput: transaction.openWeightInput,
          depositgroupID: transaction.depositgroupID,
          unitID: transaction.unitID,
          unitofPurchaseID: transaction.unitofPurchaseID,
          mealSizes: transaction.mealSizes,
          taxValue: transaction.taxValue,
          takeAwayTaxing: transaction.takeAwayTaxing,
          producer: transaction.producer,
          barcodeWeightPriceMode: transaction.barcodeWeightPriceMode,
          supplier: transaction.supplier,
          payOutItem: transaction.payOutItem,
          backgroundColor: transaction.backgroundColor,
          active: transaction.active,
          hasMHD: transaction.hasMHD,
          hasChargenNo: transaction.hasChargenNo,
          hasSerialNo: transaction.hasSerialNo,
          bookingNumberReq: transaction.bookingNumberReq,
          bookingNoRegExp: transaction.bookingNoRegExp,
          pagerNumberReq: transaction.pagerNumberReq,
          allowSetName: transaction.allowSetName,
          size: transaction.size,
          color: transaction.color,
          variationSize: transaction.variationSize,
          variationColor: transaction.variationColor,
          variationColorHex: transaction.variationHexColor,
          variations: [], //TODO
          isVariation: transaction.isVariation,
          zusatzstoffe: transaction.zusatzstoffe,
          allergene: transaction.allergene,
          ageVerification: transaction.ageVerification,
          takeAwayPrice: transaction.takeAwayPrice,
          deliveryPrice: transaction.deliveryPrice,
          tara: transaction.tara,
          enableWeighting: transaction.enableWeighting,
          enableTara: transaction.enableTara,
          extras: [],
          hasExtras: false,
          orderbonPrinterID: parseInt(transaction.orderbonPrinterID),
          isMenuItem: parseInt(transaction.isMenuItem),
          menuTaxRules: transaction.menuTaxRules,
          isDailyGoods: transaction.isDailyGoods,
        };

        // Vue.set(state.items, state.items.length, insertObject);

        // INDEXEDDB INSERT
        db.items.put(insertObject);
      }

      if (transactionType === 'UPDATE') {
        /*
                //FIND ITEMS
                let item = state.items.find((item) => {
                    if (item.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE ITEM
                if (item) {}
                */

        let item = {};
        item.id = transaction.id;
        item.ean = transaction.ean;
        item.internalWareID = transaction.internalWareID;
        item.itemOrderingNameTranslation =
          transaction.itemOrderingNameTranslation;
        item.itemNameTranslation = transaction.itemNameTranslation;
        item.itemOrderingNameTranslationCanvas =
          transaction.itemOrderingNameTranslationCanvas;
        item.itemNameTranslationCanvas = transaction.itemNameTranslationCanvas;
        item.itemgroupID = transaction.itemgroupID;
        item.name = transaction.name;
        item.description = transaction.description;
        item.dontAskForExtras = parseInt(transaction.dontAskForExtras);
        item.sellPrice = transaction.sellPrice;
        item.openPriceInput = transaction.openPriceInput;
        item.openWeightInput = transaction.openWeightInput;
        item.depositgroupID = transaction.depositgroupID;
        item.mealSizes = transaction.mealSizes;
        item.taxValue = transaction.taxValue;
        item.takeAwayTaxing = transaction.takeAwayTaxing;
        item.producer = transaction.producer;
        item.barcodeWeightPriceMode = transaction.barcodeWeightPriceMode;
        item.supplier = transaction.supplier;
        item.payOutItem = transaction.payOutItem;
        item.unitID = transaction.unitID;
        item.unitofPurchaseID = transaction.unitofPurchaseID;
        item.backgroundColor = transaction.backgroundColor;
        item.active = transaction.active;
        item.hasMHD = transaction.hasMHD;
        item.hasChargenNo = transaction.hasChargenNo;
        item.hasSerialNo = transaction.hasSerialNo;
        item.bookingNumberReq = transaction.bookingNumberReq;
        item.bookingNoRegExp = transaction.bookingNoRegExp;
        item.pagerNumberReq = transaction.pagerNumberReq;
        item.allowSetName = transaction.allowSetName;
        item.size = transaction.size;
        item.color = transaction.color;
        item.extras = transaction.extras;
        item.hasExtras = transaction.hasExtras;
        item.variationSize = transaction.variationSize;
        item.variationColor = transaction.variationColor;
        item.variationColorHex = transaction.variationHexColor;
        item.isVariation = transaction.isVariation;
        item.zusatzstoffe = transaction.zusatzstoffe;
        item.allergene = transaction.allergene;
        item.variations = transaction.variations;
        item.ageVerification = transaction.ageVerification;
        item.takeAwayPrice = transaction.takeAwayPrice;
        item.deliveryPrice = transaction.deliveryPrice;
        item.orderbonPrinterID = parseInt(transaction.orderbonPrinterID);
        item.isMenuItem = parseInt(transaction.isMenuItem);
        item.menuTaxRules = transaction.menuTaxRules;
        item.isDailyGoods = transaction.isDailyGoods;
        item.tara = transaction.tara;
        item.enableWeighting = transaction.enableWeighting;
        item.enableTara = transaction.enableTara;

        // INDEXEDDB UPDATE
        // DELETE OLD
        db.items.delete(transaction.id);
        // ADD NEW
        db.items.put(item);
      }

      if (transactionType === 'DELETE') {
        /*
                //FIND ITEM
                let item = state.items.find((item) => {
                    if (item.id === transaction.id) {
                        return true;
                    }
                });

                if (item) {
                    let itemIndex = state.items.indexOf(item);

                    //DELETE ITEM
                    Vue.delete(state.items, itemIndex);
                }
                */

        // INDEXEDDB DELETE
        db.items.delete(transaction.id);
      }
    },
    'backgroundSync.itemState'(state, payload) {
      const transaction = payload.transaction;
      const transactionType = payload.type;

      if (transactionType === 'UPDATE') {
        //FIND ITEMS
        let item = state.items.find((item) => {
          if (item.id === transaction.id) {
            return true;
          }
        });

        //UPDATE ITEM
        if (item) {
          item.active = transaction.active;
        }

        // FIND DEXIEDB
        db.items.update(transaction.id, {
          active: transaction.active,
        });
      }
    },
  },

  actions: {
    async init({ commit, rootState }) {
      commit('resetState', null);
      return new Promise((resolve, reject) => {
        //GET ALL ITEMS WITHOUT WAREGROUP + ITEMS WITH DELETE WAREGROUP
        axios
          .post('get/indexeddb/wares/', {
            wgID: 0,
          })
          .then((res) => {
            commit('init', res.data);
          })
          .catch((err) => {
            reject(err.response);
          });

        // USE STILL rootState.itemgroups.itemgroups
        // BECAUSE ELSE WE HAVE TO WAIT FOR DEXIEDB READY STATE

        if (rootState.itemgroups.itemgroups.length > 0) {
          //GET ALL ITEMS WITH WAREGROUP
          rootState.itemgroups.itemgroups.forEach((itemgroup, key, array) => {
            axios
              .post('get/indexeddb/wares/', {
                wgID: itemgroup.id,
              })
              .then((res) => {
                commit('init', res.data);

                if (Object.is(array.length - 1, key)) {
                  //LAST ITEMGROUP
                  resolve();
                }
              })
              .catch((err) => {
                reject(err.response);
              });
          });
        } else {
          resolve();
        }

        /*
                // TODO
                // GET PAGE FUNCTION FOR WARES
                // THIS IS A RECURSION FUNCTION
                function getItemsByPage(page = 1) {
                    axios.post('get/indexeddb/waresAllPagination/', {
                        page: page
                    }).then((res) => {
                        // COMMIT ITEMS
                        commit("init", res.data.items);

                        // CHECK IF WE HAVE MORE ITEMS
                        if(res.data.page !== null) {
                            // STILL MORE ITEMS...
                            // -> CALL THIS FUNCTION AGAIN!
                            getItemsByPage(res.data.page);
                        }
                        else {
                            // NO MORE ITEMS...resolve
                            resolve();
                        }

                    }).catch((err) => {
                        // ERROR -> reject
                        reject(err.response);
                    })
                }

                // CALL ABOVE FUNCTION TO START WITH PAGE 1
                getItemsByPage(1);
                */
      });
    },
    resetState({ commit }, data) {
      commit('resetState', data);
    },
    getItems({ commit }) {
      return db.items.toArray();
    },
    getItemByID({ commit }, itemId) {
      return db.items.get({ id: itemId });
    },
    getItemByManyID({ commit }, itemIds) {
      return db.items.bulkGet(itemIds);
    },
    async getActiveItemByEAN({ commit }, ean) {
      return db.items
        .where({ ean: ean })
        .and((item) => item.active === 1)
        .first();
    },
    getActiveItemsSortByNameAsc({ commit }) {
      return db.items.where({ active: 1 }).sortBy('name');
    },
    getItemsByItemGroupIDSortByName({ commit }, itemgroupId) {
      return db.items.where({ itemgroupID: itemgroupId }).sortBy('name');
    },
    paginateActiveItemsByItemgroupSortByName({ commit }, payload) {
      try {
        if (parseInt(payload.limit) === -1) {
          let offset = 0;
          /*return dexieDB.items.where({itemgroupID: payload.itemgroupId}).and(item => item.active === 1).offset(offset).reverse().toArray((items) => {
                        return items.sort((a, b)=>{
                            if(a.name < b.name) { return -1; }
                            if(a.name > b.name) { return 1; }
                            return 0;
                        })
                    });*/
          return db.items
            .where({ itemgroupID: payload.itemgroupId })
            .and((item) => item.active === 1)
            .offset(offset)
            .toArray();
        } else {
          let offset = (payload.page - 1) * (payload.limit - 1);
          let limit = payload.limit - 1;
          return db.items
            .where({ itemgroupID: payload.itemgroupId })
            .and((item) => item.active === 1)
            .offset(offset)
            .limit(payload.limit - 1)
            .toArray();
          /*return dexieDB.items.where({itemgroupID: payload.itemgroupId}).and(item => item.active === 1).toArray((val) => {
                        var items = val.sort((a, b) => {
                            if(a.name < b.name) { return -1; }
                            if(a.name > b.name) { return 1; }
                            return 0;
                        })

                        var paginatedItems = [];
                        if (items.length > offset + limit) {
                            for (let i = offset; i < offset + limit; i++) {
                                paginatedItems.push(items[i]);
                            }
                        } else {
                            for (let i = offset; i < items.length; i++) {
                                paginatedItems.push(items[i]);
                            }
                        }

                        return paginatedItems;
                    });*/
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  getters: {
    Items: (state) => {
      return state.items;
    },
  },
};
