import axios from "axios";
import {db} from "@/store/indexeddb/dexie";

export default {
    namespaced: true,

    state: {
        itemgroups: [] // !!! STILL IN USE IN module/items.js -> init() !!!
    },

    mutations: {
        async init(state, data) {
            state.itemgroups = data;

            // FIRST CLEAR TABLE
            await db.itemgroups.clear();

            //ALSO INSERT INTO DEXIE DB
            await db.itemgroups.bulkAdd(data);
        },
        async resetState(state, data) {
            state.itemgroups = [];
            await db.itemgroups.clear();
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if (transactionType === "INSERT") {
                let insertObject = {
                    id: transaction.id,
                    name: transaction.name,
                    orderbonPrinterID: parseInt(transaction.orderbonPrinterID),
                    duplicate_orderbonPrinterID: parseInt(transaction.duplicate_orderbonPrinterID),
                    backgroundColor: transaction.backgroundColor,
                    ageVerification: transaction.ageVerification,
                    faIcon: transaction.faIcon,
                    lbWareCatActive: parseInt(transaction.lbWareCatActive),
                    dontAskForExtras: parseInt(transaction.dontAskForExtras),
                    self_service_active: parseInt(transaction.self_service_active),
                    deliveryDiscount: Number(transaction.deliveryDiscount),
                    takeAwayDiscount: Number(transaction.takeAwayDiscount),
                    predefinedCourseId: parseInt(transaction.predefinedCourseId),
                    itemgroupImage: transaction.itemgroupImage,
                    show: transaction.show
                };

                // INDEXEDDB INSERT
                db.itemgroups.put(insertObject);
            }

            if (transactionType === "UPDATE") {
                let itemgroup = {};
                itemgroup.id = transaction.id;
                itemgroup.name = transaction.name;
                itemgroup.extras = transaction.extras;
                itemgroup.orderbonPrinterID = parseInt(transaction.orderbonPrinterID);
                itemgroup.duplicate_orderbonPrinterID = parseInt(transaction.duplicate_orderbonPrinterID);
                itemgroup.backgroundColor = transaction.backgroundColor;
                itemgroup.ageVerification = transaction.ageVerification;
                itemgroup.faIcon = transaction.faIcon;
                itemgroup.dontAskForExtras = parseInt(transaction.dontAskForExtras);
                itemgroup.lbWareCatActive = parseInt(transaction.lbWareCatActive);
                itemgroup.self_service_active = parseInt(transaction.self_service_active);
                itemgroup.deliveryDiscount = Number(transaction.deliveryDiscount);
                itemgroup.takeAwayDiscount = Number(transaction.takeAwayDiscount);
                itemgroup.predefinedCourseId = parseInt(transaction.predefinedCourseId);
                itemgroup.itemgroupImage = transaction.itemgroupImage;
                itemgroup.show = transaction.show;

                // INDEXEDDB UPDATE
                // DELETE OLD
                db.itemgroups.delete(itemgroup.id);
                // ADD NEW
                db.itemgroups.put(itemgroup);
            }

            if (transactionType === "DELETE") {
                // INDEXEDDB DELETE
                db.itemgroups.delete(transaction.id);
            }
        }
    },

    actions: {
        init({commit}) {
            commit("resetState", null);
            return axios.post('get/indexeddb/waregroups/').then((res) => {
                let itemgroups = [];

                Array.from(res.data).forEach((itemgroup) => {
                    itemgroups.push(itemgroup);
                });

                commit("init", itemgroups);
            }).catch(() => {

            });
        },
        resetState({commit}, data) {
            commit('resetState', data);
        },
        getItemgroups({commit}) {
            return db.itemgroups.toArray();
        },
        getItemgroupByID({commit}, itemgroupId) {
            return db.itemgroups.get(itemgroupId);
        },
    }
};
