export let settingRoutes = {

    meta: {
        menuTitle: ('settings.lang_settings'),
        icon: 'pe-7s-tools',
    },
    childs: [],
    groups: [
        {
            meta: {
                menuTitle: ('settings.lang_nav_settings_cashierSettings'),
                icon: 'lnr-store',
                permission: {}
            },
            childs: [
                {
                    path: '/settings/cashierSettings/layoutSettings',
                    name: 'settings.cashierSettings.layoutSettings',
                    meta: {
                        siteTitle: ('settings.lang_nav_design'),
                        menuTitle: ('settings.lang_nav_design'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePosGridLayout",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/Layout')
                },
                {
                    path: '/settings/cashierSettings/layoutSettings/posGridTemplate/:id',
                    name: 'settings.cashierSettings.layoutSettings.posGridTemplate',
                    props: true,
                    meta: {
                        hideInNavigation: true,
                        siteTitle: ('settings.lang_nav_design'),
                        menuTitle: ('settings.lang_nav_design'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: "",
                        }
                    },
                    component: () => import('../views/settings/cashier/LayoutPOSGridTemplate')
                },


                {
                    path: '/settings/cashierSettings/roomFloor/abstract/:id',
                    name: 'settings.cashierSettings.roomFloor.abstract',
                    meta: {
                        siteTitle: "room floor",
                        menuTitle: "room floor",
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 37,
                            requiredPermission: "roomPlanCreateEdit",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/RoomFloor/RoomFloorEditor')
                },
                {
                    path: '/settings/cashierSettings/roomPlaner',
                    name: 'settings.cashierSettings.roomPlaner',
                    meta: {
                        siteTitle: ('settings.lang_nav_settings_roomplaner'),
                        menuTitle: ('settings.lang_nav_settings_roomplaner'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 37,
                            requiredPermission: "roomPlanCreateEdit",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/TablePlanner/TablePlaner')
                },
                {
                    path: '/settings/cashierSettings/roomPlaner/create',
                    name: 'settings.cashierSettings.roomPlaner.create',
                    meta: {
                        siteTitle: "create room",
                        menuTitle: ('settings.lang_nav_settings_roomplaner'),
                        hideInNavigation: true,
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 37,
                            requiredPermission: "roomPlanCreateEdit",
                            hybridDisplay: "",
                        }
                    },
                    component: () => import('../views/settings/cashier/TablePlanner/TablePlanerCreate')
                }, {
                    path: '/settings/cashierSettings/roomPlaner/edit/:id',
                    name: 'settings.cashierSettings.roomPlaner.edit',
                    meta: {
                        siteTitle: "edit room",
                        menuTitle: ('settings.lang_nav_settings_roomplaner'),
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 37,
                            requiredPermission: "roomPlanCreateEdit",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/TablePlanner/TablePlanerEdit')
                },


                {
                    path: '/settings/cashierSettings/cashiermodules',
                    name: 'settings.cashierSettings.cashiermodules',
                    meta: {
                        siteTitle: ('settings.lang_cashierModules'),
                        menuTitle: ('settings.lang_cashierModules'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/cashier/cashiermodules/cashiermodules')
                },
                {
                    path: '/settings/cashierSettings/cashiermoduleEdit/:id',
                    name: 'settings.cashierSettings.cashiermoduleEdit',
                    meta: {
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/cashiermodules/CashierModuleEdit')
                },

                {
                    path: '/settings/cashierSettings/customerDisplay',
                    name: 'settings.cashierSettings.customerDisplay',
                    meta: {
                        siteTitle: ('settings.lang_custDisplay'),
                        menuTitle: ('settings.lang_custDisplay'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 29,
                            requiredPermission: "manageCustomerDisplay",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/Customerdisplay.vue')
                },
                {
                    path: '/settings/cashierSettings/customerDisplayTemplate/:id',
                    name: 'settings.cashierSettings.customerDisplayTemplate',
                    props: true,
                    meta: {
                        siteTitle: ('settings.lang_custDisplayTemplate'),
                        menuTitle: ('settings.lang_custDisplayTemplate'),
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 29,
                            requiredPermission: "manageCustomerDisplay",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/CustomerdisplayTemplate.vue')
                },

                {
                    path: '/settings/cashierSettings/orderbonarchive',
                    name: 'settings.cashierSettings.orderbonarchive',
                    meta: {
                        siteTitle: ('settings.lang_orderbonarchive'),
                        menuTitle: ('settings.lang_orderbonarchive'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 2,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/OrderbonArchive.vue'),
                },
                {
                    path: '/settings/cashierSettings/displayorderbonarchive/:id',
                    name: 'settings.cashierSettings.displayorderbonarchive',
                    meta: {
                        hideInNavigation: true,
                        siteTitle: ('settings.lang_orderbonarchive'),
                        menuTitle: ('settings.lang_orderbonarchive'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 2,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/cashier/DisplayOrderbonArchive.vue'),
                },
            ]
        },
        {
            meta: {
                menuTitle: ('settings.lang_printerSettings'),
                icon: 'pe-7s-print',
                permission: {}
            },
            childs: [
                {
                    path: '/settings/printerSettings/printerManager',
                    name: 'settings.printerSettings.printerManager',
                    meta: {
                        siteTitle: ('settings.lang_nav_settings_printermanager'),
                        menuTitle: ('settings.lang_nav_settings_printermanager'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/printerSettings/PrintManager')
                },
                {
                    path: '/settings/printerSettings/printerManager/createPrinter',
                    name: 'settings.printerSettings.printerManager.createPrinter',
                    meta: {
                        hideInNavigation: true,
                        siteTitle: ('settings.lang_nav_settings_printermanager'),
                        menuTitle: ('settings.lang_nav_settings_printermanager'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/printerSettings/PrintManagerCreatePrinter.vue')
                },
                {
                    props: true,
                    path: '/settings/printerSettings/printerManager/editPrinter/:id',
                    name: 'settings.printerSettings.printerManager.editPrinter',
                    meta: {
                        hideInNavigation: true,
                        siteTitle: ('settings.lang_nav_settings_printermanager'),
                        menuTitle: ('settings.lang_nav_settings_printermanager'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/printerSettings/PrintManagerEditPrinter.vue')
                },
                {
                    path: '/settings/printerSettings/receiptTmpl',
                    name: 'settings.printerSettings.receiptTmpl',
                    meta: {
                        siteTitle: ('settings.lang_receiptTmpl'),
                        menuTitle: ('settings.lang_receiptTmpl'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/printerSettings/templates/index')
                },
                {
                    path: '/settings/printerSettings/receiptTmpl/details/:id',
                    name: 'settings.printerSettings.receiptTmpl.details',
                    meta: {
                        hideInNavigation: true,
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/printerSettings/templates/TemplateEdit')
                },
               /* {
                    path: '/settings/printerSettings/invoiceTmpl',
                    name: 'settings.printerSettings.invoiceTmpl',
                    meta: {
                        siteTitle: ('settings.lang_invoiceTmpl'),
                        menuTitle: ('settings.lang_invoiceTmpl'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePrinters",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/common/404.vue')
                }*/
            ]
        },
        {
            meta: {
                menuTitle: ('settings.lang_basicSettings'),
                icon: 'pe-7s-tools',
                permission: {}
            },
            childs: [
                {
                    path: '/settings/basicSettings/displayUsers',
                    name: 'settings.basicSettings.displayUsers',
                    meta: {
                        siteTitle: ('settings.lang_users'),
                        menuTitle: ('settings.lang_users'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageUsers",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/basic/users/SearchUsers')
                },
                {
                    path: '/settings/basicSettings/createUsers/:id?',

                    name: 'settings.basicSettings.createUsers',
                    meta: {
                        siteTitle: ('settings.lang_user_edit'),
                        menuTitle: ('settings.lang_user_edit'),
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageUsers",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/basic/users/CreateUser')
                },
                {
                    path: '/settings/basicSettings/editUsers/:id',
                    name: 'settings.basicSettings.editUsers',
                    props: true,
                    meta: {
                        siteTitle: ('settings.lang_user_edit'),
                        menuTitle: ('settings.lang_user_edit'),
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageUsers",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/basic/users/EditUser')
                },
                //CHANGE PASSWORD
                {
                    path: '/settings/basicSettings/changePassword',
                    name: 'settings.basicSettings.changePassword',
                    meta: {
                        siteTitle: ('settings.lang_nav_settings_password'),
                        menuTitle: ('settings.lang_nav_settings_password'),
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/ChangePassword')
                },

                {
                    path: '/settings/basicSettings/permissions',
                    name: 'settings.basicSettings.permissions',
                    meta: {
                        siteTitle: ('settings.lang_nav_usergroups'),
                        menuTitle: ('settings.lang_nav_usergroups'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePermGroups",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/basic/usergroup/UserGroup')
                },
                {
                    path: '/settings/basicSettings/permissions/create',
                    name: 'settings.basicSettings.permissions.create',
                    meta: {
                        hideInNavigation: true,
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePermGroups",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/basic/usergroup/UserGroupCreate')
                },
                {
                    path: '/settings/basicSettings/permissions/edit/:id',
                    name: 'settings.basicSettings.permissions.edit',
                    meta: {
                        hideInNavigation: true,
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "managePermGroups",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/basic/usergroup/UserGroupEdit')
                },

                //start of main settings
                {
                    path: '/settings/basicSettings/mainSettings',
                    name: 'settings.basicSettings.mainSettings',
                    meta: {
                        siteTitle: ('generic.lang_nav_systemSettings'),
                        menuTitle: ('generic.lang_nav_systemSettings'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageMainSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/sysetmdata/SystemSettings.vue')
                },
                {
                    path: '/settings/basicSettings/mainSettings/payment/ecterminal/create',
                    name: 'settings.basicSettings.mainSettings.payment.ecTerminal.create',
                    meta: {
                        siteTitle: 'EC Terminal',
                        menuTitle: 'EC Terminal',
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageMainSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/sysetmdata/CreateECTerminal')
                },
                {
                    path: '/settings/basicSettings/mainSettings/payment/ecTerminal/edit/:id',
                    name: 'settings.basicSettings.mainSettings.payment.ecTerminal.edit',
                    meta: {
                        siteTitle: 'Adyen Terminal',
                        menuTitle: 'adyen Terminal',
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageMainSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/sysetmdata/EditECTerminal')
                },
                {
                    path: '/settings/basicSettings/mainSettings/payment/adyenterminal/edit/:id',
                    name: 'settings.basicSettings.mainSettings.payment.adyenterminal.edit',
                    meta: {
                        siteTitle: 'Adyen Terminal',
                        menuTitle: 'adyen Terminal',
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageMainSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/sysetmdata/EditAdyenECTerminal')
                },
                {
                    path: '/settings/basicSettings/mainSettings/payment/ccvterminal/edit/:id',
                    name: 'settings.basicSettings.mainSettings.payment.ccvterminal.edit',
                    meta: {
                        siteTitle: 'CCV Terminal',
                        menuTitle: 'ccv Terminal',
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageMainSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/sysetmdata/EditCCVECTerminal')
                },
                {
                    path: '/settings/basicSettings/cahieroptions/edit/:id',
                    name: 'settings.basicSettings.cahieroptions.edit',
                    meta: {
                        siteTitle: 'Cashier Options',
                        menuTitle: '',
                        layout: 'default',
                        icon: '',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "manageMainSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/sysetmdata/EditCashierOptions')
                },
            ]
        },
        {
            meta: {
                menuTitle: ('settings.lang_modules'),
                icon: 'pe-7s-plugin',
                permission: {}
            },
            childs: [
                /*  {
                      path: '/settings/moduleSettings/modules',
                      name: 'settings.moduleSettings.modules',
                      meta: {
                          siteTitle: ('settings.lang_nav_modules'),
                          menuTitle: ('settings.lang_nav_modules'),
                          layout: 'default',
                          icon: '',
                          permission: {
                              requiredModule: 0,
                              requiredPermission: "set_modules",
                              hybridDisplay: "Server"
                          }
                      },
                      component: () => import('../views/common/404.vue')
                  },
                  {
                      path: '/settings/moduleSettings/ecTerminal',
                      name: 'settings.moduleSettings.ecTerminal',
                      meta: {
                          siteTitle: ('settings.lang_nav_ecterminal'),
                          menuTitle: ('settings.lang_nav_ecterminal'),
                          layout: 'default',
                          icon: '',
                          permission: {
                              requiredModule: 36,
                              requiredPermission: "",
                              hybridDisplay: "Server"
                          }
                      },
                      component: () => import('../views/common/404.vue')
                  },
                  {
                      path: '/settings/moduleSettings/prestashop',
                      name: 'settings.moduleSettings.prestashop',
                      meta: {
                          siteTitle: ('settings.lang_nav_prestashop'),
                          menuTitle: ('settings.lang_nav_prestashop'),
                          layout: 'default',
                          icon: '',
                          permission: {
                              requiredModule: 9,
                              requiredPermission: "",
                              hybridDisplay: "Server"
                          }
                      },
                      component: () => import('../views/common/404.vue')
                  },*/
                {
                    path: '/settings/moduleSettings/zmyle',
                    name: 'settings.moduleSettings.zmyle',
                    meta: {
                        siteTitle: ('Zmyle'),
                        menuTitle: ('Zmyle'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule:58,
                            requiredPermission: "zmyle_settings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/moduleSettings/Zmyle.vue')
                },
                {
                    path: '/settings/moduleSettings/myworld',
                    name: 'settings.moduleSettings.myworld',
                    meta: {
                        siteTitle: ('MyWorld'),
                        menuTitle: ('MyWorld'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule:57,
                            requiredPermission: "myworld_settings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/moduleSettings/MyWorld.vue')
                },
                {
                    path: '/settings/moduleSettings/paperlessinvoice',
                    name: 'settings.moduleSettings.paperless invoice',
                    meta: {
                        siteTitle: ('settings.lang_paperlessInvoice'),
                        menuTitle: ('settings.lang_paperlessInvoice'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule:62,
                            requiredPermission: "allowPaperlessInvoiceSettings",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/settings/moduleSettings/PaperlessInvoice')
                },
            ]
        },
        {
            meta: {
                menuTitle: ('settings.lang_navFiscalisation'),
                icon: 'pe-7s-culture',
                permission: {}
            },
            childs: [
                {
                    path: '/settings/fiscalisation_de/fiscalDevice',
                    name: 'settings.fiscalisation_de.fiscalDevice',
                    meta: {
                        siteTitle: ('settings.lang_fiscalDevice'),
                        menuTitle: ('settings.lang_fiscalDevice'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 34,
                            requiredPermission: "tseDevices",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/fiscal_de/FiscalDevice.vue')
                },
                {
                    path: '/settings/fiscalisation_de/fiscalClient',
                    name: 'settings.fiscalisation_de.fiscalClient',
                    meta: {
                        siteTitle: ('settings.lang_fiscalClients'),
                        menuTitle: ('settings.lang_fiscalClients'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 34,
                            requiredPermission: "tseClients",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/fiscal_de/FiscalClient.vue')
                },
                {
                    path: '/settings/fiscalisation_de/dsfinvkExport',
                    name: 'settings.fiscalisation_de.dsfinvkExport',
                    meta: {
                        siteTitle: 'DSFinV-K Export',
                        menuTitle: 'DSFinV-K Export',
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 34,
                            requiredPermission: "accounting_displaydsfunvkReports",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/fiscal_de/Export.vue')
                },
                {
                    path: '/settings/fiscalisation_de/fiscalExport',
                    name: 'settings.fiscalisation_de.fiscalExport',
                    meta: {
                        siteTitle: 'Fiskal Export',
                        menuTitle: 'Fiskal Export',
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 34,
                            requiredPermission: "tseExport",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/settings/fiscal_de/TSEExport.vue')
                },
            ]
        }
    ]
};
