<template>
  <v-menu bottom min-width="250" transition="scroll-y-transition" nudge-bottom="70" autofocus >
    <template v-slot:activator="{on,attr}">
      <!--
      <v-btn v-on="on" v-bind="attr" fab color="#e3e3e5" elevation="0" class="ma-1" :x-small="$vuetify.breakpoint.smAndDown" :small="$vuetify.breakpoint.mdAndDown" >
        <v-badge left bottom :content="unopened.length" color="orange" :class="{blink_me:unopened.length>0}" :value="unopened.length>0">
          <v-avatar  color="transparent" left class="text-white" rounded size="40">
            <v-img src="@/assets/images/TableBee.svg"/>
          </v-avatar>
        </v-badge>
      </v-btn>
      -->
        <v-btn v-on="on" v-bind="attr" fab color="#e3e3e5" elevation="0" class="ma-1" small :x-small="$vuetify.breakpoint.smAndDown" :loading="loadingOpen" >
          <v-avatar  color="transparent" left class="text-white" rounded size="30">
              <v-img src="@/assets/images/TableBee.svg"  />
          </v-avatar>
        </v-btn>
      <v-badge bottom left :content="unopened.length" color="orange" :class="{blink_me:unopened.length>0}" :value="unopened.length>0" />
    </template>
    <v-card  tile>
      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-plum-plate">
          <div class="menu-header-image dd-header-bg-1"></div>
          <div class="menu-header-content text-white text-center">
            {{$t('generic.lang_gui_notifications')}}
          </div>
        </div>
      </div>
      <v-card-text v-if="notifications && notifications.length>0" class="pa-0" style="max-height:400px;overflow-y: auto;">
        <p v-if="unopened.length>0" class="subtitle-2 px-2 text-uppercase ma-1 "><v-badge top right dot color="primary" >{{$t('generic.lang_new')}}</v-badge> </p>
        <v-list two-line  dense class="py-0" >
          <template v-for="(notification,index) in unopened">
            <v-list-item  dense :key="notification.UUID"
                          class="px-1" style="min-height: 50px !important;" @click="openNotification(notification)" >

              <v-list-item-avatar size="30">
                <font-awesome-icon v-if="notification.notificationType===1" style="font-size: 20px;" :icon="['fad','concierge-bell']" class="error--text lighten-2"/>
                <template v-else>
                  <font-awesome-icon v-if="notification.paymentType===1" style="font-size: 20px;" :icon="['fad','euro-sign']" class="success--text"/>
                  <font-awesome-icon v-else :icon="['fad','credit-card']" style="font-size: 20px;" class="primary--text"/>
                </template>
              </v-list-item-avatar>
              <v-list-item-content class="py-1">
                <v-list-item-title class="text-uppercase">
                  {{$t('generic.lang_table')}} <strong>{{notification.tableName}}</strong>
                </v-list-item-title>
                <v-list-item-subtitle v-if="notification.notificationType===1">
                  {{$t('tablebee.lang_requestedAWaiter')}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{$t('tablebee.lang_requestedToPayWith')}} {{payments[notification.paymentType]}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-badge top right dot color="primary"/>
              </v-list-item-icon>
            </v-list-item>
            <v-divider :key="index" class="ma-0"/>
          </template>
        </v-list>
        <p v-if="opened.length>0" class="subtitle-2 px-2 text-uppercase ma-1 "> {{$t('generic.lang_alreadySeen')}} </p>
        <v-list>
          <template v-for="(notification,index) in opened" >
            <v-divider style="border-color: lightgray !important;" :key="'x'+index.toString()" class="ma-0"/>
            <v-list-item dense :key="notification.UUID"
                         class="px-1" :style="[$vuetify.theme.dark? 'min-height: 50px !important;' : 'min-height: 50px !important;background-color: #efefef !important;']" @click="openNotification(notification)">
              <v-list-item-avatar size="30">
                <font-awesome-icon v-if="notification.notificationType===1" style="font-size: 20px;" :icon="['fad','concierge-bell']" class="error--text lighten-2"/>
                <template v-else>
                  <font-awesome-icon v-if="notification.paymentType===1" style="font-size: 20px;" :icon="['fad','euro-sign']" class="success--text"/>
                  <font-awesome-icon v-else :icon="['fad','credit-card']" style="font-size: 20px;" class="primary--text"/>
                </template>
              </v-list-item-avatar>
              <v-list-item-content class="py-1">
                <v-list-item-title class="text-uppercase" >
                  {{$t('generic.lang_table')}} <strong>{{notification.tableName}}</strong>
                </v-list-item-title>
                <v-list-item-subtitle v-if="notification.notificationType===1">
                  {{$t('tablebee.lang_requestedAWaiter')}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-else>
                  {{$t('tablebee.lang_requestedToPayWith')}} {{payments[notification.paymentType]}}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-card-text v-else>
        <h4 class="text-center">{{$t('generic.lang_nothingtoshow')}}</h4>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faConciergeBell,
    faEuroSign,
    faCreditCard,
} from '@fortawesome/pro-duotone-svg-icons';
import {Events} from "../../../plugins/events";
import {mapGetters} from "vuex";
library.add(
    faConciergeBell,
    faEuroSign,
    faCreditCard,
);
export default {
  name: "TablebeeNotificationsComponent",
  components:{
    'font-awesome-icon': FontAwesomeIcon,
  },
  sockets: {
    "tableplan.notify": function (payload) {
      if(payload)
        this.playSound();
      this.checkNotifications();
    },
    "tableplan.refreshTables": function () {
      this.getTables();
    },
    //if (this.socketTables.findIndex((tableName) => tableName === this.tables[index].name) > -1)
    //return true;
  },
  data(){
    return{
      loadingOpen:false,
      notifications:[],
      payments: {
        1: this.$t('generic.lang_cash'),
        3: this.$t('generic.lang_ec'),
        9: this.$t('generic.lang_creditCard'),
      },
      tables:[],
      sounds:[
        {
          id:0,
          name:this.$t('generic.lang_none'),
          src:'',
        },
        {
          id:1,
          name:"mixkit-bell-notification-933",
          src:require("@/assets/sounds/mixkit-bell-notification-933.wav"),
        },
        {
          id:2,
          name:"mixkit-clear-announce-tones-2861",
          src:require("@/assets/sounds/mixkit-clear-announce-tones-2861.wav"),
        },
        {
          id:3,
          name:"mixkit-correct-answer-reward-952",
          src:require("@/assets/sounds/mixkit-correct-answer-reward-952.wav"),
        },
        {
          id:4,
          name:"mixkit-doorbell-single-press-333",
          src:require("@/assets/sounds/mixkit-doorbell-single-press-333.wav"),
        },
        {
          id:5,
          name:"mixkit-happy-bells-notification-937",
          src:require("@/assets/sounds/mixkit-happy-bells-notification-937.wav"),
        },
        {
          id:6,
          name:"mixkit-magic-notification-ring-2344",
          src:require("@/assets/sounds/mixkit-magic-notification-ring-2344.wav"),
        },
        {
          id:7,
          name:"mixkit-musical-alert-notification-2309",
          src:require("@/assets/sounds/mixkit-musical-alert-notification-2309.wav"),
        },
        {
          id:8,
          name:"mixkit-sci-fi-reject-notification-896",
          src:require("@/assets/sounds/mixkit-sci-fi-reject-notification-896.wav"),
        },
        {
          id:9,
          name:"notification",
          src:require("@/assets/sounds/notification.mp3"),
        },
        {
          id:10,
          name:"notification2",
          src:require("@/assets/sounds/notification2.mp3"),
        },
        {
          id:11,
          name:"notification3",
          src:require("@/assets/sounds/notification3.mp3"),
        },
        {
          id:12,
          name:"notification4",
          src:require("@/assets/sounds/notification4.mp3"),
        },
        {
          id:13,
          name:"notification5",
          src:require("@/assets/sounds/notification5.mp3"),
        },
      ],
    }
  },
  computed:{
    ...mapGetters({
      socketTables:'pos/gastro/lockedTables',
    }),
    unopened(){
      if(this.notifications)
        return this.notifications.filter(not=>not.is_opened===0);
      else return []
    },
    opened(){
      if(this.notifications)
        return this.notifications.filter(not=>not.is_opened===1);
      else return []
    },
  },
  methods:{
    async checkNotifications(){
      this.axios.post(ENDPOINTS.TABLEBEE.NOTIFICATIONS.GET).then((res) => {
        if (res.data.status==='success') {
          this.notifications = res.data.data;
        }
      })
    },
    async openNotification(entry){

      if(this.socketTables.includes(entry.tableName)){
        return;
      }

      if(entry.is_opened===1){
        await this.openNotificationTable(entry);
        return;
      }
      this.loadingOpen=true;
      this.axios.post(ENDPOINTS.TABLEBEE.NOTIFICATIONS.OPEN,{
        notificationUUID:entry.UUID,
      }).then(async (res) => {
        if (res.data.STATUS==='SUCCESS') {
          entry.is_opened=1;
          this.$socket.emit('tableplan.notify');
          await this.openNotificationTable(entry);
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingOpen=false;
      })
    },
    getTables() {
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then((res) => {
        if (res.status === 200) {
          this.tables = res.data.tables;
        }
      }).catch((err) => {

      });
    },
    async getTableOrders(tablename) {

      this.axios.post(ENDPOINTS.POS.GASTRO.ORDERS.GET, {
        tableName: tablename
      }).then((res) => {

        if (res.data.success) {

          //set the customer if there is one

          if (res.data.customerId) {
            this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
              customerID: res.data.customerId,
            }).then((res2) => {
              if (res2.data.success) {
                this.$store.dispatch("pos/setCustomer", {
                  posType: this.posType,
                  customer: res2.data.customer
                })
              }
            }).catch((err) => {
              Events.$emit("showSnackbar", {
                color: "error",
                message: this.$t('generic.lang_errorOccurred') + ' ' + this.$t('generic.lang_whileGettingTheCustomer') + ' : ' + err.message,
              });
            });
          } else {
            this.$store.dispatch("pos/setCustomer", {
              posType: this.posType,
              customer: null,
            })
          }

          let cntr = 0;
          for (let partyName in res.data.orders) {
            if (!res.data.orders.hasOwnProperty(partyName))
              continue;


            this.$store.commit("pos/gastro/setOrderedItems", {
              partyName: partyName,
              orders: res.data.orders[partyName]
            });

          }

        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred') + ' ' + this.$t('generic.lang_whileGettingTheOrder') + ' : ' + err.message,
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      });
    },
    async openNotificationTable(table) {
      let searchTable=this.tables.find(t=>table.tableName===t.name)
      if(searchTable){
        this.$store.commit("pos/gastro/clearTableParties");
       await this.$store.dispatch("pos/gastro/setTable", {
          posType: 'gastro',
          table: {
            name: searchTable.name,
            parties: searchTable.parties,
            totalParties: searchTable.totalParties,
            total: searchTable.total,
            soldBy: searchTable.soldBy,
            timestamp: searchTable.timestamp,
            date: searchTable.date,
            source: searchTable.source,
          }
        }).then(async () => {
          await this.getTableOrders(table.tableName);
          this.$socket.emit("tableplan.setTable", table.tableName);
          //REDIRECT TO POS
          this.$router.replace({
            path: '/pos/gastro',
            query: {
              notification:'',
            }
          });
         Events.$emit("redirect",'items');
        })
      }else{
        let date = new Date();
        let time = date.getHours() + ":" + date.getMinutes();
        this.$store.commit("pos/gastro/clearTableParties");
        this.$store.dispatch("pos/gastro/setTable", {
          posType: 'gastro',
          table: {
            name: table.tableName,
            parties: [
              {
                name: table.partyName
              }
            ],
            totalParties: 1,
            total: 0,
            date: time,
          }
        }).then(() => {
          this.$socket.emit("tableplan.setTable", table.tableName);
          //REDIRECT TO POS

          this.$router.replace({
            path: '/pos/gastro',
            query: {
              notification:'',
            }
          });

          // we fire this event to update the mobile ui whenever we re already in it
          Events.$emit("redirect",'items');
        })
      }
    },
    playSound(){
      if(Number(this.$store.getters['settings/getSettingValue']('tb_notification_sound_id'))){
        let sound=this.sounds.find(s=>Number(this.$store.getters['settings/getSettingValue']('tb_notification_sound_id')) === s.id);
        if(sound){
          let audio = new Audio(sound.src);
          audio.play();
        }
      }
    }
  },
  mounted() {
    this.checkNotifications();
    this.getTables();
  },
}
</script>

<style scoped>

.blink_me {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: .2;
  }
}

</style>
