export default {
    namespaced: true,

    state: {
        activeUser:{
            userID: null,
            name: "",
            last_name: "",
            avatarURI: ""
        },
        users:[],

    },

    mutations: {
        addUser(state,data){
            state.users.push(data);
            //Vue.set(state.users, state.users.length,data)
        },
        setActiveUser(state,data){
                    state.activeUser.userID=parseInt(data.userID);
                    state.activeUser.name=data.name;
                    state.activeUser.last_name=data.last_name;
                    state.activeUser.avatarURI=data.avatarURI;
        },
        removeUser(state,userID){
            for(let i=0,l=state.users.length;i<l;i++){
                if(state.users[i].userID===parseInt(userID)){
                    state.users.splice(i,1);
                    break;
                }
            }
        },
        clearActiveUser(state){
            state.activeUser.userID=null;
            state.activeUser.name="";
            state.activeUser.last_name="";
            state.activeUser.avatarURI="";
        }
    },

    getters: {
        activeUser(state){
            return state.activeUser;
        },
        allUsers(state){
            return state.users
        }
    },

    actions: {

    }
};
