import {erpRoutes} from "./erpRoutes";
import {accountingRoutes} from "./accountingRoutes";
import {analyticRoutes} from "./analyticRoutes";
import {customerRoutes} from "./customerRoutes";
import {settingRoutes} from "./settingRoutes";
import {tableBeeRoutes} from "./tablebeeRoutes";

export const ROUTER = {
    ENTRIES: [
        // --- LOGIN ---

        {
            path: '/index.html',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: true,
                siteTitle: 'Willkommen bei 3POS',
                menuTitle: '',
                layout: 'userpages',
                icon: '',
                permission: {}
            },
            component: () => import('../views/auth/Index'),
        },
        {
            path: '/',
            name: 'init',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: true,
                siteTitle: 'Willkommen bei 3POS',
                menuTitle: '',
                layout: 'userpages',
                icon: '',
                permission: {}
            },
            component: () => import('../views/auth/Index'),
        },
        // DASHBOARD
        {
            path: '/dashboards/analytics',
            name: 'dashboard',
            groups: [],
            childs: [],
            meta: {
                siteTitle: 'Dashboard',
                menuTitle: 'Dashboard',
                layout: 'default',
                icon: 'pe-7s-graph2',
                permission: {}
            },
            component: () => import('../views/dashboard/Analytics.vue'),
        },
        //Gastro POS
        {
            path: '/pos/gastro',
            name: 'pos.gastro',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: true,
                siteTitle: ('generic.lang_nav_gastrkasse'),
                menuTitle: ('generic.lang_nav_gastrkasse'),
                layout: 'default',
                icon: 'lnr-dinner',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 2,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: "",
                    hideInNavigation: false,
                }
            },
            component: () => import('../views/pos/Gastro.vue'),
        },
        {
            path: '/pos/gastro/tresenverkauf',
            name: 'pos.gastro.tresenverkauf',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: false,
                siteTitle: ('generic.lang_nav_gastrkasse'),
                menuTitle: ('generic.lang_nav_gastrkasse'),
                layout: 'default',
                icon: 'lnr-dinner',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 2,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: "",
                }
            },
            component: () => import('../views/pos/GastroTresenverkauf.vue'),
        },
        /* {
             path: '/pos/gastro/mobile-direct-sale',
             name: 'pos.gastro.mobile.direct.sale',
             groups: [],
             childs: [],
             meta: {
                 hideInNavigation: false,
                 siteTitle: ('generic.lang_table_Tresenverkau'),
                 menuTitle: ('generic.lang_table_Tresenverkau'),
                 layout: 'default',
                 icon: 'lnr-dinner',
                 permission: {
                     requiredModule: 2,
                     requiredPermission: "show_cashierui",
                     hybridDisplay: ""
                 }
             },
             component: () => import('../views/pos/GastroTresenverkauf.vue'),
         },*/
        {
            path: '/pos/gastro/tables/:isChange?',
            name: 'pos.gastro.tables',
            groups: [],
            childs: [],
            meta: {
                siteTitle: ('generic.lang_nav_tableOverview'),
                menuTitle: ('generic.lang_nav_tableOverview'),
                layout: 'default',
                icon: 'lnr-dinner',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 2,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: "",
                }
            },
            component: () => import('../views/pos/GastroTables.vue'),
        },
        {
            path: '/pos/rfid',
            name: 'pos.rfid',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: false,
                siteTitle: ('generic.lang_nav_rfidCashier'),
                menuTitle: ('generic.lang_nav_rfidCashier'),
                layout: 'default',
                icon: 'lnr-dinner',
                permission: {
                    requiredModule: 50,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pos/RFIDScan.vue'),
        },
        // --- Tablebee ---
        {},
        // --- WARENWIRTSCHAFT ---

        {},
        // --- BUCHHALTUNG ---
        {},
        // --- ANALYSEN / AUSWERTUNG ---
        {},

        // --- KUNDENVERWALTUNG ---
        {},
        // --- SYSTEMEINSTELLUNGEN ---
        {},
        // HILFE
        {
            groups: [],
            childs: [],
            path: '/help',
            name: 'help',
            meta: {
                siteTitle: ('generic.lang_nav_help'),
                menuTitle: ('generic.lang_nav_help'),
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {}
            },
            component: () => import('../views/help/Help.vue'),
        },

    ]
};
ROUTER.ENTRIES[7] = tableBeeRoutes;
ROUTER.ENTRIES[8] = erpRoutes;
ROUTER.ENTRIES[9] = accountingRoutes;
ROUTER.ENTRIES[10] = analyticRoutes;
ROUTER.ENTRIES[11] = customerRoutes;
ROUTER.ENTRIES[12] = settingRoutes;
