export let analyticRoutes = {

    meta: {
        menuTitle: ('generic.lang_analysisAndStats'),
        icon: 'pe-7s-display1',

    },
    groups: [
        {
            meta: {
                menuTitle: ('Umsatzanalysen'),
                icon: 'lnr-chart-bars',
                permission: {}
            },
            childs: [
                {
                    path: '/analytics/salestime',
                    name: 'analytics.saletsime',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesTime'),
                        menuTitle: ('analytics.lang_salesTime'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/sales/index')
                },
                {
                    path: '/analytics/itemsalestime',
                    name: 'analytics.itemsalestime',
                    childs: [],
                    meta: {
                        siteTitle: 'Item Sales/time',
                        menuTitle: 'Item Sales/time',
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/itemsale/index')
                },
                {
                    path: '/analytics/sales-per-time-statistics',
                    name: 'analytics.sales.per.time.statistics',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesPerTimeStatistics'),
                        menuTitle: ('analytics.lang_salesPerTimeStatistics'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/salespertimestatistics/index')
                },
                {
                    path: '/analytics/sales-per-hour-statistics',
                    name: 'analytics.sales.per.hour.statistics',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesPerHourStatistics'),
                        menuTitle: ('analytics.lang_salesPerHourStatistics'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/salesperhourstatistics/index')
                },
                {
                    path: '/analytics/sales-per-day-statistics',
                    name: 'analytics.sales.per.day.statistics',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesPerDayStatistics'),
                        menuTitle: ('analytics.lang_salesPerDayStatistics'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/salesperdaystatistics/index')
                },
                {
                    path: '/analytics/sales-per-month-statistics',
                    name: 'analytics.sales.per.month.statistics',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesPerMonthStatistics'),
                        menuTitle: ('analytics.lang_salesPerMonthStatistics'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/salespermonthstatistics/index')
                },
                {
                    path: '/analytics/sales-per-year-statistics',
                    name: 'analytics.sales.per.year.statistics',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesPerYearStatistics'),
                        menuTitle: ('analytics.lang_salesPerYearStatistics'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/salesperyearstatistics/index')
                },
                {
                    path: '/analytics/sales-per-payement-type-statistics',
                    name: 'analytics.sales.per.payement.type.statistics',
                    childs: [],
                    meta: {
                        siteTitle: ('analytics.lang_salesPerPayementTypeStatistics'),
                        menuTitle: ('analytics.lang_salesPerPayementTypeStatistics'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('@/views/analytics/wareanalytics/salesperpayementtypestatistics/index')
                },
            ]
        },
        {
            meta: {
                menuTitle: 'Dashboard',
                icon: 'pe-7s-graph2',
                permission: {}
            },
            childs: [],
        },
        {

            meta: {
                menuTitle: ("Warenanalysen"),
                icon: 'lnr-pie-chart',
                permission: {}
            },
            childs: []
        },
        {
            meta: {
                menuTitle: ('accounting.lang_nav_accounting_reports'),
                icon: 'pe-7s-news-paper',
                permission: {}
            },
            childs: [
                {
                    path: '/reports/lists/',
                    name: 'reports.lists',
                    meta: {
                        siteTitle: ('reports.lang_lists'),
                        menuTitle: ('reports.lang_lists'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "saleReport",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/reports/Lists')
                },
                {
                    path: '/reports/itemsale/',
                    name: 'reports.itemsale',
                    meta: {
                        siteTitle: ('reports.saleReport'),
                        menuTitle: ('reports.saleReport'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "saleReport",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/reports/SaleReport')
                },
                {
                    path: '/reports/storage/',
                    name: 'reports.storage',
                    meta: {
                        siteTitle: ('reports.storageReport'),
                        menuTitle: ('reports.storageReport'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/reports/StorageReport')
                },

            ]
        },
    ],
    childs: [
        {
            path: '/analytics/waresanalytics/topwares',
            name: 'analytics.waresanalytics.topwares',
            childs: [],
            meta: {
                siteTitle: 'Products Analytics',
                menuTitle: 'Products Analytics',
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/topwares/index')
        },
        {
            path: '/analytics/waresanalytics/articlegroups',
            name: 'analytics.waresanalytics.articlegroups',
            childs: [],
            meta: {
                siteTitle: ('analytic.lang_nav_analytics_QTYwaregroups'),
                menuTitle: ('analytic.lang_nav_analytics_QTYwaregroups'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/articlegroups/index')
        },
        {
            path: '/analytics/waresanalytics/garnishanalytics',
            name: 'analytics.waresanalytics.garnishanalytics',
            childs: [],
            meta: {
                siteTitle: ('analytic.lang_garnishAnalytics'),
                menuTitle: ('analytic.lang_garnishAnalytics'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/garnishreport/index')
        },
        {
            path: '/analytics/waresanalytics/itemmargins',
            name: 'analytics.waresanalytics.itemmargins',
            childs: [],
            meta: {
                siteTitle: ('accounting.lang_itemMargin'),
                menuTitle: ('accounting.lang_itemMargin'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/itemmargins/index')
        },
        {
            path: '/analytics/waresanalytics/waregroupmargins',
            name: 'analytics.waresanalytics.waregroupmargins',
            childs: [],
            meta: {
                siteTitle: ('accounting.lang_itemGroupMargin'),
                menuTitle: ('accounting.lang_itemGroupMargin'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/itemgroupmargins/index')
        },
        {
            path: '/analytics/waresanalytics/salesByItemgroupProducer/',
            name: 'analytics.waresanalytics.salesByItemgroupProducer',
            childs: [],
            meta: {
                siteTitle: ('accounting.lang_salesLists'),
                menuTitle: ('accounting.lang_salesLists'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/manufacturer/index')
        },
        {
            path: '/analytics/orders',
            name: 'analytics.orders',
            childs: [],
            meta: {
                siteTitle: ('generic.lang_orders'),
                menuTitle: ('generic.lang_orders'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/orders/index')
        },
        {
            path: '/analytics/categories',
            name: 'analytics.categories',
            childs: [],
            meta: {
                siteTitle: ('analytic.lang_salesbyType'),
                menuTitle: ('analytic.lang_salesbyType'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/categories/index')
        },
        {
            path: '/analytics/cancellation/immediate',
            name: 'analytics.cancellation.immediate',
            childs: [],
            meta: {
                siteTitle: ('analytic.lang_lineVoids'),
                menuTitle: ('analytic.lang_lineVoids'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/immediatecancellation/index')
        },
        {
            path: '/analytics/cancellation/invoice',
            name: 'analytics.cancellation.invoice',
            childs: [],
            meta: {
                siteTitle: ('accounting.lang_stornos'),
                menuTitle: ('accounting.lang_stornos'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/invoicecancellation/index')
        },
        {
            path: '/analytics/vouchergiftcard',
            name: 'analytics.vouchergiftcard',
            childs: [],
            meta: {
                siteTitle: ('analytic.lang_voucherGiftCardAnalytic'),
                menuTitle: ('analytic.lang_voucherGiftCardAnalytic'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/vouchergiftcard/index')
        },
        {
            path: '/analytics/singleanalyse',
            name: 'analytics.singleanalyse',
            childs: [],
            meta: {
                siteTitle: ('analytic.lang_singleanalyse'),
                menuTitle: ('analytic.lang_singleanalyse'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/SingleAnalyse')
        },
        //start sales per day
        {
            path: '/analytics/sales-per-day',
            name: 'analytics.sales.per.day',
            childs: [],
            meta: {
                siteTitle: ('analytics.lang_salesPerDay'),
                menuTitle: ('analytics.lang_salesPerDay'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/SalesPerDayView')
        },//end sales per day
        //start sales per hours
        {
            path: '/analytics/sales-per-hour',
            name: 'analytics.sales.per.hour',
            childs: [],
            meta: {
                siteTitle: ('generic.lang_salesPerHour'),
                menuTitle: ('generic.lang_salesPerHour'),
                layout: 'default',
                icon: '',
                hideInNavigation: false,
                permission: {
                    requiredModule: 0,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/analytics/wareanalytics/SalesPerHourView')
        }//end sales per hours
    ],
};
