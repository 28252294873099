export default {
    namespaced: true,

    state: {
        vuexStoreVersion: 190, // -> ALSO SET in store/modules/backgroundSync.js
        language: 'de',
        initDataLoaded: false,
        useAPIForPOSActions: false, // USE API WHEN SYSTEMS CONTAINS MASSIVE AMOUNT OF DATA (ITEMS, ... ) TO SPEED UP i.e. searching
        isDark: false,
        fiscalState: false,
    },

    mutations: {
        changeLanguage(state, data) {
            state.language = data;
        },
        setFiscalState(state, data) {
            state.fiscalState = data;
        },
        updateInitDataLoadedState(state, data) {
            state.initDataLoaded = data;
        },
        updateVuexStoreVersion(state, version) {
            state.vuexStoreVersion = version;
        },
        setUseAPIPos(state, status) {
            state.useAPIForPOSActions = status;
        },
        switchTheme(state) {
            state.isDark = !state.isDark;
        },
        setTheme(state, data) {
            state.isDark = data;
        }

    },

    actions: {
        setFiscalState({commit}, data) {
            commit('setFiscalState', data);
        },
        changeLanguage({commit}, data) {
            commit('changeLanguage', data);
        },
        updateInitDataLoadedState({commit}, data) {
            commit('updateInitDataLoadedState', data);
        },
        setUseAPIPos({commit}, data) {
            commit('setUseAPIPos', data);
        },

    },

    getters: {
        currentLanguage: (state) => state.language,
        fiscalState: (state) => state.fiscalState,
        isDarkTheme: (state) => state.isDark,
    }
};
