export default {
    namespaced: true,

    state: {
        permissions: [],
        modules: [],
        hybrid: 0,
    },

    mutations: {
        update(state, data) {
            state.permissions = data.permissions;
            state.modules = data.modules;
            state.hybrid = data.hybrid;
        },
    },
    actions: {
        update({commit}, data) {
            commit('update', data);
        },
    },
    getters: {
        modules: (state) => state.modules,
        checkModule: (state) => (moduleID) => {
            return state.modules.includes(moduleID);
        },
        checkPermission: (state) => (permission) => {
            return state.permissions.includes(permission);
        },
        /* CHECKS IF DISPLAYABLE WHEN USING HYBRIDSERVER (SERVER / HYBRID MODE) */
        checkHybridEnabled: (state) => (hybridType) => {
            if (state.hybrid > 0) {
                //DISPLAY ONLY ON HYBRID
                if (hybridType == "Hybrid") {
                    if (process.env.VUE_APP_HYBRID == "true") {
                        return true;
                    } else {
                        return false;
                    }
                    //DISPLAY ONLY ON SERVER
                } else if (hybridType == "Server") {
                    if (process.env.VUE_APP_HYBRID == "true") {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return true;
                }
            } else {
                //If no Hybrid Display everytime
                return true;
            }
        },
        checkHybridState: (state) => {
            if (state.hybrid > 0) {
                return true;
            } else {
                return false;
            }
        },
        checkRoutePermission: (state) => (route) => {
            if (route.hasOwnProperty("meta")) {
                if (route.meta.hasOwnProperty("permission")) {
                    //MODULE
                    if (route.meta.permission.hasOwnProperty("requiredModule")) {
                        if (!state.modules.includes(route.meta.permission.requiredModule) && route.meta.permission.requiredModule > 0)
                            return false;
                    }

                    //CHECK IF ACTIVATED ON HYBRID/SERVER OR DEACTIVED
                    if (state.hybrid > 0) {
                        if (route.meta.permission.hasOwnProperty("hybridDisplay")) {
                            if (process.env.VUE_APP_HYBRID == "true") {
                                //WE'RE ON HYBRIDSERVER
                                if (route.meta.permission.hybridDisplay == "Server") {
                                    return false;
                                }
                            } else {
                                //WE'RE ON CLOUD
                                if (route.meta.permission.hybridDisplay == "Hybrid") {
                                    return false;
                                }
                            }
                        }
                    }

                    //USER PERMISSION
                    if (route.meta.permission.hasOwnProperty("requiredPermission")) {
                        if (route.meta.permission.requiredPermission.length === 0)
                            return true;

                        if (!state.permissions.includes(route.meta.permission.requiredPermission))
                            return false;
                    }
                }
            }

            return true;
        }
    }
};
