<template>
  <div>
    <v-dialog max-width="600" v-model="visible">
      <v-card color="" class="">
        <v-card-text class="text-center font-weight-bold warning--text large pt-5">
          {{ $t("generic.lang_theHybridServerHasntSynchronizedFor10MinutesCheckInternetAndRestartHybrid") }}
          <span v-if="(10 - durationSinceLastHybridContact)<=10 && (10 - durationSinceLastHybridContact)>0">{{ $t("generic.lang_theyHave") }} {{ 10 - durationSinceLastHybridContact }} {{ $t("generic.lang_daysToEliminateTheProblemOtherwiseTheCheckoutWillBeBlocked") }}</span>
        </v-card-text>
        <v-card-actions class="text-center">

          <v-spacer/>
          <v-btn depressed @click="visible = false" color="error" class="mx-auto mr-1">
            {{ this.$t("generic.lang_close") }}
          </v-btn>

          <v-btn v-show="false" class="mx-auto" depressed color="primary">
            {{ $t("generic.lang_automaticRecovery") }}
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog fullscreen v-model="visibleStopDialog" persistent>
      <v-card elevation="0" class="fill-height">
        <v-row elevation="0" class="mx-auto ma-auto border fill-height" align="center" justify="center">
          <v-card color="">
            <v-card-text class="text-center font-weight-bold warning--text large pt-5">     
              {{ $t("generic.lang_yourHybridServerHasntSyncedFor10Days") }} support@3pos.de {{ $t("generic.lang_gen_or") }} 0431 / 1280 334
            </v-card-text>
            <v-card-actions class="text-center">

              <v-spacer/>
              <v-btn depressed @click="logout" color="error" class="mx-auto mr-1">
                {{ this.$t("generic.lang_logout") }}
              </v-btn>

              <v-btn  class="mx-auto" depressed color="warning" @click="prolongTime">
                {{ this.$t('generic.lang_prolongForTwoDays') }}
              </v-btn>
              <v-btn v-show="false" class="mx-auto" depressed color="primary">
                {{ $t("generic.lang_automaticRecovery") }}
              </v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>
        </v-row>

      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import {ENDPOINTS} from "../config";
import {Events} from "../plugins/events";

export default {
  name: "OfflineHybridDialog",
  watch: {
    /*visible(val) {

     if (!val) {
       this.$store.commit("hybrid/turnOnlineForHybridServer");
       this.$store.commit("hybrid/turnOnlineForCloudServer");
     }
     console.log("visible", val, this.IsOfflineForHybridServer, this.IsOfflineForCloudServer)
   },
  IsOfflineForHybridServer(val) {
     if (val && this.$store.getters['user/__loggedIn']) {
       this.visible = val;
       window.store.commit('hybrid/lastHybridDialogAppear');
     }
     this.$forceUpdate();
   },
   IsOfflineForCloudServer(val) {
     if (val && this.$store.getters['user/__loggedIn']) {
       this.visible = val;
       window.store.commit('hybrid/lastHybridDialogAppear');
     }
     this.$forceUpdate();
   },
   IsHybridServerOfflineOrInStop(val) {

     if (val && this.$store.getters['user/__loggedIn']) {
       this.visibleStopDialog = val;
       window.store.commit('hybrid/lastHybridDialogAppear');
     }
     this.$forceUpdate();
   }*/

    //this.$store.commit("backgroundSync/IS_HybridServerOfflineOrInStop", false)

  },
  data: () => {
    return {
      visible: false,
      visibleStopDialog: false,
    }
  },
  computed: {
    ...createNamespacedHelpers("hybrid").mapGetters([
      "IsOfflineForHybridServer",
      "IsOfflineForCloudServer",
      "lastHybridDialogAppear",
      "IsHybridServerOfflineOrInStop",
      "lastHybridContact"
    ]),
    durationSinceLastHybridContact(){
      return this.$moment.duration(this.$moment(new Date()).unix() - this.lastHybridContact,'seconds').days();
    }
  },
  mounted() {
    //listen on hybrid status changes
    window.eventBus.$on('status', ({server, status, type}) => {
      if (this.$store.getters['user/__loggedIn']) {

        //show 10 min dialog if cloud or hybrid is offline for 10 min
        if (status && (server === "cloud" || server === "hybrid")) {
          this.visible = true;
          this.visibleStopDialog = false;
          window.store.commit('hybrid/lastHybridDialogAppear');
        } else if (server === "hybrid") {
          //show logout dialog if hybrid is offline for 7 days or more
          if (type === "stop") {
            this.visibleStopDialog = true;
            this.visible = false;
            this.$store.commit("hybrid/turnOnlineForHybridServer");
          } else {
            this.visibleStopDialog = false;
            this.visible = status;
            this.$store.commit("hybrid/turnOnlineForHybridServer");
          }

        } else if (server === "both" && !status) {//no server if offline
          this.visible = false;
          this.$store.commit("hybrid/turnOnlineForHybridServer");
          this.$store.commit("hybrid/turnOnlineForCloudServer");
          localStorage.removeItem('last_hybrid_dialog_appear')
        }
      }

    })
  },
  beforeDestroy() {
    window.eventBus.$off('status');
  },
  methods: {
    logout() {
      this.$store.dispatch("user/logout", {vm: this}).then(() => {
        this.visibleStopDialog = false;
        this.$router.replace('/')
      });
    },
    prolongTime(){
      //On your own risk. It may break your system
      this.$swal({
        title: this.$t('generic.lang_warning'),
        text: this.$t('generic.lang_onYourOwnRiskItMayBreakYourSystem'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_ok'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post('update/system/increaseSyncTimer/').then((res) => {
            if (res.data.success) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_ActionSuccessful'),
                color: "success"
              });
              this.visibleStopDialog = false;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => false,
      });
    }
  }
}
</script>

<style scoped>

</style>
