import store from "@/store";
import Vue from "vue";
import {geol} from "../../main.js";

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it

if (store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}

let enableOrderbonItemNameTranslation=false;

export function createOrderBonPrintingData(items, data, printerOpts, globalFreetext, activeUser, deliveryService = false,isDup = false,isArchive = false) {
    if (typeof globalFreetext === 'undefined') {
        globalFreetext = "";
    }

    let orderbonTempl = 1;
    if (typeof store.state.settings.settings.posGastroOrderbonTemplate === 'undefined') {
        orderbonTempl = 1;
    } else {
        orderbonTempl = parseInt(store.state.settings.settings.posGastroOrderbonTemplate);
    }
    if (orderbonTempl < 1) {
        orderbonTempl = 1;
    }


    let singlePrintEachPosition = false;

    // CHECK IF NEED TO PRINT EACH POSITION
    if (printerOpts.hasOwnProperty("autoPaperCut")) {
        if (parseInt(printerOpts.autoPaperCut) === 1) {
            singlePrintEachPosition = true;
        }
    }

    let printWaitersBons = false;

    // CHECK IF WE NEED TO REPRINT POSITIONS SEPARATLY
    if (printerOpts.hasOwnProperty("printWaiterOrderBon")) {
        if (parseInt(printerOpts.printWaiterOrderBon) === 1) {
            printWaitersBons = true;
        }
    }


    let coursesOnOneOrderbon = false;

    let coursesOnOneOrderbonCnt = 0;

    // CHECK IF NEED TO PRINT EACH POSITION
    if (printerOpts.hasOwnProperty("coursesOnOneBon")) {
        if (parseInt(printerOpts.coursesOnOneBon) === 1) {
            coursesOnOneOrderbon = true;
        }
    }


    var pickUpNo = "";
    if (typeof store.state.pos.gastro.pickup === 'undefined') {
        pickUpNo = "";
    } else {
        if (deliveryService) {
            pickUpNo = data.pickUpNo;
        } else {
            pickUpNo = store.state.pos.gastro.pickup;
        }
    }
    let printObj = [];

    //ADD BUZZER SOUND VIA CASHDRAWER OPEN

    if (printerOpts.hasOwnProperty("playBuzzer")) {
        if (parseInt(printerOpts.playBuzzer) === 1) {
            printObj.push({cashDrawer: true});
        }
    }
    if(activeUser && activeUser.userID===101){
        printObj.push({feed: 1});
        printObj.push({align: 'center'});
        printObj.push({fontSize: 2});
        printObj.push({reverse: 'on'});
        printObj.push({text: geol.t('generic.lang_training').toUpperCase()});
        printObj.push({reverse: 'off'});
        printObj.push({fontSize: 1});
    }
    let singlePrintEachPositionArray = [];


    if (singlePrintEachPosition === true) {
        items.forEach(function (item) {
            singlePrintEachPositionArray.push(item);
        });
    } else {
        singlePrintEachPositionArray[0] = items;
    }


    // --- DATE ---
    let today = new Date(data.timestamp * 1000);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let hours, minutes;

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }

    hours = today.getHours();
    minutes = today.getMinutes();

    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    // ITEM NAME TRANSLATION SETTINGS

    let enableOrderbonTranslations = Number(store.state.settings.settings.enableOrderbonTranslations);
    let printingTranslationType = Number(store.state.settings.settings.printingTranslationType);


    singlePrintEachPositionArray.forEach(function (items) {
        let step1Array = [];
        if (!Array.isArray(items)) {
            step1Array.push(items);
        } else {
            step1Array = items;
        }

        let itemsCoursPrint = [];

//CHECK IF WE HAVE COURSES DEFINED
        let coursesEnabled = 0;
        step1Array.forEach(function (item) {
            if (item.hasOwnProperty("course") && item.course !== null) {
                coursesEnabled = 1;
                if (!itemsCoursPrint[item.course.id]) {
                    itemsCoursPrint[item.course.id] = [];
                }
                itemsCoursPrint[item.course.id].push(item);
            } else {
                if (!itemsCoursPrint[0]) {
                    itemsCoursPrint[0] = [];
                }
                itemsCoursPrint[0].push(item);
            }
        });

        let lengthOfCoursePrinting = itemsCoursPrint.filter(Boolean).length;

        if (orderbonTempl == 1) {
            itemsCoursPrint.forEach(function (items) {

                //CONTER +1
                coursesOnOneOrderbonCnt++;

                if (coursesOnOneOrderbon === false || (coursesOnOneOrderbon === true && coursesOnOneOrderbonCnt === 1)) {

                    printObj.push({
                        feed: 5
                    });

                    //ORDER QR CODE FOR PICKUP
                    if(data.hasOwnProperty("orderUUID")){
                        printObj.push({align: "center"});
                        printObj.push({qrCode: 'ORD$'+data.orderUUID});
                    }

                    printObj.push({
                        lineSpace: 30
                    });

                    printObj.push({
                        font: "B"
                    });

                    if(isDup){
                        printObj.push({
                            font: "B"
                        });
                        printObj.push({
                            fontSize: 3
                        });
                        printObj.push({
                            reverse: 'on'
                        });
                        printObj.push({
                            align: "center"
                        });
                        printObj.push({
                            text: geol.t('generic.lang_duplicate').toUpperCase()
                        });
                        printObj.push({
                            reverse: 'off'
                        });
                    }else if(isArchive){
                        printObj.push({
                            font: "B"
                        });
                        printObj.push({
                            fontSize: 3
                        });
                        printObj.push({
                            reverse: 'on'
                        });
                        printObj.push({
                            align: "center"
                        });
                        printObj.push({
                            text: geol.t('generic.lang_archive')
                        });
                        printObj.push({
                            reverse: 'off'
                        });
                    }

                    printObj.push({
                        fontSize: 2
                    });

                    printObj.push({
                        align: "center"
                    });



                    //CHECK IF CASHIER ALIAS NAME SHOULD BE SHOWN
                    if (printerOpts.hasOwnProperty("showCashierAlias"))
                        if (parseInt(printerOpts.showCashierAlias) === 1) {
                            //GET CASHIER ALIAS NAME
                            let cashier_alias = store.getters['api/auth/cashierAliasName'];

                            printObj.push({
                                text: cashier_alias
                            });
                        }
                    if ((deliveryService || store.state.pos.gastro.isDeliveryService) && data.hasOwnProperty('isAsap')) {
                        let tmp = '';
                        printObj.push({
                            fontSize: 4
                        });
                        printObj.push({
                            feed: 1
                        });
                        if(!data.isAsap)
                            tmp = ' ' + geol.t('datatables.lang_dtNext');

                        if (data.isDelivery)
                            tmp = data.zip + tmp;

                        if(tmp.length>0)
                            printObj.push({
                                text: tmp,
                            });
                    } else if (data.isDelivery) {
                        // show zip code in the top of
                        printObj.push({
                            fontSize: 2
                        });

                        printObj.push({
                            text: data.zip
                        });
                    }

                    printObj.push({
                        reverse: 'on'
                    });

                    // CHECK IF DELIVERY SERVICE
                    if (deliveryService || store.state.pos.gastro.isDeliveryService) {
                        printObj.push({
                            fontSize: 4
                        });
                        printObj.push({
                            fontSize: 4
                        });
                        printObj.push({
                            text: (data.isDelivery ? ' ' + geol.t('delivery.lang_delivery') + ' ' : ' ' + geol.t('delivery.lang_pickup') + ' ')
                        });
                        printObj.push({
                            feed: 1
                        });
                        printObj.push({
                            fontSize: 2
                        });
                        if (data.hasOwnProperty('serviceTime'))
                            printObj.push({
                                text: geol.t('generic.lang_time')+" : " + data.serviceTime,
                            });
                    }
                    if (!(deliveryService || store.state.pos.gastro.isDeliveryService)) {
                        printObj.push({
                            text: (data.table === 0 ? '  ' + geol.t('generic.lang_table_Tresenverkau') + '  ' : "  " + data.table + "  ")
                        });

                        if (data.table !== 0) {
                            printObj.push({
                                text: (" " + data.party + " ")
                            });
                        }
                    }

                    if ((data.table === 0 && pickUpNo.toString().length > 0 && store.state.settings.settings.pickup_enable > 0 && store.state.pos.gastro.pagerNo === null) || (deliveryService || store.state.pos.gastro.isDeliveryService)) {
                        printObj.push({
                            text: geol.t('generic.lang_pickUpNumber') + ': ' + pickUpNo
                        });
                    }

                    printObj.push({
                        reverse: 'off'
                    });

                    // AUSSER HAUS
                    if (store.state.pos.gastro.hasOwnProperty("takeAway"))
                        if (store.state.pos.gastro.takeAway === 1) {
                            printObj.push({
                                text: geol.t('generic.lang_offSiteSale')
                            });
                        }

                    // PAGER NUMBER
                    if (store.state.pos.gastro.pagerNo !== null) {
                        printObj.push({
                            reverse: 'on'
                        });
                        printObj.push({
                            text: geol.t('generic.lang_Pagernummer') + ": " + store.state.pos.gastro.pagerNo
                        });
                        printObj.push({
                            reverse: 'off'
                        });
                    }
                }


                printObj.push({
                    fontSize: 3
                });
                printObj.push({
                    reverse: 'on'
                });
                printObj.push({
                    text: (items[0].course != null ? items[0].course.name : "")
                });
                printObj.push({
                    fontSize: 2
                });
                printObj.push({
                    reverse: 'off'
                });

                printObj.push({
                    align: "left"
                });

                // --- PRINT ITEMS ---
                printObj.push({
                    text: "----------------------------"
                });

                items.forEach(function (item) {
                    // ------------ PREPARE OPTIONS -------------
                    let hideName = false;
                    if (printerOpts.hasOwnProperty("hideItemNames"))
                        if (parseInt(printerOpts.hideItemNames) === 1)
                            hideName = true;

                    let showEAN = false;
                    if (printerOpts.hasOwnProperty("showPLU"))
                        if (parseInt(printerOpts.showPLU) === 1)
                            showEAN = true;

                    let showItemPrice = false;
                    if (printerOpts.hasOwnProperty("showItemPrices"))
                        if (parseInt(printerOpts.showItemPrices) === 1)
                            showItemPrice = true;
                    // --------------------------------------------

                    // ------------ PREPARE NAME ----------------
                    let itemName = "";
                    let translatedNameData = ""; // A VAR THAT STORES SOME APPLIED SETTINGS TO SHOW LATER LIKE DEPOSIT, MEALSIZE, OTHER SETTINGS ETC..., IN CASE WE RE USING TRANSLATION
                    // CHECK IF NEED TO PRINT ITEM NAME
                    if (!hideName) {
                        itemName += item.name + " ";

                        // CHECK MEALSIZE
                        if (item.hasOwnProperty("mealSizeID")) {
                            if (item.mealSizeID > 0) {
                                // GET MEALSIZE
                                let mealsize = store.state["mealsizes"].mealSizes.find((mealsize) => mealsize.id === item.mealSizeID);

                                // APPEND MEALSIZE NAME
                                if (mealsize) {
                                    itemName += mealsize.name + " ";
                                    translatedNameData += mealsize.name + " ";
                                }
                            }
                        }

                        if (showEAN) {
                            itemName += "\n";
                            translatedNameData += "\n";
                        }
                    }

                    // CHECK IF NEED TO PRINT ITEM EAN
                    if (showEAN) {
                        itemName += "#" + item.ean + " ";
                        translatedNameData += "#" + item.ean + " ";

                        if (showItemPrice) {
                            itemName += "\n";
                            translatedNameData += "\n";
                        }
                    }

                    // CHECK IF NEED TO PRINT ITEM PRICE
                    if (printerOpts.hasOwnProperty("showItemPrices")) {
                        if (parseInt(printerOpts.showItemPrices) === 1) {
                            // CALC SELL PRICE
                            // CHECK IF DEPOSIT
                            let itemSellPrice = 0;

                            if (item.hasOwnProperty("depositgroupID")) {
                                if (item.depositgroupID > 0) {
                                    let deposit = store.state.deposit.deposits.find((depositGroup) => {
                                        return depositGroup.id === item.depositgroupID;
                                    });

                                    if (deposit)
                                        itemSellPrice = parseFloat(deposit.priceTotal);
                                }
                            }

                            let discount = 0;

                            if (item.hasOwnProperty("discount") && item.discount)
                                discount = item.discount;

                            itemSellPrice += parseFloat((parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));

                            // CALC EXTRAS
                            /* if (item.selectedExtras.length > 0) {
                               item.selectedExtras.forEach((extra) => {
                                 itemSellPrice += (extra.amount * extra.sellPrice);
                               });
                             }*/

                            itemName += Vue.options.filters.currency(itemSellPrice);
                            translatedNameData += Vue.options.filters.currency(itemSellPrice);
                        }
                    }

                    // ---------------------------

                    // PRINT NAME
                    if(item.itemOrderingNameTranslationCanvas && enableOrderbonTranslations){
                        if(!hideName)
                            if(printingTranslationType ===1){
                                printObj.push({
                                    image:item.itemOrderingNameTranslationCanvas,
                                });
                            }else{
                                printObj.push({
                                    text: (item.amount.toString() + "X ").substr(0, 3).padEnd(3, ' ') + item.itemOrderingNameTranslation
                                });
                            }

                        if(hideName)
                            translatedNameData = (item.amount.toString() + "X ")+' '+translatedNameData;

                        printObj.push({
                            text: translatedNameData
                        });
                    }else{
                        printObj.push({
                            text: (item.amount.toString() + "X ").substr(0, 3).padEnd(3, ' ') + itemName
                        });
                    }


                        //EXTRAS
                    if (item.selectedExtras.length > 0) {
                        printObj.push({
                            fontSize: 2
                        });

                        item.selectedExtras.forEach((extra) => {
                            printObj.push({
                                text: ("+").substr(0, 2).padEnd(2, ' ') + ("  " + extra.amount.toString() + "X ").substr(0, 3).padEnd(5, ' ') + extra.name.substr(0, 20).padEnd(20, ' ')
                            });

                            if (extra.name.length > 20) {
                                printObj.push({
                                    text: "    " + extra.name.substr(20, 80)
                                });
                            }
                        });
                    }

                    // CHECK FREETEXT
                    if (item.freeText !== null)
                        if (item.freeText.length > 0) {
                            printObj.push({
                                text: " -> " + item.freeText + "\n"
                            });
                        }

                    printObj.push({
                        text: "----------------------------"
                    });
                });




                if (coursesOnOneOrderbon === false || (coursesOnOneOrderbon === true && coursesOnOneOrderbonCnt == lengthOfCoursePrinting)) {

                    // FREETEXT
                    if (globalFreetext !== null)
                        if (globalFreetext.length > 3) {
                            printObj.push({
                                feed: 1
                            });

                            printObj.push({
                                fontSize: 2
                            });

                            printObj.push({
                                text: globalFreetext
                            });

                            printObj.push({
                                feed: 1
                            });
                        }

                    printObj.push({
                        feed: 2
                    });

                    printObj.push({
                        font: "B"
                    });

                    printObj.push({
                        fontSize: 1
                    });

                    printObj.push({
                        text: geol.t('generic.lang_date') + " / " + geol.t('generic.lang_time') + ": " + dd + "." + mm + "." + yyyy + " - " + hours + ":" + minutes
                    });

                    //CHECK IF WAITER NAME SHOULD BE PRINTED
                    let waiterPrintText = geol.t('generic.lang_waiter') + ': ' + data.userID;

                    if (printerOpts.hasOwnProperty("showWaiterName"))
                        if (parseInt(printerOpts.showWaiterName) === 1)
                            waiterPrintText = geol.t('generic.lang_waiter') + ': ' + activeUser.name + " " + activeUser.last_name + " (" + activeUser.userID + ")";

                    printObj.push({
                        text: waiterPrintText
                    });
                    // CHECK IF WE SHOULD PRINT DELIVERY TIME IN CASE OF DELIVERY ORDER
                    if (deliveryService || store.state.pos.gastro.isDeliveryService) {
                        if (data.isDelivery) {
                            printObj.push({
                                text: geol.t('generic.lang_drivingTime') + ': ' + data.deliveryTime + ' min'
                            });
                        }
                    }
                    printObj.push({
                        feed: 2
                    });

                    printObj.push({
                        cut: true
                    });
                }

            });
            //END LAYOUT A
        } else {
            //LAYOUT B
            itemsCoursPrint.forEach(function (items) {
                printObj.push({
                    feed: 5
                });

                //ORDER QR CODE FOR PICKUP
                if(data.hasOwnProperty("orderUUID")){
                    printObj.push({align: "center"});
                    printObj.push({qrCode: 'ORD$'+data.orderUUID});
                }

                printObj.push({
                    lineSpace: 30
                });

                printObj.push({
                    font: "B"
                });

                if(isDup){
                    printObj.push({
                        font: "B"
                    });
                    printObj.push({
                        fontSize: 3
                    });
                    printObj.push({
                        reverse: 'on'
                    });
                    printObj.push({
                        align: "center"
                    });
                    printObj.push({
                        text: geol.t('generic.lang_duplicate').toUpperCase()
                    });
                    printObj.push({
                        reverse: 'off'
                    });
                }else if(isArchive){
                    printObj.push({
                        font: "B"
                    });
                    printObj.push({
                        fontSize: 3
                    });
                    printObj.push({
                        reverse: 'on'
                    });
                    printObj.push({
                        align: "center"
                    });
                    printObj.push({
                        text: geol.t('generic.lang_archive')
                    });
                    printObj.push({
                        reverse: 'off'
                    });
                }

                printObj.push({
                    fontSize: 3
                });

                printObj.push({
                    align: "center"
                });

                //CHECK IF CASHIER ALIAS NAME SHOULD BE SHOWN
                if (printerOpts.hasOwnProperty("showCashierAlias"))
                    if (parseInt(printerOpts.showCashierAlias) === 1) {
                        //GET CASHIER ALIAS NAME
                        let cashier_alias = store.getters['api/auth/cashierAliasName'];

                        printObj.push({
                            text: cashier_alias
                        });
                    }
                if ((deliveryService || store.state.pos.gastro.isDeliveryService) && data.hasOwnProperty('isAsap')) {
                    let tmp = '';
                    printObj.push({
                        fontSize: 4
                    });
                    printObj.push({
                        feed: 1
                    });
                    if(!data.isAsap)
                        tmp = ' ' + geol.t('datatables.lang_dtNext');

                    if (data.isDelivery)
                        tmp = data.zip + tmp;

                    if(tmp.length>0)
                        printObj.push({
                            text: tmp,
                        });
                } else if (data.isDelivery) {
                    // show zip code in the top of
                    printObj.push({
                        fontSize: 2
                    });

                    printObj.push({
                        text: data.zip
                    });
                }


                printObj.push({
                    reverse: 'on'
                });
                if (!(deliveryService || store.state.pos.gastro.isDeliveryService)) {
                    printObj.push({
                        text: (data.table === 0 ? '  ' + geol.t('generic.lang_table_Tresenverkau') + '  ' : geol.t('generic.lang_table') + ' ' + data.table + "  ")
                    });
                    if (data.table !== 0) {
                        printObj.push({
                            text: (" " + data.party + " ")
                        });
                    }
                }

                // CHECK IF DELIVERY SERVICE
                if (deliveryService || store.state.pos.gastro.isDeliveryService) {
                    printObj.push({
                        fontSize: 4
                    });
                    printObj.push({
                        text: (data.isDelivery ? ' ' + geol.t('delivery.lang_delivery') + ' ' : ' ' + geol.t('delivery.lang_pickup') + ' ')
                    });
                    printObj.push({
                        feed: 1
                    });
                    printObj.push({
                        fontSize: 2
                    });
                    if (data.hasOwnProperty('serviceTime'))
                        printObj.push({
                            text: geol.t('generic.lang_time')+" : " + data.serviceTime,
                        });
                    printObj.push({
                        feed: 1
                    });
                }


                if ((data.table === 0 && pickUpNo.toString().length > 0 && store.state.settings.settings.pickup_enable > 0 && store.state.pos.gastro.pagerNo === null) || (deliveryService || store.state.pos.gastro.isDeliveryService)) {
                    printObj.push({
                        text: geol.t('generic.lang_pickUpNumber') + ': ' + pickUpNo
                    });
                }

                printObj.push({
                    fontSize: 3
                });

                //COURSE NAME WAS PRINTED HERE
                //printObj.push({text: (items[0].course != null ? items[0].course.name : "")});

                printObj.push({
                    fontSize: 2
                });
                printObj.push({
                    reverse: 'off'
                });

                // AUSSER HAUS
                if (store.state.pos.gastro.hasOwnProperty("takeAway"))
                    if (store.state.pos.gastro.takeAway === 1) {
                        printObj.push({
                            text: geol.t('generic.lang_offSiteSale')
                        });
                    }

                // PAGER NUMBER
                if (store.state.pos.gastro.pagerNo !== null) {
                    printObj.push({
                        reverse: 'on'
                    });
                    printObj.push({
                        text: geol.t('generic.lang_pagerNo') + " " + store.state.pos.gastro.pagerNo
                    });
                    printObj.push({
                        reverse: 'off'
                    });
                }

                printObj.push({
                    feed: 1
                });

                // --- PRINT COURSE NAME ---
                printObj.push({
                    text: (items[0].course != null ? items[0].course.name : "")
                });

                printObj.push({
                    align: "left"
                });
                // --- PRINT ITEMS ---
                printObj.push({
                    text: "----------------------------"
                });

                items.forEach(function (item) {
                    // ------------ PREPARE OPTIONS -------------
                    let hideName = false;
                    if (printerOpts.hasOwnProperty("hideItemNames"))
                        if (parseInt(printerOpts.hideItemNames) === 1)
                            hideName = true;

                    let showEAN = false;
                    if (printerOpts.hasOwnProperty("showPLU"))
                        if (parseInt(printerOpts.showPLU) === 1)
                            showEAN = true;

                    let showItemPrice = false;
                    if (printerOpts.hasOwnProperty("showItemPrices"))
                        if (parseInt(printerOpts.showItemPrices) === 1)
                            showItemPrice = true;
                    // --------------------------------------------

                    // ------------ PREPARE NAME ----------------
                    let itemName = "";
                    let translatedNameData = "";// A VAR THAT STORES SOME APPLIED SETTINGS TO SHOW LATER LIKE DEPOSIT, MEALSIZE, OTHER SETTINGS ETC..., IN CASE WE RE USING TRANSLATION

                    // CHECK IF NEED TO PRINT ITEM NAME
                    if (!hideName) {
                        itemName += item.name + " ";

                        // CHECK MEALSIZE
                        if (item.hasOwnProperty("mealSizeID")) {
                            if (item.mealSizeID > 0) {
                                // GET MEALSIZE
                                let mealsize = store.state["mealsizes"].mealSizes.find((mealsize) => mealsize.id === item.mealSizeID);

                                // APPEND MEALSIZE NAME
                                if (mealsize) {
                                    itemName += mealsize.name + " ";
                                    translatedNameData += "\n"+mealsize.name + " ";
                                }
                            }
                        }

                        if (showEAN) {
                            itemName += "\n";
                            translatedNameData += "\n";
                        }
                    }

                    // CHECK IF NEED TO PRINT ITEM EAN
                    if (showEAN) {
                        itemName += "#" + item.ean + " ";
                        translatedNameData += "#" + item.ean + " ";

                        if (showItemPrice) {
                            itemName += "\n";
                            translatedNameData += "\n";
                        }
                    }

                    // CHECK IF NEED TO PRINT ITEM PRICE
                    if (printerOpts.hasOwnProperty("showItemPrices")) {
                        if (parseInt(printerOpts.showItemPrices) === 1) {
                            // CALC SELL PRICE
                            // CHECK IF DEPOSIT
                            let itemSellPrice = 0;

                            if (item.hasOwnProperty("depositgroupID")) {
                                if (item.depositgroupID > 0) {
                                    let deposit = store.state.deposit.deposits.find((depositGroup) => {
                                        return depositGroup.id === item.depositgroupID;
                                    });

                                    if (deposit)
                                        itemSellPrice = parseFloat(deposit.priceTotal);
                                }
                            }

                            let discount = 0;

                            if (item.hasOwnProperty("discount") && item.discount)
                                discount = item.discount;

                            itemSellPrice += parseFloat((parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2))).toFixed(2));

                            // CALC EXTRAS
                            /* if (item.selectedExtras.length > 0) {
                               item.selectedExtras.forEach((extra) => {
                                 itemSellPrice += (extra.amount * extra.sellPrice);
                               });
                             }*/

                            itemName += Vue.options.filters.currency(itemSellPrice);
                            translatedNameData += Vue.options.filters.currency(itemSellPrice);
                        }
                    }

                    // ---------------------------

                    // PRINT NAME
                    if(item.itemOrderingNameTranslationCanvas && enableOrderbonTranslations){
                        if(!hideName)
                            if(printingTranslationType ===1){
                                printObj.push({
                                    text:(item.amount.toString() + "X "),
                                });
                                printObj.push({
                                    image:item.itemOrderingNameTranslationCanvas,
                                });
                            }else{
                                printObj.push({
                                    text: (item.amount.toString() + "X ").substr(0, 3).padEnd(3, ' ') + item.itemOrderingNameTranslation
                                });
                            }

                        if(hideName)
                           translatedNameData = (item.amount.toString() + "X ")+' '+translatedNameData;

                        printObj.push({
                            text: translatedNameData
                        });
                    }else{
                        printObj.push({
                            text: (item.amount.toString() + "X ").substr(0, 3).padEnd(3, ' ') + itemName
                        });
                    }

                    //EXTRAS
                    if (item.selectedExtras.length > 0) {
                        printObj.push({
                            fontSize: 2
                        });
                        let extraTabs = [];
                        item.selectedExtras.forEach((extra) => {

                            // PRINT EXTRA TAB NAME
                            printObj.push({
                                reverse: 'on'
                            });

                            let extraTab = store.state.extratabs.extraTabs.find(extraTab => extraTab.id === extra.extraTabID)

                            if (extraTab) {
                                let isDup = extraTabs.find(tab => tab.id === extraTab.id);
                                if (!isDup) {
                                    extraTabs.push(extraTab)
                                    printObj.push({
                                        text: extraTab.name
                                    });
                                }
                            }

                            printObj.push({
                                reverse: 'off'
                            });

                            printObj.push({
                                text: ("+").substr(0, 2).padEnd(2, ' ') + ("  " + extra.amount.toString() + "X ").substr(0, 3).padEnd(5, ' ') + extra.name.substr(0, 20).padEnd(20, ' ')
                            });

                            if (extra.name.length > 20) {
                                printObj.push({
                                    text: "    " + extra.name.substr(20, 80)
                                });
                            }
                        });
                    }

                    // CHECK FREETEXT
                    if (item.freeText !== null)
                        if (item.freeText.length > 0) {
                            printObj.push({
                                text: " -> " + item.freeText
                            });
                        }
                    printObj.push({
                        feed: 1
                    });

                });

                printObj.push({
                    text: "----------------------------"
                });

// FREETEXT
                if (globalFreetext !== null)
                    if (globalFreetext.length > 3) {
                        printObj.push({
                            feed: 1
                        });

                        printObj.push({
                            fontSize: 1
                        });

                        printObj.push({
                            text: globalFreetext
                        });

                        printObj.push({
                            feed: 1
                        });
                    }

                printObj.push({
                    feed: 1
                });

                printObj.push({
                    font: "B"
                });

                printObj.push({
                    fontSize: 1
                });

                printObj.push({
                    text: geol.t('generic.lang_date') + " / " + geol.t('generic.lang_time') + ": " + dd + "." + mm + "." + yyyy + " - " + hours + ":" + minutes
                });

//CHECK IF WAITER NAME SHOULD BE PRINTED
                let waiterPrintText = geol.t('generic.lang_waiter') + ": " + data.userID;

                if (printerOpts.hasOwnProperty("showWaiterName"))
                    if (parseInt(printerOpts.showWaiterName) === 1)
                        waiterPrintText = geol.t('generic.lang_waiter') + ": " + activeUser.name + " " + activeUser.last_name + " (" + activeUser.userID + ")";

                printObj.push({
                    text: waiterPrintText
                });
                // CHECK IF WE SHOULD PRINT DELIVERY TIME IN CASE OF DELIVERY ORDER
                if (deliveryService || store.state.pos.gastro.isDeliveryService) {
                    if (data.isDelivery) {
                        printObj.push({
                            text: geol.t('generic.lang_drivingTime') + ': ' + data.deliveryTime + ' min'
                        });
                    }
                }
                printObj.push({
                    feed: 2
                });

                printObj.push({
                    cut: true
                });

            });


            //END LAYOUT B

        }

    });


    if (printWaitersBons && !isDup) {
        items.forEach(function (item) {

            for (let i = 0; i < item.amount; i++) {
                printObj.push({
                    font: "B"
                });
                printObj.push({
                    fontSize: 2
                });

                printObj.push({
                    align: "center"
                });

                printObj.push({
                    reverse: 'on'
                });

                if (!(deliveryService || store.state.pos.gastro.isDeliveryService)) {
                    printObj.push({
                        text: (data.table === 0 ? '  ' + geol.t('generic.lang_table_Tresenverkau') + '  ' : "  " + geol.t('generic.lang_table') + " " + data.table + "  ")
                    });
                }


                printObj.push({
                    fontSize: 1
                });

                if (data.table !== 0) {
                    printObj.push({
                        text: (" " + data.party + " ")
                    });
                }
                //CHECK IF WAITER NAME SHOULD BE PRINTED
                let waiterPrintText = geol.t('generic.lang_waiter') + ": " + data.userID;

                if (printerOpts.hasOwnProperty("showWaiterName"))
                    if (parseInt(printerOpts.showWaiterName) === 1)
                        waiterPrintText = geol.t('generic.lang_waiter') + ": " + activeUser.name + " " + activeUser.last_name + " (" + activeUser.userID + ")";

                printObj.push({
                    text: waiterPrintText
                });
                printObj.push({
                    reverse: 'off'
                });
                printObj.push({
                    fontSize: 2
                });
                printObj.push({
                    text: "----------------------------"
                });
                // ------------ PREPARE NAME ----------------
                let itemName = "";
                itemName += item.name + " ";

                // CHECK MEALSIZE
                if (item.hasOwnProperty("mealSizeID")) {
                    if (item.mealSizeID > 0) {
                        // GET MEALSIZE
                        let mealsize = store.state["mealsizes"].mealSizes.find((mealsize) => mealsize.id === item.mealSizeID);

                        // APPEND MEALSIZE NAME
                        if (mealsize)
                            itemName += mealsize.name + " ";
                    }
                }
                // ---------------------------

                // PRINT NAME
                if(item.itemOrderingNameTranslationCanvas && enableOrderbonTranslations && printingTranslationType ===1){
                    printObj.push({
                        text:(item.amount.toString() + "X "),
                    });
                    printObj.push({
                        image:item.itemOrderingNameTranslationCanvas,
                    });
                }else{

                    printObj.push({
                        text: (1 + "X ").substr(0, 3).padEnd(3, ' ') + itemName
                    });
                }
                printObj.push({
                    align: "left"
                });
                if (item.selectedExtras.length > 0) {
                    printObj.push({
                        fontSize: 1
                    });
                    let extraTabs = [];
                    item.selectedExtras.forEach((extra) => {

                        // PRINT EXTRA TAB NAME
                        printObj.push({
                            reverse: 'on'
                        });

                        let extraTab = store.state.extratabs.extraTabs.find(extraTab => extraTab.id === extra.extraTabID)

                        if (extraTab) {
                            let isDup = extraTabs.find(tab => tab.id === extraTab.id);
                            if (!isDup) {
                                extraTabs.push(extraTab)
                                printObj.push({
                                    text: extraTab.name
                                });
                            }
                        }

                        printObj.push({
                            reverse: 'off'
                        });

                        printObj.push({
                            text: ("+").substr(0, 2).padEnd(2, ' ') + ("  " + extra.amount.toString() + "X ").substr(0, 3).padEnd(5, ' ') + extra.name.substr(0, 20).padEnd(20, ' ')
                        });

                        if (extra.name.length > 20) {
                            printObj.push({
                                text: "    " + extra.name.substr(20, 80)
                            });
                        }
                    });
                }
                printObj.push({
                    fontSize: 1
                });
                printObj.push({
                    feed: 1
                });

                printObj.push({
                    cut: true
                });
            }

        });
    }
    return printObj;
}
