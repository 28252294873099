import axios from "axios";
import {ENDPOINTS} from "../../config";
import eposClass from "../ePosClass";
import {Events} from "@/plugins/events";

export function printDataFromPrinter(printer,printObj,deviceID="local_printer") {
    return new Promise((resolve, reject) => {
        if(printer && printer.api === "epos") {
            let epos = new eposClass();
            epos.printXML(printObj, printer.address, deviceID, printer.options.sslEnabled).then(() => {
                 resolve();
            }).catch((err) => {
                 reject(err);
            });
        }else if(printer && printer.api === "escpos"){
            //PRINT ON ESC POS CAPABLE PRINTER OVER HYBRID SERVER
            axios.post(ENDPOINTS.POS.ESCPRINT.CREATE, {
                printObj: printObj,
                printerId: printer.id,
                ipAddress: printer.address
            }).then((res) => {
                if (res.data.status === 'SUCCESS') {
                     resolve();
                } else {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }
            }).catch((err) => {
                 reject(err);
            });
        }else if (printer && printer.api === "escposexternal") {
            let axiosData = {
                "payload": printObj,
                "interface": printer.externalESCType === "usb" ? 'USB' : 'NETWORK',
                "ip": printer.externalESCType === "usb" ? '' : printer.address,
                "hash": "jmfkldhfkls"
            };
            if (printer.externalESCType === "usb") {
                axiosData.usbId1 = printer.usbId1;
                axiosData.usbId2 = printer.usbId2;
            }
            let config = {
                method: 'post',
                url: 'http://' + printer.serverAddress + ':3089/print',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: axiosData
            };

            let axiosPrint = axios.create();
            axiosPrint(config)
                .then( (res)=> {
                     resolve();
                })
                .catch((err)=> {
                     reject(err);
                })
        }else{
             reject();
        }
    });
}