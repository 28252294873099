import {Events} from "@/plugins/events";
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {createPaperlesInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {ENDPOINTS} from "@/config";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";
import {geol} from "../../../main";
// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
// we re gonna use this work arround for now until we find a better way
if (store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}

export default {
    computed: {
        transactionPayload() {
            if (this.posType === "retail")
                return this.pos.retail.transactionPayload;

            if (this.posType === "gastro")
                return this.pos.gastro.transactionPayload;

            return null;
        }
    },

    methods: {
        checkTSEPrint() {
            let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

            //TSE NOT READY
            if (!tseDevice.tseReady) {
                this.showErrorSnackBox(this.$t('generic.lang_tseIsNotOperational'), "print_without_tse");
                return;
            }

            // CHECK IF WE HAVE TRANSACTION
            if (Object.keys(this.transactionPayload).length > 0) {
                //TRY TO FINISH TRANSACTION AND WAIT FOR PROMISE
                this.finishTransaction(tseDevice).then((payload) => {
                    this.printWithTSE(payload);
                }).catch((err) => {
                    this.loading = false;
                    this.showErrorSnackBox(err, "print_without_tse");
                })
            } else {
                //TRANSACTION NOT STARTED! START NOW...
                //START TRANSACTION
                this.startTransaction(tseDevice).then(() => {
                    //TRY TO FINISH THE STARTED TRANSACTION AGAIN
                    tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, this.transactionPayload, this.invoiceItemsToFiscal(), this.selectedPaymentType, this.activeUserID, this.splitPayments);

                    Events.$once("GFE_OnReceive", (payload) => {
                        if (payload.result.function === "FinishTransaction") {
                            if (payload.result.result === "EXECUTION_OK") {
                                this.printWithTSE(payload);
                            } else {
                                this.loading = false;

                                this.showErrorSnackBox(this.$t('generic.lang_transactionCouldNotBeCompleted') + " (" + payload.result.result + ")", "print_without_tse");
                            }
                        }
                    });
                }).catch((err) => {
                    this.loading = false;

                    this.showErrorSnackBox(err, "print_without_tse");
                });
            }

        },
        invoiceItemsToFiscal() {

            let voucherLeftToPay = this.goodsValue;
            let items = this._.cloneDeep(this.invoiceItems);

            items.forEach((invoiceItem) => {
                //CHECK IF WE HAVE CHOOSEN TAKE AWAY
                if (this.offSite > 0) {
                    if (invoiceItem.takeAwayTaxing > 0) {
                        invoiceItem.taxValue = this.offSiteTax;
                    }
                }

                //CHECK IF VOUCHER VALUE IS GREATER THEN TOTAL VALUE
                if ((invoiceItem.ean.includes("GF$") || invoiceItem.ean.includes("V$") || invoiceItem.ean.includes("ZMYLE$")) && invoiceItem.sellPrice < 0) {
                    let voucherValue = invoiceItem.sellPrice * -1;

                    if (voucherLeftToPay > 0) {
                        if (voucherValue >= voucherLeftToPay) {
                            voucherValue = voucherLeftToPay;
                            voucherLeftToPay = 0;
                        } else {
                            voucherLeftToPay -= voucherValue;
                        }
                    }

                    voucherValue = voucherValue * -1;
                    invoiceItem.sellPrice = voucherValue;
                }

            });


            return items;
        },

        finishTransaction(tseDevice) {
            return new Promise((resolve, reject) => {
                tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, this.transactionPayload, this.invoiceItemsToFiscal(), this.selectedPaymentType, this.activeUserID, this.splitPayments);

                Events.$once("GFE_OnReceive", (payload) => {
                    if (payload.result.function === "FinishTransaction") {
                        if (payload.result.result === "EXECUTION_OK") {
                            resolve(payload);
                        } else if (payload.result.result === "TSE1_ERROR_TRANSACTION_NOT_STARTED" || payload.result.result === "TSE1_ERROR:_") {
                            //TRANSACTION NOT STARTED! START NOW...
                            //START TRANSACTION
                            this.startTransaction(tseDevice).then(() => {
                                //TRY TO FINISH THE STARTED TRANSACTION AGAIN
                                tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, this.transactionPayload, this.invoiceItemsToFiscal(), this.selectedPaymentType, this.activeUserID, this.splitPayments);

                                Events.$once("GFE_OnReceive", (payload) => {
                                    if (payload.result.function === "FinishTransaction") {
                                        if (payload.result.result === "EXECUTION_OK") {
                                            resolve(payload);
                                        } else {
                                            reject(this.$t('generic.lang_transactionCouldNotBeCompleted') + " (" + payload.result.result + ")");
                                        }
                                    }
                                });
                            }).catch((err) => {
                                reject(err);
                            });
                        } else {
                            reject(this.$t('generic.lang_transactionCouldNotBeCompleted') + " (" + payload.result.result + ")");
                        }
                    } else {
                        reject(this.$t('generic.lang_incorrectResponseFromTheTSE') + " (" + payload.result.function + ")");
                    }
                });

                //REJECT WITH TIMEOUT AFTER 10 SECONDS
                setTimeout(() => {
                    reject("Timeout");
                }, 10000);
            });
        },
        startTransaction(tseDevice) {
            return new Promise((resolve, reject) => {
                tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

                Events.$once("GFE_OnReceive", (payload) => {
                    if (payload.result.function === "StartTransaction") {
                        if (payload.result.result === "EXECUTION_OK") {
                            if (this.posType === "retail") {
                                this.$store.dispatch("pos/retail/setTransactionPayload", payload.result.output).then(() => {
                                    resolve();
                                });
                            }

                            if (this.posType === "gastro") {
                                this.$store.dispatch("pos/gastro/setTransactionPayload", payload.result.output).then(() => {
                                    resolve();
                                });
                            }
                        } else {
                            reject(this.$t('generic.lang_transactionCouldNotBeStarted') + " (" + payload.result.result + ")");
                        }
                    }
                });

                //REJECT WITH TIMEOUT AFTER 10 SECONDS
                setTimeout(() => {
                    reject("Timeout");
                }, 10000);
            });
        },
        async printWithTSE(payload) {
            let result = payload.result;
            let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

            //GENERATE INVOICE DATA
            let data = {
                saleID: this.invoiceResultData.invoiceID,
                cashierID: this.invoiceResultData.cashierID,
                userID: this.invoiceResultData.userID,
                ts: this.invoiceResultData.ts,
                moneyGiven: this.moneyGiven, //this.invoiceResultData.moneyGiven,
                paymentType: this.invoiceResultData.paymentType,
                splitPayments: this.splitPayments,
                invoiceUUID:this.invoiceResultData.invoiceUUID,
            };

            if (this.posType === "gastro") {
                data["tableNo"] = this.pos.gastro.table.name;
            }

            if (this.posType === "retail") {
                data["tableNo"] = "";
            }

            let printData;
            let paperlessInvoicePrintData;
            let qrCode;

            try {
                if(this.$store.getters['permissions/checkModule'](62)
                    &&Number(this.settings.settings.enablePaperlessInvoice)===1
                    &&Number(this.settings.settings.printSmallReceipt)===1
                    &&!this.pos.gastro.isDeliveryService
                    &&Number(this.settings.settings.disableReceiptPrint)===0
                    && this.paperlessInvoiceCashiers?.includes(this.api.auth.cashierID)
                ){
                    paperlessInvoicePrintData = createPaperlesInvoicePrintingData(this.invoiceItemsToPrint,data, this.invoicePrinter.options, this.realUser, false);
                }

                printData = createInvoicePrintingData(this.invoiceItemsToPrint, data, this.invoicePrinter.options, this.realUser, false, false, this.goodsValue, this.vouchersValue);

                //BEWIRTUNGSBELEG
                if (this.bewirtungsbeleg) {
                    let today = new Date();
                    let current_date = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear();
                    let current_city = "";

                    if (this.settings.settings.hasOwnProperty("systemCity"))
                        current_city = this.settings.settings.systemCity;

                    printData.push({
                        text: "\n-----------------------------------\n" + geol.t('generic.lang_hospitalityExpensesInformationAccordingTo') + " \n" + geol.t('generic.lang_paragraph4Paragraph5Number2EStG') + " \n-----------------------------------\n" + geol.t('generic.lang_hostedPersons') + ":\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n\n" + geol.t('generic.lang_occasionOfTheHospitality') + ":\n...................................\n\n...................................\n\n\n" + geol.t('generic.lang_amountOfExpenditure') + ":\n\n...................................\n" + geol.t('generic.lang_whenCateringInTheRestaurant') + "\n\n...................................\n" + geol.t('generic.lang_inOtherCases') + "\n\n\n" + geol.t('generic.lang_date_place') + ':' + current_date + " / " + current_city + "\n\n...................................\n" + geol.t('generic.lang_signature') + "\n\n\n\n"
                    });

                    //printData.push({
                     //   text: "\nAngaben zum Nachweis der Hoehe und der \nbetrieblichen Veranlassung von \nBewirtungsaufwendungen Par. 4 Abs. 5\nTag der Bewirtung:" + current_date + "\nBewirtete Personen:\n\n\n\n\n\n\n\nAnlass der Bewirtung:\n\n\n\n\nDatum / Ort:" + current_date + " / " + current_city + "\nUnterschrift:\n\n\n\n"
                    //});
                }


                // CHECK IF CASH DRAWER SHOULD NOT BE OPENED WHEN PAYING WITH EC
                if (this.disableGlobalCashDrawer || (this.selectedPaymentType === 3 || this.selectedPaymentType === 9) && parseInt(this.$store.getters['settings/getSettingValue']('ecPaymentOpenCashDrawer')) === 0) {
                    // THEN GET INDEX OF OPENCASHDRAWER ELEMENT AND REMOVE IT
                    let cashDrawerElementIndex = printData.findIndex((el) => {
                        return el.hasOwnProperty("cashDrawer");
                    })

                    // REMOVE OPEN CASH DRAWER ELEMENT
                    this.$delete(printData, cashDrawerElementIndex);
                }


                //FIND LAST CUT OF PRINT DATA(INDEX)
                let lastCutIndex = null;

                printData.forEach((element, index) => {
                    if (element.hasOwnProperty("cut"))
                        if (element.cut)
                            lastCutIndex = index;
                });

                //DELETE LAST CUT, BECAUSE WE WILL ADD CUT AFTER TSE DATA
                if (lastCutIndex !== null)
                    this.$delete(printData, lastCutIndex);

                printData.push({
                    fontSize: 1
                });

                printData.push({
                    font: "B"
                });

                if (this.posType === "gastro" && this.invoiceResultData !== null && this.pos.gastro.table.name !== 0) {
                    printData.push({
                        text: "1. " + geol.t('generic.lang_best') + ".: " + this.invoiceResultData.firstOrder
                    });
                }

                printData.push({
                    text: geol.t('accounting.lang_eazSerNr') + ": " + this.api.auth.posID + " - " + this.api.auth.cashierID
                });
                printData.push({
                    text: geol.t('accounting.lang_tseSerNr') + ": " + tseDevice.storageInfo.tseInformation.serialNumber
                });
                printData.push({
                    text: geol.t('accounting.lang_tseStart') + ": " + this.transactionPayload.logTime
                });
                printData.push({
                    text: geol.t('accounting.lang_tseStop') + ":  " + result.output.logTime
                });
                printData.push({
                    text: geol.t('accounting.lang_tseTrxNr') + ": " + this.transactionPayload.transactionNumber
                });
                printData.push({
                    text: geol.t('accounting.lang_tseSigCount') + ":" + result.output.signatureCounter
                });
                printData.push({
                    text: geol.t('accounting.lang_signatur') + ":  " + result.output.signature
                });

                qrCode = tseDevice.createInvoiceQRCode(this.invoiceItemsToFiscal(), this.selectedPaymentType, this.activeUserID, this.splitPayments, this.transactionPayload.transactionNumber, result.output.signatureCounter, this.transactionPayload.logTime, result.output.logTime, result.output.signature, this.fiscalClient.clientID);

                printData.push({
                    align: "center"
                });

                //ADD QR CODE
                printData.push({
                    qrCode: qrCode
                });

                printData.push({
                    cut: true
                });

                // ADD ZVT PRINTING
                printData = this.addZVTPrinting(printData);
                //SAVE PRINT DATA
                this.invoiceXMLPrintData = printData;
            } catch (error) {
                this.showErrorDialog(this.$t('generic.lang_printReceiptError'), false, "check_vouchers");
                return;
            }

            // PREPARE FISCAL SAVING PAYLOAD
            try {
                let type;

                if (this.activeUserID !== 101)
                    type = "Beleg";
                else
                    type = "AVTraining";

                let savePayload = {
                    fiscalDevice: {
                        id: this.fiscalClient.device.id,
                        deviceID: this.fiscalClient.device.deviceID
                    },
                    fiscalClient: {
                        id: this.fiscalClient.id,
                        clientID: this.fiscalClient.clientID
                    },
                    tse: {
                        serialNumber: tseDevice.storageInfo.tseInformation.serialNumber,
                    },
                    transaction: {
                        processType: "Kassenbeleg-V1",
                        type: type,
                        startTime: this.transactionPayload.logTime,
                        stopTime: result.output.logTime,
                        transactionNumber: this.transactionPayload.transactionNumber,
                        signatureCounter: result.output.signatureCounter,
                        signature: result.output.signature
                    },
                    qrCode: qrCode
                };

                if (this.posType === "gastro" && this.pos.gastro.table.name !== 0) {
                    savePayload.firstOrder = this.invoiceResultData.firstOrder;
                }

                await this.axios.post(ENDPOINTS.POS.SALE.UPDATETSEPAYLOAD, {
                    invoiceUUID: this.invoiceResultData.invoiceUUID,
                    tsePayload: savePayload
                });
            } catch (error) {
                console.log("cashoutTSEMixin: error saving tse fiscal data to invoice", error);
            }

            //paperless invoice settings check
            if(this.$store.getters['permissions/checkModule'](62)
                &&Number(this.settings.settings.enablePaperlessInvoice)===1
                &&Number(this.settings.settings.disableReceiptPrint)===1
                &&!this.pos.gastro.isDeliveryService
                && this.paperlessInvoiceCashiers?.includes(this.api.auth.cashierID)
            ){
                if(!(this.disableGlobalCashDrawer || (this.selectedPaymentType === 3 || this.selectedPaymentType === 9) && parseInt(this.$store.getters['settings/getSettingValue']('ecPaymentOpenCashDrawer')) === 0))
                    this.openCashDrawer();
                this.checkVoucher();
                return;
            }

            let dataToPrint;

            if(this.$store.getters['permissions/checkModule'](62)
                &&Number(this.settings.settings.enablePaperlessInvoice)===1
                &&Number(this.settings.settings.printSmallReceipt)===1
                &&!this.pos.gastro.isDeliveryService
                &&Number(this.settings.settings.disableReceiptPrint)===0
                && this.paperlessInvoiceCashiers?.includes(this.api.auth.cashierID)
            ){
                dataToPrint = paperlessInvoicePrintData
            }else{
                dataToPrint = printData;
            }

            // CHECKING CASHIER OPTIONS SETTINGS FOR DIRECT SALE RECEIPT PRINTING
            if(this.settings.settings.disableDirectSaleReceiptPrint==="1"&&this.posType === "gastro" && this.pos.gastro.table.name === 0){
                this.loadingPrinting = false;
                this.checkVoucher();
                return;
            }
            // EPSON EPOS PRINT
            let printerPromises = [printDataFromPrinter(this.invoicePrinter,dataToPrint)];
            if (this.pos.gastro.isDeliveryService && this.pos.gastro.delivery && Number(this.settings.settings.lb_enable_printing_twice) === 1) {
                printerPromises.push(printDataFromPrinter(this.invoicePrinter,printData))
            }

            Promise.all(printerPromises).then(() => {
                this.checkVoucher();
            }).catch((err) => {
                this.showErrorDialog(this.$t('generic.lang_printReceiptError') + '  (' + err + ')', false, "check_vouchers");
            }).finally(()=>{

            })
        },
    }
}
