import randomString from "randomstring";
import Vue from 'vue';
import {roundNumber} from "@/plugins/round";
import {format} from 'mathjs';

export default {
    namespaced: true,

    state: {
        customer: null,
        customerProject: null,
        orderedItems: [],
        selectedItem: null,
        savedOrderID: 0,
        transactionPayload: {},
        additionalStatisticUUIDs: [],

    },

    mutations: {
        setAdditionalStatistic(state, val) {
            state.additionalStatisticUUIDs = val;
        },
        setFreetext(state, text) {
            state.freeText = text;
        },
        bookItem(state, item) {
            //RESET SELECTED
            state.selectedItem = null;

            //CHECK IF ITEM ALREADY BOOKED
            const cartItem = state.orderedItems.find(cartItem => {
                if (cartItem.id === item.id) {
                    if (item.weight === null) {
                        if (cartItem.freeText === null) {
                            if (cartItem.originalSellPrice === cartItem.sellPrice) {
                                if (cartItem.sellPrice === item.sellPrice) {
                                    if (cartItem.discount === item.discount) {
                                        if (!cartItem.isVoid) {
                                            if (cartItem.batchNo === item.batchNo) {
                                                if (cartItem.bestBeforeDate === item.bestBeforeDate) {
                                                    if (cartItem.serialNo === item.serialNo) {
                                                        if (cartItem.individualBookingNo === item.individualBookingNo) {
                                                            if ((item.warenameOverwrite === null && cartItem.name === item.name) || (item.warenameOverwrite !== null && cartItem.name === item.warenameOverwrite)) {
                                                                return true;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return false;
            });

            if (!cartItem) {
                let item2 = {
                    amount: (isNaN(parseFloat(item.weight)) ? 1.00 : item.weight),
                    isWeighted: item.isWeighted,
                    scalePositionId: item.scalePositionId,
                    randomPositionID: randomString.generate(15),
                    id: item.id,
                    name: (item.warenameOverwrite !== null ? item.warenameOverwrite : item.name),
                    ean: item.ean,
                    course: state.selectedCourse,
                    icon: item.icon,
                    badgeColor: item.badgeColor,
                    sellPrice: item.sellPrice,
                    originalSellPrice: item.originalSellPrice,
                    selectedExtras: item.selectedExtras,
                    discount: item.discount,
                    mealSizeID: item.mealSizeID,
                    internalWareID: item.internalWareID,
                    itemgroupID: item.itemgroupID,
                    depositgroupID: item.depositgroupID,
                    isPriceChanged: item.originalSellPrice !== item.sellPrice,
                    isVoid: false,
                    needsVoidPrint: false,
                    voidReason: "",
                    freeText: null,
                    taxValue: item.taxValue,
                    takeAwayTaxing: item.takeAwayTaxing,
                    isVariation: item.isVariation,
                    variationColor: item.variationColor,
                    variationSize: item.variationSize,
                    bestBeforeDate: item.bestBeforeDate,
                    batchNo: item.batchNo,
                    serialNo: item.serialNo,
                    isMenuItem: item.isMenuItem,
                    menuTaxRules: item.menuTaxRules,
                    payOutItem: item.payOutItem,
                    additionalStatisticUUIDs: [],
                    individualBookingNo: item.individualBookingNo
                };

                Vue.set(state.orderedItems, state.orderedItems.length, item2);

                //SELECT ITEM
                this.commit("pos/retail/selectInvoiceItem", item2);
            } else {
                //cartItem.amount++;
                Vue.set(cartItem, 'amount', (cartItem.amount + 1));

                //SELECT ITEM
                this.commit("pos/retail/selectInvoiceItem", cartItem);
            }
        },
        selectInvoiceItem(state, item) {
            if (state.selectedItem !== item) {
                state.selectedItem = item;
            } else {
                state.selectedItem = null;
            }
        },
        setItems(state, data) {
            state.orderedItems = data.orders;
        },
        setCustomer(state, data) {
            state.customer = data;
        },
        setCustomerProject(state, data) {
            state.customerProject = data;
        },
        numpadQTY(state, data) {
            if (state.selectedItem !== null) {
                if (!state.selectedItem.isVoid && !state.selectedItem.ean.includes("V$") && !state.selectedItem.ean.includes("GF$") && !state.selectedItem.ean.includes("ZMYLE$") && !state.selectedItem.ean.includes("MYWORLD$")) {
                    state.selectedItem.amount = (data > 0 ? parseFloat(data.replace(",", ".")) : 0);
                }
            }
        },
        numpadPrice(state, data) {
            if (state.selectedItem !== null) {
                if (!state.selectedItem.isVoid && !state.selectedItem.ean.includes("V$") && !state.selectedItem.ean.includes("GF$") && !state.selectedItem.ean.includes("ZMYLE$") && !state.selectedItem.ean.includes("MYWORLD$")) {
                    //CHECK IF PAY OUT ITEM
                    if (state.selectedItem.payOutItem === 1)
                        state.selectedItem.sellPrice = parseFloat(data) * -1;
                    else
                        state.selectedItem.sellPrice = parseFloat(data);

                    if (state.selectedItem.sellPrice !== state.selectedItem.originalSellPrice) {
                        state.selectedItem.isPriceChanged = true;
                    } else {
                        state.selectedItem.isPriceChanged = false;
                    }
                }
            }
        },
        openSavedOrder(state, data) {
            state.savedOrderID = data.id;
        },
        reset(state) {
            state.selectedItem = null;
            state.freeText = null;
            state.customer = null;
            state.customerProject = null;
            state.orderedItems = [];
            state.savedOrderID = 0;
            state.transactionPayload = {};
        },
        setTransactionPayload(state, payload) {
            state.transactionPayload = payload;
        }
    },

    actions: {
        bookItem({commit}, data) {
            commit('bookItem', data);
        },
        selectInvoiceItem({commit}, data) {
            commit('selectInvoiceItem', data);
        },
        setItems({commit}, data) {
            commit('setItems', data);
        },
        setCustomer({commit}, data) {
            commit("setCustomer", data);
        },
        setCustomerProject({commit}, data) {
            commit("setCustomerProject", data);
        },
        numpadQTY({commit}, data) {
            commit("numpadQTY", data);
        },
        numpadPrice({commit}, data) {
            commit("numpadPrice", data);
        },
        openSavedOrder({commit}, data) {
            commit("openSavedOrder", data);
        },
        setTransactionPayload({commit}, data) {
            commit("setTransactionPayload", data);
        },
    },

    getters: {
        totalPrice: (state, getters, rootState) => {
            let total = 0;

            state.orderedItems.forEach((item) => {
                let itemPrice = 0;

                //SKIP IF VOIDED
                if (item.isVoid || item.amount === 0)
                    return;

                //CHECK DISCOUNT
                let discount = 0;
                if (item.hasOwnProperty("discount"))
                    discount = item.discount;

                //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
                itemPrice = parseFloat((((((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100)));

                itemPrice = format(itemPrice, {precision: 14});
                itemPrice = roundNumber(itemPrice, 2);

                //CHECK IF DEPOSIT
                if (item.hasOwnProperty("depositgroupID")) {
                    if (item.depositgroupID > 0) {
                        let deposit = rootState.deposit.deposits.find((depositGroup) => {
                            return depositGroup.id === item.depositgroupID;
                        });

                        if (deposit)
                            itemPrice += deposit.priceTotal;
                    }
                }

                let weightCalc = 0.00;
                weightCalc = parseFloat((itemPrice * item.amount));
                weightCalc = format(weightCalc, {precision: 14});
                weightCalc = roundNumber(weightCalc, 2);

                total += parseFloat((weightCalc));
                total = format(total, {precision: 14});
                total = roundNumber(total, 2);
            }, 0);

            return total;
        },
        goodsValue: (state, getters, rootState) => {
            let total = 0;

            state.orderedItems.forEach((item) => {
                let itemPrice = 0;

                //CHECK IF VOIDED
                if (item.isVoid || item.amount === 0)
                    return;

                if (item.sellPrice < 0 && (item.ean.includes("V$") || item.ean.includes("GF$") || item.ean.includes("ZMYLE$") || item.ean.includes("MYWORLD$")))
                    return;

                //CHECK DISCOUNT
                let discount = 0;
                if (item.hasOwnProperty("discount"))
                    discount = item.discount;

                //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
                itemPrice = parseFloat((((((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100)));
                itemPrice = format(itemPrice, {precision: 14});
                itemPrice = roundNumber(itemPrice, 2);
                //CHECK IF DEPOSIT
                if (item.hasOwnProperty("depositgroupID")) {
                    if (item.depositgroupID > 0) {
                        let deposit = rootState.deposit.deposits.find((depositGroup) => {
                            return depositGroup.id === item.depositgroupID;
                        });

                        if (deposit)
                            itemPrice += parseFloat(deposit.priceTotal);
                    }
                }

                let weightCalc = 0.00;
                weightCalc = parseFloat((itemPrice * item.amount));
                weightCalc = format(weightCalc, {precision: 14});
                weightCalc = roundNumber(weightCalc, 2);

                total += parseFloat((weightCalc).toFixed(2));
            }, 0);
            return parseFloat(total.toFixed(2));
        },
        selectedItem: (state) => {
            return state.selectedItem;
        }
    }
};
