<template>
  <div class="d-flex">
    <div class="header-btn-lg pr-0">
      <div class="widget-content p-0">
        <div class="widget-content-wrapper">
          <div class="widget-content-left">
            <b-dropdown menu-class="dropdown-menu-lg" no-caret right toggle-class="p-0 mr-2 mb-0"
                        variant="link">
                            <span slot="button-content">

                                <div class="widget-content-left" style="float:left;">
                                    <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                                        <img :src="userAvatar" alt="" class="rounded-circle bg-primary" width="42">
                                    </div>
                                </div>

                                <div class="white--text widget-content-right pt-3 text-decoration-none ml-3 header-user-info"
                                    style="float:right; margin-top: 3px;">
                                    <div class="widget-heading">{{ user.name }} {{ user.last_name }}</div>
                                </div>
                            </span>
              <div class="dropdown-menu-header mb-0" style="padding-left:0px !important;">
                <div class="dropdown-menu-header-inner bg-info"
                     style="margin: -1px -1px 0 !important; padding: 1.5rem .5rem !important; ">
                  <div class="menu-header-image opacity-2 dd-header-bg-6"></div>
                  <div class="menu-header-content text-left">
                    <div class="widget-content p-0">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left mr-3">
                          <img :src="userAvatar" alt="" class="rounded-circle bg-primary"
                               width="42">
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{ user.name }} {{ user.last_name }}
                          </div>
                        </div>
                        <div class="widget-content-right mr-2">
                          <button class="btn-pill btn-shadow btn-shine btn btn-focus"
                                  v-on:click="logout">{{ $t('generic.lang_logout') }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <v-list class="mat-0 pt-0 mb-0 pb-0">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-fullscreen</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t('generic.lang_toggleFullscreen') }}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-switch v-model="fullscreen"></v-switch>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item link to="/settings/basicSettings/changePassword">
                  <v-list-item-icon>
                    <v-icon>lock</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ this.$t('settings.lang_nav_settings_password') }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item>
                  <v-list-item-title>
                    {{ $t('generic.lang_LoggedInAtCashierID') }} {{ this.api.auth.cashierID }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Events} from "@/plugins/events";
import {mapState, mapGetters} from "vuex";
import {ENDPOINTS} from "@/config";

export default {
  data: () => ({
    name: "",
    last_name: "",
    fullscreen: "",
  }),

  computed: {
    ...mapState([
      'user',
      'api'
    ]),
    ...mapGetters({
      getFullscreen: 'user/getFullscreen'
    }),
    userAvatar: function () {
      if (typeof this.user.avatarURI !== 'undefined') {
        if (this.user.avatarURI.length > 60) {
          return this.user.avatarURI;
        } else {
          return require("@/assets/images/no_avatar.png");
        }
      } else {
        return require("@/assets/images/no_avatar.png");
      }
    }
  },
  watch: {
    fullscreen(val) {
      this.$store.commit('user/setFullscreen', val)
      if (val) {
        let elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen();
        }
      } else {
        if (this.fullscreen && document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }
      }
    }
  },
  mounted() {
    this.fullscreen = document.fullscreen;
  },
  methods: {
    logout() {
      this.axios.post('get/logout/').then((res) => {
        if (res.data.success) {
          this.finishLogout();
        } else {
          if (res.data.status === "already_loggedOut") {
            this.finishLogout();
          }
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_mistakeYouCouldNotBeLoggedOut')
        });
      });
    },
    finishLogout() {
      this.$store.dispatch("user/logout", {vm: this}).then(() => {
        Events.$emit("showSnackbar", {
          color: "success",
          message: this.$t('generic.lang_youHaveBeenSuccessfullyLoggedOut')
        });

        //REDIRECT
        this.$router.replace({
          name: "init"
        });
      });
    },
  }
}
</script>


<style scoped>
.v-list-item--link {
  text-decoration: none !important;
}

/* @media (min-width: 0px) and (max-width: 700px) {
  .dropdown-menu-header {
    padding-top: 190px;
  }
} */
.blink_me {
  animation: blinker 1.1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: .2;
  }
}


</style>
