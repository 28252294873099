import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import de from 'vuetify/lib/locale/de'
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);


export default new Vuetify({
    theme: {
        options: {
            themeCache: {
                get: key => localStorage.getItem(key),
                set: (key, value) => localStorage.setItem(key, value),
            },
        },
    },
    iconfont: 'md' || 'mdi',
    lang: {
        locales: {de, fr, en},
        //current: localStorage.getItem('lang') || 'de', //PLEASE DONT USE LOCALSTORAGE TO SAVE LANGUAGE -> USE VUEX STORE
        fallbackLocale: 'de',
    },
});
